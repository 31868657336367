/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Form,
  Input,
  Modal,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Icon, Toast } from '../../../Components';
import utility from '../../../Global/Utility';
import Constant from '../../../Global/Constant';

interface ModalProps {
  onOk: (comment?: string) => void;
  isModalVisible: boolean
  onCancel: () => void;
  email: string
  is_invitation_send: boolean
  isLoading: boolean
}

function ActivateAccount(props: ModalProps) {
  const {
    onOk,
    isModalVisible,
    onCancel,
    email,
    isLoading,
    is_invitation_send,
  } = props;
  const [form] = useForm();

  const onModelOk = () => {
    form.validateFields()
      .then((values) => {
        onOk(values);
      });
  };

  const onModelClose = () => {
    onCancel();
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ email });
  }, [email, isModalVisible]);

  return (
    <Modal
      title={is_invitation_send ? 'Reinvite' : 'Activate Account'}
      centered
      visible={isModalVisible}
      onOk={onModelOk}
      onCancel={onModelClose}
      confirmLoading={isLoading}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper"
      okText={is_invitation_send ? 'Reinvite' : 'Activate'}
      okButtonProps={{ className: 'main_btn d-flex r-bold align-items-center', htmlType: 'submit' }}
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
          <Icon className="icon-10" iconRef="#close" />
        </Button>
)}
    >

      <Form layout="vertical" form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <Form.Item
          label="EMAIL"
          name="email"
          normalize={utility.trimSpacesInValues}
          className="mandatory"
          rules={[{
            required: true,
            message: Constant.emailRequired,
          },
          {
            type: 'email',
            message: Constant.invalidEmail,
          }]}
        >
          <Input placeholder="Enter email" type="text" className="inp_style" maxLength={50} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default observer(ActivateAccount);
