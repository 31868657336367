import React from 'react';
import { observer } from 'mobx-react';
import {
  Layout,
  Typography,
} from 'antd';

function Orders() {
  return (
    <Layout.Content>
      <Typography.Title level={1} className="mb-4">
        Orders
      </Typography.Title>
    </Layout.Content>
  );
}

export default observer(Orders);
