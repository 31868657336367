/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable } from 'mobx';
import Constant from '../Global/Constant';
import settingsService from '../Services/settingsService';

export interface ISettingsStore {
  settings: any,
  getSettings: () => void
}

class SettingsStore {
  settings: any = null;

  constructor() {
    makeObservable(this, {
      settings: observable,
    });
  }

  getSettings = async () => {
    try {
      const response = await settingsService.getSettings();
      if (response && response?.data) {
        this.settings = response?.data?.settings;
      }
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new SettingsStore();
