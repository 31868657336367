/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import {
  Button,
  Card,
  Col,
  Divider,
  InputNumber,
  Layout,
  Row,
  Slider,
  Spin,
  Typography,
} from 'antd';
import { Icon, Toast } from '../../Components';
import settingsService from '../../Services/settingsService';
import './settings.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function Settings(props: any) {
  const { settingsStore } = props;
  const { settings } = settingsStore;
  const [isLoading, setIsLoading] = useState(false);
  const [editExpireTime, setEditExpireTime] = useState(false);
  const [editPaymentDue, setEditPaymentDue] = useState(false);
  const [dueAmount, setDueAmount] = useState(settings?.vault_plan_due_amount);
  const [expireTime, setExpireTime] = useState(settings?.plan_expiring_time);
  const [collapsed, setCollapsed] = useState(false);

  const getSettings = async () => {
    try {
      setIsLoading(true);
      await settingsStore.getSettings();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const updateSettings = async (data: any, type?: string) => {
    try {
      setIsLoading(true);
      await settingsService.updateSettings(data);
      getSettings();
      if (type === 'due') setEditPaymentDue(false);
      else setEditExpireTime(false);
      Toast.success('Settings updated');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const expireBtnHandler = () => setEditExpireTime(true);
  const cancelExpireTime = () => setEditExpireTime(false);
  const updateExpireTimeSettings = () => updateSettings({ expiring_time: expireTime });
  const sliderChangeHandler = (value: any) => setExpireTime(value);
  const marks = {
    10: '10 Days',
    15: '15 Days',
    20: '20 Days',
  };

  const dueBtnHandler = () => setEditPaymentDue(true);
  const cancelDue = () => setEditPaymentDue(false);
  const updateDueSettings = () => updateSettings({ plan_due_amount: dueAmount }, 'due');
  const onChangeDueHandler = (value: any) => setDueAmount(value);

  useEffect(() => {
    getSettings();
  }, []);
  return (
    <Layout.Content>
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Typography.Title level={1} className="mb-4 fs-xs-24">
              Settings
            </Typography.Title>
            <Card className="create_card">
              <Typography.Title level={3} className="mb-4 fs-xs-18">
                The Vault
              </Typography.Title>
              <Row
                gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
              >
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Typography.Title className="m-0" level={5}>
                    Expiring<span className="clearfix d-none d-lg-table" /> Period
                  </Typography.Title>
                </Col>

                <Col lg={20} md={24} sm={24} xs={24}>
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                        Set how many days before, the plan should flagged as expiring
                      </Typography.Paragraph>

                      { !editExpireTime && <div className="r-bold">{settings?.plan_expiring_time} Days</div>}
                      { editExpireTime && (
                      <div>
                        <Slider
                          min={10}
                          max={20}
                          step={5}
                          marks={marks}
                          defaultValue={settings?.plan_expiring_time}
                          onChange={sliderChangeHandler}
                          trackStyle={{ background: '#26D65B' }}
                          handleStyle={{ background: '#26D65B' }}
                          className="custom_slider"
                        />
                        <div className="pt-4">
                          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" onClick={updateExpireTimeSettings}>
                            Save changes
                          </Button>
                          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelExpireTime}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                      )}
                    </div>
                    { !editExpireTime && (
                    <Button className="m-0 border-0 bg-transparent d-flex align-items-center shadow-none r-bold primary p-0 h-xs-max" onClick={expireBtnHandler}>
                      Edit
                      <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                    )}
                  </div>
                  <Divider className="border-top mt-3 mb-3" />
                </Col>
              </Row>
              <Row
                gutter={{
                  xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
                }}
              >
                <Col lg={4} md={24} sm={24} xs={24}>
                  <Typography.Title className="m-0" level={5}>
                    Penalty<span className="clearfix d-none d-lg-table" /> amount
                  </Typography.Title>
                </Col>
                <Col lg={20} md={24} sm={24} xs={24}>
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Typography.Paragraph className="secondary fs-16 fs-xs-14">
                        Set the Penalty amount to be charged when
                        a vehicle is parked without a plan in the facility
                      </Typography.Paragraph>

                      { !editPaymentDue && <div className="r-bold">{settings?.vault_plan_due_amount} KD/Day</div>}
                      { editPaymentDue && (
                      <div>
                        <div className="secondary r-medium mb-2">Amount</div>
                        <InputNumber
                          defaultValue={settings?.vault_plan_due_amount}
                          value={dueAmount}
                          onChange={onChangeDueHandler}
                          min={0}
                          max={999999}
                          maxLength={6}
                          placeholder="due amount"
                          className="inp_style h-40 custom_inp_number setting_inp w-100"
                        />
                        <div className="mt-4">
                          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" onClick={updateDueSettings} disabled={!dueAmount}>
                            Save changes
                          </Button>
                          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelDue}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                      )}
                    </div>
                    { !editPaymentDue && (
                    <Button className="m-0 border-0 bg-transparent d-flex align-items-center shadow-none r-bold primary p-0 h-xs-max" onClick={dueBtnHandler}>
                      Edit
                      <div className="ms-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle">
                        <Icon className="icon-8" iconRef="#arrow_right" />
                      </div>
                    </Button>
                    ) }
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>The Vault Settings</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('settingsStore')(observer(Settings));
