/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import {
  Col, Row, Typography,
} from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '..';

import './Usercard.less';

interface UserCardProps {
  cardName: String;
  id: String|undefined;
  name: String;
  profileUrl: string;
  contractCount? : number;
  path? : string;
  sharedAccessCount? : number;
  membersCount?: number;
  handleAccessClick?: () => void,
  handleMemberClick?: () => void,
}

UserCard.defaultProps = {
  contractCount: null,
  path: null,
  sharedAccessCount: null,
  membersCount: null,
  handleAccessClick: () => {},
  handleMemberClick: () => {},

};

function UserCard(props: UserCardProps) {
  const {
    profileUrl, cardName, id, name, contractCount, path,
    sharedAccessCount, membersCount, handleAccessClick, handleMemberClick,
  } = props;
  return (
    <div className="detail_card_wrapper">
      <div className="d-xxl-flex d-xl-block d-lg-block d-flex justify-content-between align-items-center">
        <Typography.Title level={4} className="mb-0">{cardName}</Typography.Title>
        {path
          ? (
            <Link to={path} className="d-flex ml-auto r-bold align-items-center back_link fs-16 link_dark">
              View
              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
            </Link>
          )
          : null}
      </div>
      <div className="justify-content-center d-flex align-items-center">
        <div className="large_icn_bg p-0 rounded-circle mb-3 mt-2 justify-content-center d-flex align-items-center">
          {!profileUrl
            ? (
              <Icon className="icon-32" iconRef="#User" />
            )
            : (
              <img className="img-fluid" src={profileUrl} alt="Profile" />
            )}
        </div>
      </div>
      { name && name !== null && name !== undefined && (
      <Typography.Title className="text-center mb-0 text-capitalize" level={5}>
        {name || ''}
      </Typography.Title>
      )}
      { id && (
      <Typography.Paragraph className="text-center secondary">
        {cardName} ID - {id || ''}
      </Typography.Paragraph>
      )}
      <Row gutter={4}>
        {contractCount !== null && contractCount !== undefined
          && (
          <Col lg={7} md={7} xs={7} className="border-right">
            <Typography.Title className="text-center mb-0" level={5}>
              {contractCount}
            </Typography.Title>
            <Typography.Paragraph className="text-center secondary mb-0 text-ellipsis" title="Contracts">
              {`${contractCount > 1 ? 'Plans' : 'Plan'}`}
            </Typography.Paragraph>
          </Col>
          )}
        {sharedAccessCount !== null && sharedAccessCount !== undefined
          && (
          <Col lg={10} md={10} xs={10} className="border-right" onClick={handleAccessClick}>
            <Typography.Title className="text-center mb-0 cursor-pointer" level={5}>
              {sharedAccessCount}
            </Typography.Title>
            <Typography.Paragraph className="text-center secondary mb-0 text-ellipsis cursor-pointer" title="Shared Access">
              Shared Access
            </Typography.Paragraph>
          </Col>
          )}
        {membersCount !== null && membersCount !== undefined
        && (
        <Col lg={7} md={7} xs={7} onClick={handleMemberClick}>
          <Typography.Title className="text-center mb-0 cursor-pointer" level={5}>
            {membersCount}
          </Typography.Title>
          <Typography.Paragraph className="text-center secondary mb-0 text-ellipsis cursor-pointer" title="Members">
            Members
          </Typography.Paragraph>
        </Col>
        )}
      </Row>
    </div>
  );
}

export default UserCard;
