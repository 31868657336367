const Constant = {
  defaultErrorMessage: 'Something went wrong, please try again!',
  loginErrorMessage: 'Please enter a valid credentials',
  emailRequiredValidationError: 'Please enter your email address',
  emailValidationError: 'Please enter a valid email address',
  passwordRequiredValidationError: 'Please enter your password',
  invalidPassword: 'Please provide valid password',

  idRequiredValidationError: 'Please provide a valid ID of maximum length 15',
  formValidationError: 'Please provide valid parameters',

  newPasswordRequiredError: 'Enter your new password',
  currentPasswordRequiredError: 'Enter your current password',
  confirmPasswordRequiredError: 'Please confirm your password',
  passwordMatchError: 'Password do not match!',
  passwordValidationError: 'Password must be minimum of 6 charecter',
  oldNewPasswordSameError: 'Old password and new password should not be same',
  parkedDateError: 'Please choose the parked date with in the start date and due date',
  myfatoorahErrorEmail: 'Please enter valid email. MyFatoorah does not accept email with special characters except \'.@\'',

  vehicleImageRequiredError: 'Vehicle image is required',
  vehicleRequired: 'Vehicle is required.',
  passwordMinLength: 6,
  maxNameLength: 50,
  MaxColorLength: 20,
  fieldRequiredValidation: 'This field is required.',
  renewDaysInMilliseconds: 86400000,
  maximumFileSize: 3, // in mb
  daysInMilliSeconds: 86400000,

  organizationName: 'Travancore Analytics',

  employeePerPage: 15,
  clientPerPage: 15,
  facilityPerPage: 15,
  defaultPageNumber: 1,
  modelPerPage: 8,
  contractPerPage: 15,
  approvalsPerPage: 8,
  clientDetailsPage: 8,
  memberTablePerPage: 15,
  serviceRequest: 15,
  activityPerPage: 10,
  notificationPerPage: 10,
  defaultSliderValue: 0,
  defaultMileageValue: 20,
  defaultTirePressureValue: {
    wheel_1: 30,
    wheel_2: 30,
    wheel_3: 30,
    wheel_4: 30,
  },
  defaultAttachment: false,
  userActivityPerPage: 8,
  facilityLog: 8,

  debaounceTime: 500,

  nameRequired: 'First name is required.',
  facilityNameRequired: 'Name is required.',
  lastNameRequired: 'Last name is required.',
  genderRequired: 'Gender is required.',
  dateRequired: 'Date is required.',
  monthRequired: 'Month is required.',
  yearRequired: 'Year is required.',
  emailRequired: 'Email is required.',
  phoneRequired: 'Phone is required.',
  countryRequired: 'Country is required.',
  stateRequired: 'State is required.',
  cityRequired: 'City is required.',
  zipcodeRequired: 'Post code is required.',
  profileRequired: 'Profile is required.',
  splOrNumberOnlyError: 'Special characters or numericals are not allowed.',
  specialCharectorError: 'Special characters not allowed.',
  invalidData: 'Please provide a valid data.',
  invalidDate: 'Not a valid date.',
  invalidMonth: 'Not a valid month.',
  invalidYear: 'Not a valid year.',
  invalidEmail: 'Not a valid email.',
  invalidPhone: 'Not a valid phone number.',
  zipMinimumError: 'Please provide post-code minimum of 4 numbers.',
  vehicleCategoryRequired: 'Vehicle category required.',
  vehicleModelRequired: 'Model required.',
  vehicleCompanyRequired: 'Manufacturer required.',
  manufactureYearRequired: 'Year required.',
  vehicleColorRequired: 'Vehicle color required',
  vehicleColorNameRequired: 'Vehicle color name required',
  fileExistError: 'File exists with same name.',
  addressLine1Required: 'Address line 1 is required.',
  addressLine2Required: 'Address line 2 is required.',
  invalidAddressLine1: 'Invalid address line 1.',
  invalidAddressLine2: 'Invalid address line 2.',
  contractPeriodRequired: 'Plan period required.',
  startDateRequired: 'Start date required.',
  expiryDateRequired: 'Expiry date required.',
  vehicleNoRequired: 'Vehicle number required.',
  chassisNoRequired: 'Chassis number required.',
  facilityRequired: 'Facility required.',
  parkedDateRequired: 'Parked date required.',
  invalidVehicleNumber: 'Invalid vehicle number.',
  invalidChassisNumber: 'Invalid chassis number.',
  invalidTirePressure: 'Please provide valid tire pressure.',
  invalidMileage: 'Please provide valid mileage.',
  planRequired: 'Plan is required.',

  userAccess: {
    SUPER_ADMIN: 1,
    EMPLOYEE: 2,
  },

  userTypeText: new Map([
    [1, 'Super admin'],
    [2, 'Employee'],
  ]),

  employeeRequest: {
    CLIENT_DELETE: 'CLIENT_DELETE',
    MODEL_DELETE: 'MODEL_DELETE',
    FACILITY_DELETE: 'FACILITY_DELETE',
  },

  unAuthorizedError: 'You are not authorized to access this website.',

  token: 'token',
  refreshToken: 'refreshToken',

  passwordLength: 20,
  invalidLocation: 'Please provide a valid location.',
  addressRequired: 'Location is required.',
  capacityRequired: 'Facility capacity is required.',
  onlyNumbers: 'Only numbers are allowed.',
  onlyIntegerNumbers: 'Only numbers without decimals are allowed.',
  invalidQRCode: 'Invalid QR code.',
  contractTypes: {
    ACTIVE: 'active',
    EXPIRED: 'expired',
    EXPIRING: 'expiring',
    PAYMENT_PENDING: 'payment_pending',
  },

  customerContract: {
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    ACTIVE: 'ACTIVE',
    HOLD: 'HOLD',
    CLOSED: 'CLOSED',
  },
  requestActions: {
    REJECT: 'REJECT',
    APPROVE: 'APPROVE',
    CANCELED: 'CANCELED',
  },
  requestStatus: {
    OPEN: 'OPEN',
    CANCELED: 'CANCELED',
    CLOSED: 'CLOSED',
    PENDING: 'PENDING',
  },
  vehicleDocTypes: {
    REGISTRATION: 'REGISTRATION',
    INSURANCE: 'INSURANCE',
  },

  VAULT_CONTRACT: 'VAULT_CONTRACT',
  SERVICE_CONTRACT: 'SERVICE_CONTRACT',

  MEMBER_REQUEST_STATUS: {
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
  },

  modelPublishStatus: {
    PUBLISHED: 'PUBLISHED',
    NEW: 'NEW',
  },

  plan_type: {
    VAULT: 'VAULT',
    RESIDENTIAL: 'RESIDENTIAL',
  },

  duration: {
    30: '1 month',
    60: '2 months',
    90: '3 months',
    180: '6 months',
    360: '12 months',
  },

  psi: 'psi',
  bar: 'bar',
  psiToBar: 1 / 14.5038,
  barToPsi: 14.5038,

  KM: 'KM',
  MI: 'MI',
  mileageMilesMaximumValue: 621372.1152770701,
  mileageKiloMeterMaximumValue: 999999,

  MIToKM: 1.60934,
  KMToMI: 1 / 1.60934,

  serviceRequestStatus: {
    OPEN: 'OPEN',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
  },

  serviceRequestActions: {
    START: 'START',
    COMPLETE: 'COMPLETE',
    CANCEL: 'CANCEL',
    PAYMENT_DONE: 'PAYMENT_DONE',
  },

  SERVICES: new Map([
    ['WASH', 'Wash'], ['COVER', 'Cover'], ['MILEAGE_CHECK', 'Mileage Check'], ['BATTERY_TENDER', 'Battery Tender'],
    ['FLAT_STOPPERS', 'Flat Stoppers'], ['TIRE_PRESSURE_CHECK', 'Tire Pressure Check'], ['FUEL_CHECK', 'Fuel Check'],
    ['ENGINE', 'Engine'], ['FLUID', 'Fluid'], ['WINDSHIELD', 'Windshield'], ['EXTRA_WASH_STANDARD', 'Extra Wash Standard'],
    ['EXTRA_WASH_PREMIUM', 'Extra Wash Premium'],
  ]),

  vehicleActivities: new Map([
    ['VEHICLE_SWAP', 'Swap'], ['VEHICLE_PARK', 'Park'], ['VEHICLE_UNPARK', 'Unpark'], ['VEHICLE_ADD', 'Add Vehicle'],
    ['VEHICLE_REMOVE', 'Remove Vehicle'],
  ]),

  ACTIVITIES: new Map([
    ['SERVICE_REQUEST', 'Requested'],
    ['SERVICE_CANCEL', 'Cancelled'],
    ['SERVICE_PAYMENT', 'Paid'],
    ['SERVICE_START', 'Started'],
    ['SERVICE_COMPLETE', 'Completed'],
  ]),

  ServiceReqStatusText: new Map([
    ['OPEN', 'Not initiated'],
    ['IN_PROGRESS', 'In progress'],
    ['COMPLETED', 'Closed'],
    ['PAYMENT_PENDING', 'Payment pending'],
  ]),

  ServiceReqStatusClass: new Map([
    ['OPEN', 'label_warning'],
    ['IN_PROGRESS', 'label_success'],
    ['COMPLETED', 'label_inactive'],
    ['PAYMENT_PENDING', 'label_warning'],
  ]),
  statusImage: new Map([
    ['ENGINE', '#engine'],
    ['FUEL_CHECK', '#fuel'],
    ['COVER', '#cover'],
    ['BATTERY_TENDER', '#battery'],
    ['FLAT_STOPPERS', '#flatstopper'],
    ['WASH', '#wash'],
    ['EXTRA_WASH_STANDARD', '#extrawash'],
    ['EXTRA_WASH_PREMIUM', '#extrawash'],
    ['MILEAGE_CHECK', '#mileage'],
    ['WINDSHIELD', '#windshield'],
    ['TIRE_PRESSURE_CHECK', '#tire'],
    ['FLUID', '#fluid'],
  ]),

  contractStatusColor: new Map([
    ['REQUESTED', 'label_warning'],
    ['ACTIVE', 'label_success'],
    ['APPROVED', 'label_success'],
    ['EXPIRED', 'label_danger'],
    ['REJECTED', 'label_danger'],
    ['CLOSED', 'label_inactive'],
    ['CANCELLED', 'label_inactive'],
  ]),

  facilityLogAction: new Map([
    ['IN', 'IN'],
    ['OUT', 'OUT'],
  ]),

  facilityLogActionClassName: new Map([
    ['IN', 'label_success'],
    ['OUT', 'label_warning'],
  ]),

  wheels: new Map([
    ['car', 4],
    ['others', 4],
    ['motorbike', 2],
    ['marine', 0],
  ]),

  activity_type: {
    PLAN_ACTIVITY: 'PLAN_ACTIVITY',
    SERVICE_ACTIVITY: 'SERVICE_ACTIVITY',
    PARKING_UNPARKING: 'PARKING_UNPARKING',
  },

  activities: {
    PLAN_REQUEST: 'PLAN_REQUEST',
    PLAN_PAYMENT: 'PLAN_PAYMENT',
    PLAN_CANCEL: 'PLAN_CANCEL',
    PLAN_CLOSE: 'PLAN_CLOSE',
    PLAN_APPROVE: 'PLAN_APPROVE',
    PLAN_HOLD: 'PLAN_HOLD',
    PLAN_REJECT: 'PLAN_REJECT',
    SERVICE_REQUEST: 'SERVICE_REQUEST',
    SERVICE_CANCEL: 'SERVICE_CANCEL',
    SERVICE_PAYMENT: 'SERVICE_PAYMENT',
    SERVICE_START: 'SERVICE_START',
    SERVICE_COMPLETE: 'SERVICE_COMPLETE',
    VEHICLE_STATUS_UPDATE: 'VEHICLE_STATUS_UPDATE',
    VEHICLE_PARK: 'VEHICLE_PARK',
    VEHICLE_UNPARK: 'VEHICLE_UNPARK',
    VEHICLE_SWAP: 'VEHICLE_SWAP',
  },

  services: {
    FACILITY_SLOT: 'FACILITY_SLOT',
    WASH: 'WASH',
    COVER: 'COVER',
    MILEAGE_CHECK: 'MILEAGE_CHECK',
    BATTERY_TENDER: 'BATTERY_TENDER',
    FLAT_STOPPERS: 'FLAT_STOPPERS',
    TIRE_PRESSURE_CHECK: 'TIRE_PRESSURE_CHECK',
    FUEL_CHECK: 'FUEL_CHECK',
    ENGINE: 'ENGINE',
    FLUID: 'FLUID',
    WINDSHIELD: 'WINDSHIELD',
  },

  payment_mode: {
    GATEWAY: 'GATEWAY',
    CASH: 'CASH',
    POS: 'POS',
  },

  parkUnparkActions: new Map([
    ['park', 'park'],
    ['unPark', 'unPark'],
    ['swap', 'swap'],
  ]),

  ContactMode: {
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
  },

  QRcodeTypes: {
    VEHICLE: 'VEHICLE',
    CLIENT: 'CLIENT',
  },

  vehicleActions: {
    PARK: 'PARK',
    UNPARK: 'UNPARK',
    SWAP: 'SWAP',
  },
  approvalActions: new Map([
    ['APPROVE', 'Accepted'],
    ['REJECT', 'Rejected'],
  ]),

  paymentType: new Map([
    ['PLAN_PURCHASE', 'Purchase'],
    ['PLAN_RENEW', 'Renew'],
    ['PLAN_OVERDUE', 'Penalty'],
    ['SERVICE_REQUEST', 'Service request'],
  ]),

  colors: {
    yellow: '#f1aa1d',
    red: '#ed093b',
    green: '#26d65b',
    white: '#ffffff',
    black: '#000000',
    blue: '#00b1ff',
    grey: '#bbb',
    darkGrey: '#777',
  },

  passwordRegex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{6,20}$/,

  notificationTypes: {
    PLAN_REQUEST: 'PLAN_REQUEST', // client(to PORTAL)
    PLAN_CLOSED: 'PLAN_CLOSED',
    PLAN_CANCEL: 'PLAN_CANCEL',

    GATE_OPEN_REQUEST: 'GATE_OPEN_REQUEST', // client (to PORTAL)

    DELETE_CLIENT_REQUEST: 'DELETE_CLIENT_REQUEST', // employee (to PORTAL)
    DELETE_MODEL_REQUEST: 'DELETE_MODEL_REQUEST', // employee (to PORTAL)
    DELETE_FACILITY_REQUEST: 'DELETE_FACILITY_REQUEST', // employee (to PORTAL)

    DELETE_CLIENT_APPROVAL: 'DELETE_CLIENT_APPROVAL', // admin (to PORTAL)
    DELETE_MODEL_APPROVAL: 'DELETE_MODEL_APPROVAL', // admin (to PORTAL)
    DELETE_FACILITY_APPROVAL: 'DELETE_FACILITY_APPROVAL', // admin (to PORTAL)

    DELETE_CLIENT_REJECTION: 'DELETE_CLIENT_REJECTION', // admin (to PORTAL)
    DELETE_MODEL_REJECTION: 'DELETE_MODEL_REJECTION', // admin (to PORTAL)
    DELETE_FACILITY_REJECTION: 'DELETE_FACILITY_REJECTION', // admin (to PORTAL)

    SERVICE_REQUEST: 'SERVICE_REQUEST', // client (to PORTAL)

    SERVICE_REQUEST_CANCELLED: 'SERVICE_REQUEST_CANCELLED',
  },

  menuAccess: {
    PLAN_MANAGEMENT: 'PLAN_MANAGEMENT',
    CLIENT_MANAGEMENT: 'CLIENT_MANAGEMENT',
    SERVICE_MANAGEMENT: 'SERVICE_MANAGEMENT',
  },
  myfatoorahSupportedCountries: ['KW', 'SA', 'BH', 'AE', 'QA', 'OM', 'JO', 'EG'],
};

export default Constant;
