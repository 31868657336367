/* eslint-disable camelcase */
import { Typography } from 'antd';
import React from 'react';

import './Address.less';

interface AddressProps{
    country: String,
    state: String,
    city: String,
    address_line_1: String,
    address_line_2: String,
    zip_code: Number
}

interface AddressCardProps{
    addressInfo: AddressProps | any
}

function Address(props: AddressCardProps) {
  const { addressInfo } = props;
  return (
    <div className="detail_card_wrapper">
      <Typography.Title level={4} className="fs-xs-18">Address</Typography.Title>
      <Typography.Paragraph className="m-0 secondary">{addressInfo?.address_line_1}</Typography.Paragraph>
      <Typography.Paragraph className="m-0 secondary">{addressInfo?.address_line_2}</Typography.Paragraph>
      <Typography.Paragraph className="m-0 secondary">{addressInfo?.city}</Typography.Paragraph>
      <Typography.Paragraph className="m-0 secondary">{addressInfo?.state}</Typography.Paragraph>
      <Typography.Paragraph className="m-0 secondary">{addressInfo?.zip_code}</Typography.Paragraph>
      <Typography.Paragraph className="m-0 secondary">{addressInfo?.country}</Typography.Paragraph>
    </div>
  );
}

export default Address;
