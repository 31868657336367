/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable-next-line import/no-unresolved */

import React from 'react';
import {
  Button, Checkbox, Col, DatePicker, Divider, Form, Modal, Row, Select, Spin, Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { Icon } from '..';
import Constant from '../../Global/Constant';
import Rules from './create-contract-form.validation';

interface AddPlanProps {
    isLoading: boolean,
    isModalVisible: boolean,
    onCancel: any,
    onOk: any,
    planTypes: any,
    facilities: any,
    setIsPlanPaid: any,
    isPlanPaid: boolean,
    isVehicleInside: boolean,
    setIsVehicleInside: any,
    vehicles: any,
    setSelectedPlan: any,
    selectedPlan: any
}

function ContactCard(props: AddPlanProps) {
  const {
    isLoading, isModalVisible, onCancel, onOk, planTypes, facilities, setIsPlanPaid, isPlanPaid,
    vehicles, setIsVehicleInside, isVehicleInside, setSelectedPlan, selectedPlan,
  } = props;

  const onFinishContract = (value: any) => {
    value.plan_paid = isPlanPaid;
    onOk(value);
  };

  const { Option } = Select;
  const disabledDateForFuture = (current: Moment): boolean => current && current > moment();

  const isVaultPlan = (plan: any) => {
    const planDetail = planTypes.find((item: any) => item?._id === plan);
    return planDetail?.plan_type === 'VAULT';
  };

  const onCloseModel = () => {
    setSelectedPlan('');
    setIsPlanPaid(false);
    setIsVehicleInside(false);
    onCancel();
  };

  const paidCheckBoxHandler = () => setIsPlanPaid(!isPlanPaid);
  const vehicleCheckboxHandler = () => setIsVehicleInside(!isVehicleInside);

  return (
    <Modal
      title="Add Plan"
      visible={isModalVisible}
      onCancel={onCloseModel}
      onOk={onOk}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      className="cmn_modal_wrapper"
      width={1000}
      destroyOnClose
      closeIcon={(
        <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={onCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
    )}
    >
      <div>
        <Spin spinning={isLoading}>
          <Form layout="vertical" onFinish={onFinishContract} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
            <Row
              gutter={{
                xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
              }}
              className="mt-3"
            >
              <Col lg={4} md={24} sm={24} xs={24}>
                <Typography.Title className="m-0" level={5}>
                  Plan details
                </Typography.Title>
              </Col>
              <Col lg={20} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-between">
                  <Typography.Text className="m-0 create_para">
                    Enter plan details
                  </Typography.Text>
                </div>
                <div className="add_cnt_wrapper">
                  <div className="border-bottom">
                    <Row gutter={16} className="mt-3">
                      {/* PLAN TYPE */}
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="Plan type"
                          className="mandatory"
                          name="contract_plan"
                          rules={Rules.contract_type}
                        >
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose plan type"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                            onChange={(value: string) => setSelectedPlan(value)}
                          >
                            {planTypes?.map((plan: any) => (
                              <Option value={plan?._id}>{plan?.plan}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {/* PLAN DURATION */}
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="Plan period"
                          className="mandatory"
                          name="plan_duration"
                          rules={Rules.contract}
                        >
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose plan period"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                          >
                            {Object.entries(Constant.duration).map(([key, value]) => (
                              <Option value={key}>{value}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {/* FACILITY */}
                      {selectedPlan && isVaultPlan(selectedPlan) && (
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="facility"
                          className="mandatory"
                          name="facility"
                          rules={Rules.facility}
                        >
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                          >
                            {facilities?.map((facility: any) => (
                              <Option
                                value={facility._id}
                                disabled={
                                facility?.used_capacity >= facility?.total_capacity
                               }
                              >
                                {facility?.name}<br />
                                <span className={classNames('text-uppercase secondary r-regular d-flex align-items-center', { disabled: facility?.used_capacity >= facility?.total_capacity })}>{facility?.address}
                                  <Icon className="icon-12 ms-2" iconRef="#location" />
                                </span>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      )}
                    </Row>
                    <Row gutter={16} className="mt-3">
                      {/* plan paid check box */}
                      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Checkbox
                          checked={isPlanPaid}
                          className="chkbox_style primary r-regular mb-3"
                          onChange={paidCheckBoxHandler}
                        >
                          Plan is already paid
                        </Checkbox>
                      </Col>
                      {/* PLAN START DATE */}
                      {isPlanPaid
                      && (
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="start date"
                          className="mandatory"
                          name="start_date"
                         rules={Rules.start_date}
                        >
                          <DatePicker
                            className="inp_style date_picker primary"
                            dropdownClassName="date_picker_drp_dwn"
                            placeholder="Choose start date"
                            showToday={false}
                            disabledDate={disabledDateForFuture}
                            format="DD-MM-YYYY"
                            suffixIcon={(
                              <Icon className="icon-16 primary" iconRef="#calendar" />
                          )}
                          />
                        </Form.Item>
                      </Col>
                      )}
                    </Row>
                    <Row gutter={16} className="mt-3">
                      {/* vehicle inside check box */}
                      {isPlanPaid && selectedPlan && isVaultPlan(selectedPlan)
                      && (
                      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                        <Checkbox
                          checked={isVehicleInside}
                          className="chkbox_style primary r-regular mb-3"
                          onChange={vehicleCheckboxHandler}
                        >
                          Vehicle is inside the facility now
                        </Checkbox>
                      </Col>
                      )}
                      {/* VEHICLE */}
                      {isVehicleInside && isPlanPaid && selectedPlan && isVaultPlan(selectedPlan)
                      && (
                      <>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item
                          label="Vehicle"
                          className="mandatory"
                          name="vehicle"
                         rules={Rules.vehicle}
                          >
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose Vehicle"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                            >
                              {vehicles?.length && vehicles?.map((vehicle:any) => <Option disabled={vehicle?.contractPlan} value={vehicle?._id}>{`${vehicle?.model[0]?.company} ${vehicle?.model[0]?.model}`}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item
                        label="parked date"
                        className="mandatory"
                        name="parked_date"
                        rules={Rules.parked_date}
                          >
                            <DatePicker
                          className="inp_style date_picker primary"
                          dropdownClassName="date_picker_drp_dwn"
                          placeholder="Choose date"
                          showToday={false}
                          format="DD-MM-YYYY"
                          disabledDate={disabledDateForFuture}
                          suffixIcon={(
                            <Icon className="icon-16 primary" iconRef="#calendar" />
                            )}
                            />
                          </Form.Item>
                        </Col>
                      </>
                      )}
                    </Row>
                  </div>
                </div>
                <Divider className="m-0 border-top mb-3" />
              </Col>
              <Col lg={4} md={24} sm={24} xs={24} />
              <Col lg={20} md={24} sm={24} xs={24}>
                <div className="d-flex mt-4 ps-lg-0 ps-4">
                  <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
                    Add plan
                  </Button>
                  <Button className="cancel_btn border-0 primary shadow-none me-3 fs-16 r-bold" disabled={isLoading} onClick={onCloseModel}>
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
}

export default ContactCard;
