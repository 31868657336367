/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col,
  Layout,
  Row,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import './ApprovalHistory.less';
import { Link as ScrollLinks, Element } from 'react-scroll';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import {
  Icon,
  NoData, Pagination, Search, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import Routes from '../../Global/Routes';
import SortTh from '../../Components/SortTh';
import { RequestData, RequestedData } from '../../Global/interface';

const { PLAN_MANAGEMENT } = Constant.menuAccess;
const { Sider } = Layout;
const { Title, Paragraph } = Typography;

function ApprovalListing(props: any) {
  const { requestStore, tenantStore } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { requests } = requestStore;
  const { CLIENT_DELETE, MODEL_DELETE, FACILITY_DELETE } = Constant.employeeRequest;
  const [empSearchValue, setEmpSearchValue] = useState<string>('');
  const [clientSearchValue, setClientSearchValue] = useState<string>('');
  const [collapsed, setCollapsed] = useState(false);

  const showPlanHistory = tenantStore?.tenantAccess?.includes(PLAN_MANAGEMENT);

  const employeeReqColumns = [
    {
      title: <SortTh title="Employee" dataIndex="user.first_name" orderBy={requests?.employee?.orderBy} order={requests?.employee?.order} />,
      dataIndex: 'employee',
      ellipsis: true,
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.employee, 'user.first_name', 'employee');
        },
      }),
    },
    {
      title: <SortTh title="Request Date" dataIndex="createdAt" orderBy={requests?.employee?.orderBy} order={requests?.employee?.order} />,
      dataIndex: 'date',
      ellipsis: true,
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.employee, 'createdAt', 'employee');
        },
      }),
    },
    {
      title: 'Request',
      dataIndex: 'request',
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
    },
  ];

  const findRoute = (request: RequestedData) => {
    switch (request?.request) {
      case CLIENT_DELETE:
        return `${Routes.client_detail}/${request?.target_id}`;
      case MODEL_DELETE:
        return `${Routes.model_detail}/${request?.target_id}`;
      case FACILITY_DELETE:
        return `/facility_detail/${request?.target_id}`;
      default:
        return '';
    }
  };

  const getTargetName = (request: RequestedData) => {
    switch (request?.request) {
      case CLIENT_DELETE:
        return `${request?.client?.first_name} ${request?.client?.middle_name || ''} ${request?.client?.last_name}`;
      case MODEL_DELETE:
        return `${request?.model?.company} ${request?.model?.model}`;
      case FACILITY_DELETE:
        return `${request?.facility?.name}`;
      default:
        return '';
    }
  };

  const getTargetDeleteStatus = (request: RequestedData) => {
    switch (request?.request) {
      case CLIENT_DELETE:
        return request?.client?.deleted;
      case MODEL_DELETE:
        return request?.model?.deleted;
      case FACILITY_DELETE:
        return request?.facility?.deleted;
      default:
        return '';
    }
  };

  const employeeReqData = requests?.employee?.data?.map((request: RequestedData) => ({
    employee:
  <div className="d-flex text-capitalize ml-auto r-bold align-items-center p-0 link_text">
    <span className="text-truncate">
      {request?.user[0]?.first_name} {request?.user[0]?.middle_name || ''} {request?.user[0]?.last_name}
    </span>
    { !request?.user[0]?.deleted && (
    <Link to={`/employees_detail/${request?.user[0]?._id}`} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
      <Icon className="icon-8" iconRef="#arrow_right" />
    </Link>
    ) }
  </div>,
    date: Utility.getFormattedDate(request?.createdAt),
    request:
  <div className="d-flex">{Utility.getRequestText(request?.request)} -
    <span title={getTargetName(request)} className="text-capitalize text-truncate ms-1">
      {getTargetName(request)}
    </span>
    { request?.action === Constant.requestActions.REJECT && !getTargetDeleteStatus(request) && (
      <Link to={findRoute(request)} className="d-flex ml-auto r-bold ms-1 align-items-center p-0 link_text">
        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
          <Icon className="icon-8" iconRef="#arrow_right" />
        </div>
      </Link>
    )}
  </div>,
    rowKey: request?._id,
    key: request?._id,
    action:
  <div className="d-flex align-items-center">
    {Constant.approvalActions.get(request?.action)}
    { request?.action === Constant.requestActions.REJECT && request?.comment && (
    <Tooltip title={request?.comment}>
      <div className="lh_0">
        <Icon className="icon-16 ms-2" iconRef="#info" />
      </div>
    </Tooltip>
    )}
  </div>,
  }));

  const sortTable = (request: RequestData, dataIndex: string, isEmployee?: string) => {
    const nextSort = request?.orderBy === -1 ? 1 : -1;
    if (isEmployee) getEmployeeRequests(request?.page, request?.keyword, dataIndex, nextSort);
    else getClientRequests(request?.page, request?.keyword, dataIndex, nextSort);
  };

  const clientReqColumn = [
    {
      title: <SortTh title="Client Name" dataIndex="client.first_name" orderBy={requests?.client?.orderBy} order={requests?.client?.order} />,
      dataIndex: 'clientname',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.client, 'client.first_name');
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Request Date" dataIndex="createdAt" orderBy={requests?.client?.orderBy} order={requests?.client?.order} />,
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.client, 'createdAt');
        },
      }),
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Plan Type',
      dataIndex: 'planType',
      ellipsis: true,
    },
    {
      title: 'Plan Term',
      dataIndex: 'planTerm',
      ellipsis: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      ellipsis: true,
    },
    // {
    //   title: 'Client Type',
    //   dataIndex: 'clientType',
    //   ellipsis: true,
    // },
    {
      title: 'Action',
      dataIndex: 'action',
    },
  ];

  const getLocation = (contractType: string, request: RequestedData) => {
    if (contractType === Constant.plan_type.VAULT) {
      return request?.facility[0]?.name;
    }
    return request?.contract[0]?.address?.state;
  };

  const clientReqData = requests?.client?.data.map((request: RequestedData) => ({
    clientname:
  <Link to={`${Routes.client_detail}/${request?.client[0]?._id}`} className="d-flex ml-auto text-capitalize r-bold align-items-center p-0 link_text">
    <span className="text-truncate text-capitalize">
      {request?.client[0]?.first_name} {request?.client[0]?.middle_name || ''} {request?.client[0]?.last_name}
    </span>
    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
      <Icon className="icon-8" iconRef="#arrow_right" />
    </div>
  </Link>,
    date: Utility.getFormattedDate(request?.createdAt),
    planType: request?.contractPlan[0]?.plan,
    planTerm: Utility.getMonthFromDays(request?.contract[0]?.duration),
    location:
  <div className="d-flex align-items-center">
    <Icon className="icon-13 secondary me-1 min-w-13" iconRef="#location" />
    <span className="d-block text-truncate">{getLocation(request?.contractPlan[0]?.plan_type, request)}</span>
  </div>,
    rowKey: request?.contract[0]?._id,
    key: request?._id,
    action:
  <div className="d-flex align-items-center">
    {Constant.approvalActions.get(request?.action)}
    { request?.action === Constant.requestActions.REJECT && request?.comment && (
      <Tooltip title={request?.comment}>
        <div className="lh_0">
          <Icon className="icon-16 ms-2" iconRef="#info" />
        </div>
      </Tooltip>
    )}
  </div>,
  }));

  const getEmployeeRequests = async (
    page: number, keyword?: string, order?: string, orderBy?:number,
  ) => {
    setIsLoading(true);
    try {
      await requestStore.getEmployeeRequest(
        Constant.requestStatus.CLOSED, page, Constant.approvalsPerPage, keyword, order, orderBy,
      );
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const getClientRequests = async (
    page: number, keyword?: string, order?: string, orderBy?:number,
  ) => {
    setIsLoading(true);
    try {
      await requestStore.getPlanRequests(
        {
          request_status: Constant.requestStatus.CLOSED,
          page,
          limit: Constant.approvalsPerPage,
          keyword,
          order,
          orderBy,
        },
      );
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  useEffect(() => {
    requestStore.initialize();
    getEmployeeRequests(Constant.defaultPageNumber);
    getClientRequests(Constant.defaultPageNumber);
  }, []);

  const empDebouncedSave = useCallback(
    debounce((nextValue: any) => getEmployeeRequests(
      Constant.defaultPageNumber, nextValue, requests?.employee?.order, requests?.employee?.orderBy,
    ), Constant.debaounceTime),
    [],
  );

  const empSearch = (event: any) => {
    const { value: nextValue } = event.target;
    setEmpSearchValue(nextValue);
    empDebouncedSave(nextValue);
  };

  const clientDebouncedSave = useCallback(
    debounce((nextValue: any) => getClientRequests(
      Constant.defaultPageNumber, nextValue, requests?.client?.order, requests?.client?.orderBy,
    ), Constant.debaounceTime),
    [],
  );

  const clientSearch = (event: any) => {
    const { value: nextValue } = event.target;
    setClientSearchValue(nextValue);
    clientDebouncedSave(nextValue);
  };

  const emplocale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="Employee Request" />
    ),
  };

  const clientlocale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="Client Request" />
    ),
  };

  const getEmployeeReq = (page: number) => getEmployeeRequests(
    page, requests?.employee?.keyword, requests?.employee?.order, requests?.employee?.orderBy,
  );

  const getClientReq = (page: number) => getClientRequests(
    page, requests?.client?.keyword, requests?.client?.order, requests?.client?.orderBy,
  );

  return (
    <Layout.Content className="listing_wrapper">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <Link className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content primary" to={Routes.approval}>
            <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_left" />
            </div>
            Back
          </Link>
          <Typography.Title className="m-0 header_main mb-2 pb-3 d-flex align-items-center fs-xs-24">
            Approval History
          </Typography.Title>
          <div className="approval_listing">
            {showPlanHistory
            && (
            <Element name="Client Request" className="approval_hist_listing">
              <div className="section_off_set  border-bottom mb-4 pb-4" id="expired_cnrct">
                <div className="d-sm-flex align-items-center mb-3">
                  <h4 className="mb-0 table-header r-bold">Plan Requests</h4>
                  <Search
                    onChange={clientSearch}
                    value={clientSearchValue}
                    placeholder="Search"
                    titleToolTip="Search Client Request"
                    size="small"
                  />
                </div>
                <Table
                  columns={clientReqColumn}
                  dataSource={clientReqData}
                  pagination={false}
                  size="small"
                  scroll={{ x: '800px' }}
                  className="table_wrapper"
                  locale={clientlocale}
                />
                <Pagination
                  total={requests?.client?.total}
                  currentPage={requests?.client?.page}
                  pageSize={Constant.approvalsPerPage}
                  onChange={getClientReq}
                />
              </div>
            </Element>
            )}
            <Element name="Employees Request" className="employee_hist_request">
              <div className="section_off_set" id="active_cnrct">
                <div className="d-sm-flex justify-content-between align-items-center mb-3">
                  <div className="d-sm-flex align-items-center flex-fill">
                    <h4 className="mb-0 table-header r-bold">Employee Requests</h4>
                    <Search
                      onChange={empSearch}
                      value={empSearchValue}
                      placeholder="Search"
                      titleToolTip="Search Employee Request"
                      size="small"
                    />
                  </div>
                </div>
                <Table
                  columns={employeeReqColumns}
                  dataSource={employeeReqData}
                  pagination={false}
                  size="small"
                  scroll={{ x: '800px' }}
                  className="table_wrapper"
                  locale={emplocale}
                />
                <Pagination
                  total={requests?.employee?.total}
                  currentPage={requests?.employee?.page}
                  pageSize={Constant.approvalsPerPage}
                  onChange={getEmployeeReq}
                />
              </div>
            </Element>
          </div>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See all approval history</Paragraph>
          </Typography>
          <h6 className="text-uppercase r-medium r-side-info mt-5">Sections</h6>
          <div className="v_tab d-flex flex-column">
            {showPlanHistory
            && (
            <ScrollLinks
              to="Client Request"
              activeClass="tab_active"
              offset={-110}
              className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
              spy
            >
              Plan Requests
            </ScrollLinks>
            )}
            <ScrollLinks
              to="Employees Request"
              activeClass="tab_active"
              offset={-110}
              className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
              spy
            >
              Employee Requests
            </ScrollLinks>
          </div>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('requestStore', 'tenantStore')(observer(ApprovalListing));
