/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Badge,
  Card,
  Divider,
  Tabs,
  Typography,
  Skeleton,
} from 'antd';
import './VehicleStatusCard.less';
import {
  Icon,
  SliderItem,
  VehicleAttachments,
  VehicleMilage,
  TirePressure,
  Toast,
  VehicleWash,
  QRCodeModal,
  NoData,
} from '../../Components';
import {
  serviceRequestProps, Vehicle, Facility, Contract, ExtendedFeatures,
} from '../../Global/interface';
import qrimage from '../../Assets/Images/comp_qr.svg';
import Constant from '../../Global/Constant';
import { IServiceStore } from '../../Store/serviceRequestStore';
import serviceRequestSevices from '../../Services/serviceRequestSevices';
import { VehicleStoreProps } from '../../Store/vehicleStore';
import vehicleService from '../../Services/vehicleService';
import noplan from '../../Assets/Images/no-plan.png';
import Utility from '../../Global/Utility';

export interface InfoCardProps {
  vehicle: Vehicle,
  vehicleId: string,
  facility: Facility,
  services?: Array<serviceRequestProps>,
  vehicleStore?: VehicleStoreProps,
  serviceStore?: IServiceStore,
  requestCode?: string,
  showStatus?: string,
  clientEmail: string,
  clientPhone: string,
  contract: Contract,
}

VehicleStatusCard.defaultProps = {
  requestCode: '',
  showStatus: '',
  services: undefined,
  vehicleStore: undefined,
  serviceStore: undefined,
};

const { TabPane } = Tabs;
const { OPEN, IN_PROGRESS, PAYMENT_PENDING } = Constant.serviceRequestStatus;
const { COMPLETE, PAYMENT_DONE } = Constant.serviceRequestActions;

function VehicleStatusCard(props: InfoCardProps) {
  const {
    vehicle, facility,
    vehicleStore, serviceStore,
    vehicleId, requestCode, showStatus,
    clientEmail, contract, clientPhone,
  } = props;
  const [vehicleStatusData, setVehicleStatusData] = useState(vehicleStore?.vehicleStatus);
  const services = serviceStore?.services;
  const features = contract?.enabled_features?.map(
    (feature: ExtendedFeatures) => feature.feature,
  ); // get plan features
  const enabledServices = services?.filter((service: any) => {
    if (service?.is_contract_required) return features?.includes(service?.service);
    return true;
  });
  const [isQRCodeModal, setIsQRCodeModal] = useState<boolean>(false);
  const defaultTab = showStatus === 'true' ? '2' : '1';
  const [activeKey, setActiveKey] = useState(defaultTab);
  const servicesRequests = serviceStore?.servicesRequests?.service_request;
  const category = vehicle?.model?.category;
  const client = servicesRequests && servicesRequests[0]?.client;
  const [isLoading, setIsLoading] = useState(false);

  const isActivePlan = (contract?.contract_status === Constant.customerContract.APPROVED
    && contract?.payment_status
    && contract?.due_date > Date.now());

  // Filter requested services from request
  const requestedServices = enabledServices?.filter((
    item: serviceRequestProps,
  ) => servicesRequests?.some(
    (
      request: any,
    ) => request?.service === item?.service,
  ));

  const getVehicleStatus = async () => {
    setIsLoading(true);
    try {
      await vehicleStore?.getVehicleStatus(vehicleId);
    } catch (err: any) {
      Toast.error(err);
    }
    setIsLoading(false);
  };

  const getServices = async () => {
    setIsLoading(true);
    try {
      await serviceStore?.getServices();
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getRequests = async () => {
    setIsLoading(true);
    try {
      await serviceStore?.getRequests({
        vehicle: vehicleId,
        status: [OPEN, IN_PROGRESS, PAYMENT_PENDING],
      });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const updateServiceRequest = async (requestId: string, action: any) => {
    setIsLoading(true);
    try {
      const response = await serviceRequestSevices.updateServiceRequest(requestId, action);
      if (response && response?.status === 200) {
        getRequests();
        let actionMessage;
        if (action?.action === COMPLETE) {
          actionMessage = 'finished';
        } else if (action?.action === PAYMENT_DONE) {
          actionMessage = 'completed';
        } else {
          actionMessage = 'started';
        }
        Toast.success(`Request ${actionMessage}`);
      }
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const updateVehicleStatus = async (statusData: any) => {
    setIsLoading(true);
    try {
      const response = await vehicleService.updateVehicleStatus(vehicleId, statusData);
      if (response && response?.status === 200) {
        getVehicleStatus();
      }
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const sendPaymentLink = async (requestId: string, data: any) => {
    setIsLoading(true);
    try {
      const response = await serviceRequestSevices.sendPaymentLink(requestId, data);
      if (response && response?.status === 200) {
        Toast.success('Link shared successfully');
      }
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const getVehicleServices = (
    inputType: string, name: string, service: string, requestStatus?: any, price?: number,
    showRequest?: boolean,
  ) => {
    const status = vehicleStatusData && vehicleStatusData[service];
    switch (inputType) {
      case 'SCALE':
        return (
          <SliderItem
            key={name}
            statusName={name}
            label="level"
            sliderStatus={status}
            IconRef={Constant.statusImage.get(service)}
            checkBoxText={`${name}`}
            request={requestStatus}
            updateServiceRequest={updateServiceRequest}
            updateVehicleStatus={(
              value: number, id?: string,
            ) => updateVehicleStatus({ service, value, service_req: id })}
          />
        );
      case 'INPUT':
        return (
          <VehicleMilage
            key={name}
            statusName={name}
            checkBoxText={`${name} status checked`}
            IconRef={Constant.statusImage.get(service)}
            milageStatus={status}
            request={requestStatus}
            updateServiceRequest={updateServiceRequest}
            updateVehicleStatus={(
              value: number, id?: string,
            ) => updateVehicleStatus({ service, value, service_req: id })}
          />
        );
      case 'TIRE':
        return (
          <TirePressure
            key={name}
            statusName={name}
            checkBoxText={`${name} status checked`}
            IconRef={Constant.statusImage.get(service)}
            unitLabel="METRICS"
            pressureStatus={status}
            request={requestStatus}
            updateServiceRequest={updateServiceRequest}
            updateVehicleStatus={(
              value: number, id?: string,
            ) => updateVehicleStatus(
              { service, value, service_req: id },
            )}
            wheels={Constant.wheels.get(category) as number}
          />
        );
      case 'BOOLEAN':
        return (
          <VehicleAttachments
            key={name}
            statusName={name}
            IconRef={Constant.statusImage.get(service)}
            attachmentStatus={status}
            request={requestStatus}
            updateServiceRequest={updateServiceRequest}
            updateVehicleStatus={(
              value: number, id?: string,
            ) => updateVehicleStatus({ service, value, service_req: id })}
          />
        );
      case 'BUTTON':
        return (
          <VehicleWash
            key={name}
            statusName={Constant.SERVICES.get(service) as string}
            IconRef={Constant.statusImage.get(service)}
            date={status?.last_updated_time}
            request={requestStatus}
            updateServiceRequest={updateServiceRequest}
            name={name}
            price={price}
            showStatus={showRequest}
            clientEmail={clientEmail}
            clientPhone={clientPhone}
            updateVehicleStatus={() => updateVehicleStatus({ service })}
            sendPaymentLink={(id, data) => sendPaymentLink(id,
              { email: data.email, phone: data.phone, client })}
            wash_details={contract?.enabled_features?.find(
              (item) => item?.feature === Constant.services.WASH,
            )}
          />
        );
      default:
        return '';
    }
  };

  // Show the selected service request in top
  const selectedServiceRequest = servicesRequests?.find(
    (request) => request?.request_code === requestCode,
  )?.service;

  const openReq = servicesRequests?.findIndex((request) => request?.status === OPEN);
  const showBadge = openReq !== -1 && activeKey !== '2';
  const onTabClick = (key: string) => setActiveKey(key);

  const serviceIndex = requestedServices?.findIndex(
    (request) => request?.service === selectedServiceRequest,
  ) as number;
  if (serviceIndex >= 0) {
    requestedServices?.unshift(requestedServices[serviceIndex]);
    requestedServices?.splice(serviceIndex + 1, 1);
  }

  const serviceReqested = requestedServices?.map((request: serviceRequestProps) => {
    const serviceRequest = servicesRequests && servicesRequests.find(
      (requestedService) => requestedService?.service === request?.service,
    );
    if (serviceRequest) {
      const status = getVehicleServices(
        request?.input_type, request?.name, request?.service, serviceRequest, request?.price,
        request?.exclude_from_status,
      );
      return status;
    }
    return '';
  });

  const vehicleStatus = enabledServices?.filter(
    (request: serviceRequestProps) => !request?.is_attachment,
  ).map((request: serviceRequestProps) => {
    const isServiceRequested = requestedServices?.findIndex(
      (requestedService) => requestedService?.service === request?.service,
    );
    if (isServiceRequested && isServiceRequested < 0) {
      const status = getVehicleServices(
        request?.input_type, request?.name, request?.service, undefined, undefined,
        request?.exclude_from_status,
      );
      return status;
    }
    return '';
  });

  const VehicleAttachment = enabledServices?.filter(
    (request: serviceRequestProps) => request?.is_attachment,
  ).map((request: serviceRequestProps) => {
    const isServiceRequested = requestedServices?.findIndex(
      (requestedService) => requestedService?.service === request?.service,
    );
    if (isServiceRequested && isServiceRequested < 0) {
      const attachments = getVehicleServices(
        request?.input_type, request?.name, request?.service,
      );
      return attachments;
    }
    return '';
  });

  const showQRCodeModal = () => {
    setIsQRCodeModal(true);
  };

  const closeQRCodeModal = () => {
    setIsQRCodeModal(false);
  };

  const operations = (
    <div className="d-flex">
      <img src={qrimage} width="44px" className="h-100" alt="qr_code" style={{ cursor: 'pointer' }} onClick={showQRCodeModal} />
      <div className="ms-3 w-75">
        <Typography.Title level={5} className="text-uppercase mb-0 primary text-truncate w-100">
          {vehicle?.model?.company} {vehicle?.model?.model}
        </Typography.Title>
        <Typography.Paragraph className="mb-0 secondary">
          {vehicle?.vehicle_code}
        </Typography.Paragraph>
      </div>
    </div>
  );

  useEffect(() => {
    setVehicleStatusData(vehicleStore?.vehicleStatus);
  }, [vehicleStore?.vehicleStatus]);

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    getRequests();
    getVehicleStatus();
  }, [vehicleId]);

  const vehicleContract = contract && Object?.keys(contract)?.length > 0;
  return (
    <Card className="info_ht">
      <Skeleton loading={isLoading} active>
        <div className="d-flex">
          <Tabs activeKey={activeKey} onTabClick={onTabClick} className="custom_tab custom_tab_v2 ext_cont_style w-100" tabBarExtraContent={operations}>
            <TabPane tab="Model" key="1">
              <div className="car_image vehicle_img_size d-flex justify-content-center m-auto">
                <img className="img-fluid" src={vehicle?.model?.image?.fileUrl || Utility.defaultModalImage(vehicle?.model?.category)} alt="vehicleimage" />
              </div>
              { !vehicle && <div className="w-100 text-center"> Vehicle is deleted</div>}
              {facility
              && (
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div>
                    <Typography.Title level={5} className="text-capitalize mb-0">
                      {facility?.name}
                    </Typography.Title>
                    <Typography.Paragraph className="mb-0 d-flex align-items-center car_location">
                      <Icon className="icon-13 me-2" iconRef="#location" />
                      {facility?.address}
                    </Typography.Paragraph>
                  </div>
                  { contract?.parked_date && (
                  <div>
                    <Typography.Title level={5} className="text-capitalize mb-0">
                      Parked Date
                    </Typography.Title>
                    <Typography.Paragraph className="mb-0 d-flex align-items-center car_location">
                      {Utility.getFormattedDate(contract?.parked_date)}
                    </Typography.Paragraph>
                  </div>
                  ) }
                </div>
              )}
            </TabPane>
            <TabPane
              tab={showBadge ? (
                <Badge
                  className="fs-16"
                  style={{ background: '#ff4141' }}
                  dot
                >Status
                </Badge>
              ) : 'Status'}
              key="2"
              disabled={!vehicle}
            >
              <Divider className="mt-2 mb-4 border-top" />
              { servicesRequests?.length ? (
                <div className="off-white-bg border-radius p-3 mb-3">
                  {serviceReqested}
                </div>
              ) : null}
              { vehicleContract && isActivePlan && (
              <div>
                <Typography.Title level={5} className="r-blod">Status</Typography.Title>
                {vehicleStatus}
                {VehicleAttachment && VehicleAttachment?.length > 0 && <Typography.Title level={5} className="r-blod border-top mt-3 pt-3">Attachments</Typography.Title>}
                {VehicleAttachment}
              </div>
              )}
              { !(vehicleContract && isActivePlan) && (
              <div>
                <Typography.Title level={5} className="r-blod">Status</Typography.Title>
                <NoData displayText="Vehicle don't have an active plan" image={noplan} />
              </div>
              )}
            </TabPane>
          </Tabs>
        </div>
        <QRCodeModal
          vehicleNo={vehicle?.vehicle_no || vehicle?.vehicle_code}
          id={vehicleId}
          isQRCodeModal={isQRCodeModal}
          closeQRCodeModal={closeQRCodeModal}
        />
      </Skeleton>
    </Card>
  );
}

export default inject('vehicleStore', 'serviceStore')(observer(VehicleStatusCard));
