const Routes = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',
  employees: '/employees',
  report: '/report',
  forgotPassword: '/forgot-password',
  setPassword: '/set-password/:token',
  notFound: '/404',
  create_employee: '/create_employee/:id?',
  employees_detail: '/employees_detail',
  clients: '/clients',
  contracts: '/plans',
  orders: '/orders',
  customers: '/customers',
  products: '/products',
  analytics: '/analytics',
  facilities: '/facilities',
  models: '/models',
  settings: '/settings',
  profile: '/profile',
  customOrders: '/custom_orders',
  returnOrders: '/return_orders',
  abandonedCart: '/abandoned_cart',
  create_client: '/create_client',
  client_detail: '/client_detail',
  create_facility: '/create_facility',
  facility_detail: '/facility_detail',
  create_model: '/create_model',
  model_detail: '/model_detail',
  approval: '/approval',
  contract_detail: '/contract_detail',
  request: '/service_request',
  approval_history: '/approval_history',
  service_request_detail: '/service_request_detail',
  model_history: '/model_history',
  client_history: '/client_history',
  shared_access: '/shared_access',
  facility_history: '/facility_history',
  renew_plan: '/renew_plan',
  payment_plan: '/payment_plan',
  shared_member: '/shared_member',
  updateProfile: '/update_profile',
  vehicleDetails: '/vehicle-detail',
  client_park_unpark: '/client_park_unpark',
  plan_history: '/plan_history',
  create_vehicle: '/create_vehicle',
  client: '/client',
};

export default Routes;
