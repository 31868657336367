/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import Constant from '../Global/Constant';
import { Model, ModelArray, ModelQueryParams } from '../Global/interface';
import modelService from '../Services/modelService';

class ModelStore {
  models: any = [] ;

  model: Model|null = null;

  modelInUse: number = 0;

  total: number = 0;

  modelsHistory: any = [] ;

  constructor() {
    makeObservable(this, {
      models: observable,
      model: observable,
      total: observable,
      modelsHistory: observable,
      modelInUse: observable,
    });
  }

  createModel = async (model: any) => {
    try {
      const response = await modelService.createModel(model);
      return Promise.resolve(response?.data);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error
        ? error.response.data.error.message
        : Constant?.defaultErrorMessage);
    }
  }

  getData = async (categories: Array<string>, published?: Boolean) => {
    try {
      const promises = categories.map((category: string) => (
        modelService.getModels(
          {
            page: Constant.defaultPageNumber, limit: Constant.modelPerPage, category, published,
          },
        )));
      const responses: Array<AxiosResponse> = await Promise.all(promises);
      runInAction(() => {
        this.models = responses.filter((response) => response?.data?.models?.length)
          .map((response: AxiosResponse) => {
            if (response?.data?.models?.length) {
              const { category } = response?.data?.models[0];
              return {
                data: response?.data?.models,
                total: response?.data?.total,
                pageNumber: Constant.defaultPageNumber,
                keyword: '',
                category,
              };
            }
            return false;
          });
      });
      return null;
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getModelList = async (modelQuery: ModelQueryParams) => {
    try {
      const response = await modelService.getModels(modelQuery);
      const index = this.models.findIndex(
        (model: ModelArray) => model?.category === modelQuery?.category,
      );
      runInAction(() => {
        this.models[index].data = response?.data?.models;
        this.models[index].total = response?.data?.total;
        this.models[index].pageNumber = modelQuery?.page;
        this.models[index].keyword = modelQuery?.keyword;
      });
      return null;
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getModelById = async (id: string, deleted?: any) => new Promise((resolve, reject) => {
    modelService.getModelById(id, deleted)
      .then((response: any) => {
        if (response?.data) {
          runInAction(() => {
            this.model = response?.data?.model;
            this.modelInUse = response?.data?.modelInUse;
          });
        }
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

      updateModel = async (id: string, model: any) => new Promise((resolve, reject) => {
        modelService.updateModel(id, model)
          .then(() => {
            resolve(null);
          })
          .catch((error: any) => {
            reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
          });
      })

      publishModel = async (id: string) => new Promise((resolve, reject) => {
        modelService.publishModel(id)
          .then(() => {
            resolve(null);
          })
          .catch((error: any) => {
            reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
          });
      })

      getModelHistory = async (categories: Array<string>) => {
        try {
          const promises = categories.map((category: string) => (
            modelService.getModels(
              {
                page: Constant.defaultPageNumber,
                limit: Constant.modelPerPage,
                category,
                deleted: true,
              },
            )));
          const responses: Array<AxiosResponse> = await Promise.all(promises);
          runInAction(() => {
            this.modelsHistory = responses.filter((response) => response?.data?.models?.length)
              .map((response: AxiosResponse) => {
                if (response?.data?.models?.length) {
                  const { category } = response?.data?.models[0];
                  return {
                    data: response?.data?.models,
                    total: response?.data?.total,
                    pageNumber: Constant.defaultPageNumber,
                    keyword: '',
                    category,
                  };
                }
                return false;
              });
          });
          return null;
        } catch (error: any) {
          return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
        }
      }

      getModelListHistory = async (deleted: boolean, pageNumber?: number,
        count?: number, category?: string, keyword?: string) => {
        try {
          const response = await modelService.getModels({
            page: pageNumber, limit: count, category, keyword, deleted,
          });
          const index = this.modelsHistory.findIndex(
            (model: ModelArray) => model?.category === category,
          );
          runInAction(() => {
            this.modelsHistory[index].data = response?.data?.models;
            this.modelsHistory[index].total = response?.data?.total;
            this.modelsHistory[index].pageNumber = pageNumber;
            this.modelsHistory[index].keyword = keyword;
          });
          return null;
        } catch (error: any) {
          return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
        }
      }
}

export default new ModelStore();
