/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import {
  Button, Checkbox, Col, Divider, Form, Input, Radio, Row, Spin, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import classNames from 'classnames';
import Paragraph from 'antd/lib/typography/Paragraph';

import { Icon, Toast } from '..';
import Utility from '../../Global/Utility';
import rules from './overDueForm.validation';
import Constant from '../../Global/Constant';

const { Title } = Typography;
interface OverDueProps {
  contract: any,
  handleOverDue: (id: string, params:any) => void
  loading: boolean,
  invoiceAmount: number|null,
  dueDays: number|null,
  email: string,
  phone: string,
}

function OverDue(props: OverDueProps) {
  const {
    contract, handleOverDue, loading, invoiceAmount, dueDays, email, phone,
  } = props;
  const [form] = useForm();
  const CheckboxGroup = Checkbox.Group;

  const month = contract?.duration / 30;
  const [paymentMode, setPaymentMode] = useState<String>('GATEWAY');
  const [isLinkShared, setIsLinkShared] = useState<any>(contract?.payment_link_sent);
  const [isEmailSelected, setIsEmailSelected] = useState(false);
  const [isPhoneSelected, setIsPhoneSelected] = useState(false);

  const handlePaymentMode = (e: any) => {
    setPaymentMode(e.target.value);
    setIsEmailSelected(false);
    setIsPhoneSelected(false);
  };
  const handlePayment = async (value: any) => {
    if (value.email || value.phone) {
      const params: any = {
        paymentMode,
      };
      if (value.email) params.email = value?.email;
      if (value.phone) params.phone = value?.phone;

      if (value?.close_plan && value?.close_plan[0]) params.close_plan = value?.close_plan[0];
      handleOverDue(contract?._id, params);
    } else {
      Toast.error('Please select Email or Phone');
    }
  };

  useEffect(() => {
    setIsLinkShared(contract?.payment_link_sent);
  }, [contract?.payment_link_sent]);

  useEffect(() => {
    form.setFieldsValue({ email });
  }, [email]);

  useEffect(() => {
    form.setFieldsValue({ phone });
  }, [phone]);

  const handleEmailCheckbox = (e: any) => {
    setIsEmailSelected(e.target.checked);
  };
  const handlePhoneCheckbox = (e: any) => {
    setIsPhoneSelected(e.target.checked);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="position-relative mt-3">
          <div className={classNames('plan_info_img exo_fnt position-absolute d-flex align-items-center justify-content-start ps-2', { 'fs-60': month >= 12 })}>
            {month}<sub className="r-medium secondary fs-16 roboto position-absolute">{month > 1 ? 'Month' : 'Months'}</sub>
          </div>
          <div className="plan_info_pad d-flex align-items-center plan_price_wrap_ht">
            <div className="border-right pe-4 me-4">
              <Typography.Title level={3} className="mb-0">
                {contract?.contract?.plan} Plan
              </Typography.Title>
              <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2">Expired on {Utility.getFormattedDate(contract?.due_date)}</Typography.Paragraph>
            </div>
            <div>
              <Typography.Title level={3} className="mb-0 red">
                {invoiceAmount} KD
              </Typography.Title>
              <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2">{`Penalty (${dueDays} ${dueDays && dueDays > 1 ? 'days' : 'day'})`}</Typography.Paragraph>
            </div>
            {isLinkShared
            && (
            <div className="border-left ps-4 ms-4 d-flex align-items-center">
              <div className="pay_icn_size white yellow_bg p-0 rounded-circle justify-content-center d-flex align-items-center cir_y_outline me-4">
                <Icon className="icon-24" iconRef="#tick" />
              </div>
              <div className="d-flex align-items-center w-100">

                <Typography className="w-100">
                  <Title level={3} className="text-truncate primary r-bold mb-0" title="Waiting for payment">Waiting for payment</Title>
                  {contract?.payment_link_sent_date && <Paragraph className="secondary mb-0 black">Link shared {Utility?.getRelativeDate(contract?.payment_link_sent_date)}</Paragraph>}
                </Typography>
              </div>
            </div>
            )}
          </div>
        </div>
        <Divider className="border-top" />
        <Form layout="vertical" validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError onFinish={handlePayment} form={form}>
          <Row
            gutter={{
              xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
            }}
          >
            <Col md={24} lg={4}>
              <Typography.Title className="m-0" level={5}>
                Method
              </Typography.Title>
            </Col>
            <Col md={24} lg={20}>
              <Typography.Text className="m-0 secondary fs-16">
                Choose how you want to close the plan
              </Typography.Text>
              <Form.Item name="payment_method" className="mandatory mt-4">
                <Radio.Group className="cust_radio_btn" defaultValue="GATEWAY" onChange={handlePaymentMode}>
                  <Radio className="radio_inp" value="GATEWAY">Send link</Radio>
                  <Radio className="radio_inp" value="CASH">Cash payment</Radio>
                  <Radio className="radio_inp" value="POS">POS payment</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider className="border-top" />
              {paymentMode === 'GATEWAY'
              && (
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                  <Typography.Text className="m-0 secondary fs-16">
                    Select how you want to send the link
                  </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                  <CheckboxGroup>
                    <Checkbox className="chkbox_style primary r-regular fs-16 radio_inp" onChange={handleEmailCheckbox}>Email</Checkbox>
                  </CheckboxGroup>
                  <CheckboxGroup className="p-3">
                    <Checkbox className="chkbox_style primary r-regular fs-16 radio_inp" onChange={handlePhoneCheckbox}>Phone</Checkbox>
                  </CheckboxGroup>
                </Col>
              </Row>
              )}
              <Row gutter={16}>
                {(isEmailSelected || paymentMode !== 'GATEWAY')
                && (
                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                  <Form.Item rules={rules.email} label={<Typography.Text className="secondary r-medium">Email</Typography.Text>} name="email" className="mandatory">
                    <Input placeholder="Enter email" className="inp_style" maxLength={50} />
                  </Form.Item>
                </Col>
                )}
                {isPhoneSelected
                && (
                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                  <Form.Item label="PHONE NUMBER" className="mandatory" name="phone" normalize={Utility.trimSpacesInValues} rules={rules.phone}>
                    <PhoneInput
                      defaultCountry="KW"
                      international
                      countries={Constant.myfatoorahSupportedCountries as any}
                      className="phn_inp_style"
                      countryCallingCodeEditable={false}
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={() => {}}
                      maxLength={16}
                    />
                  </Form.Item>
                </Col>
                )}
              </Row>
              {paymentMode !== 'GATEWAY'
              && (
              <div>
                <Typography.Text className="m-0 secondary fs-16">
                  Select if the client wants to close the plan
                </Typography.Text>
                <Form.Item name="close_plan" className="mandatory mt-4">
                  <Checkbox.Group>
                    <Checkbox value> Close plan </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </div>
              )}
              {(isEmailSelected || isPhoneSelected || paymentMode !== 'GATEWAY')
              && (
              <Typography.Text className="m-0 secondary fs-16">
                The invoice will be shared with client through mail once the payment is completed
              </Typography.Text>
              )}
              <Divider className="border-top" />
              <div className="d-flex mt-4 ps-lg-0 ps-4">
                <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                  {paymentMode === 'GATEWAY' ? 'Send Link' : 'Unpark vehicle'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
export default OverDue;
