/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import authStore from './authStore';
import userStore from './userStore';
import clientStore from './clientStore';
import contractStore from './contractStore';
import countryStore from './countryStore';
import dashboardStore from './dashboardStore';
import facilityStore from './facilityStore';
import masterStore from './masterStore';
import modelStore from './modelStore';
import requestStore from './requestStore';
import vehicleStore from './vehicleStore';
import serviceStore from './serviceRequestStore';
import UIStore from './UIStore';
import parkUnparkStore from './parkUnparkStore';
import settingsStore from './settingsStore';
import searchStore from './searchStore';
import notificationStore from './notficationStore';
import tenantStore from './tenantStore';

export default {
  authStore,
  userStore,
  clientStore,
  contractStore,
  countryStore,
  dashboardStore,
  facilityStore,
  masterStore,
  modelStore,
  requestStore,
  vehicleStore,
  serviceStore,
  UIStore,
  parkUnparkStore,
  settingsStore,
  searchStore,
  notificationStore,
  tenantStore,
};
