/* eslint-disable camelcase */
import React from 'react';

interface StatusProps{
    statusText: any,
    status: string,
    statusClassName: any
}

function Status(props: StatusProps) {
  const { statusText, status, statusClassName } = props;
  return (
    <div
      className={`${statusClassName.get(status)}
     rounded-pill ps-2 pe-2 w-xl-100 d-inline-block ellipse text-center`}
      title={statusText.get(status)}
    >
      {statusText.get(status)}
    </div>
  );
}

export default Status;
