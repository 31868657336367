/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Typography,
  Slider,
  Row,
  Divider,
  Button,
} from 'antd';
import { Action, Icon } from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

interface Props {
  statusName: string
  IconRef: any
  label: string,
  checkBoxText: string,
  sliderStatus: any,
  request?: any,
  updateServiceRequest: (id: string, action: any) => void
  updateVehicleStatus: (statusData: any, id?: string) => void
}

SliderItem.defaultProps = {
  request: null,
};

const { OPEN } = Constant.serviceRequestStatus;
const { START, COMPLETE } = Constant.serviceRequestActions;

function SliderItem(props: Props) {
  const {
    label, checkBoxText, statusName, IconRef, sliderStatus, request,
    updateServiceRequest, updateVehicleStatus,
  } = props;
  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const [sliderValue, setSliderValue] = useState<number>(Constant.defaultSliderValue);
  const isRequestStarted = request?.status === OPEN;

  useEffect(() => {
    setSliderValue(sliderStatus?.value || Constant.defaultSliderValue);
  }, [sliderStatus]);

  const sliderColor = () => {
    if (sliderValue < 4) return '#FF4141';
    if (sliderValue >= 4 && sliderValue < 7) return '#F1AA1D';
    return '#26D65B';
  };

  const levelIndicator = () => {
    if (sliderValue < 4) return 'Low';
    if (sliderValue >= 4 && sliderValue < 7) return 'Medium';
    return 'High';
  };

  const onConfirm = () => {
    setIsStatusChecked(false);
    updateVehicleStatus(sliderValue);
  };

  const onCancel = () => {
    setIsStatusChecked(false);
    setSliderValue(sliderStatus?.value || Constant.defaultSliderValue);
  };

  const onCheckboxHandler = () => setIsStatusChecked(!isStatusChecked);
  const onClickHandler = () => updateServiceRequest(request?._id, { action: START });

  const onChangeHandler = (value: number) => {
    if (!isRequestStarted) setSliderValue(value);
  };

  const onFinishHandler = () => {
    updateVehicleStatus(sliderValue, request?._id);
    updateServiceRequest(request?._id, { action: COMPLETE });
  };

  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      { request && (
      <div>
        <Row gutter={16}>
          <Col lg={9} md={9} sm={9} xs={24}>
            <div className="d-flex align-items-center fs-16">
              <div className="icn_bg_44 primary rounded-circle d-flex align-items-center
                     justify-content-center me-3"
              ><Icon className="icon-20" iconRef={IconRef} />
              </div>
              <div className="lh-sm r-bold fs-16">
                <Typography.Text className="r-medium fs-14 secondary text-uppercase">Request</Typography.Text><br />
                {statusName}
              </div>
            </div>
          </Col>
          <Col lg={15} md={15} sm={15} xs={24}>
            <Row>
              <Col lg={15} md={15} sm={15} xs={24}>
                <div className="d-flex justify-content-between justify-content-sm-start mt-3 mt-sm-0 mb-3 mb-sm-0">
                  <div className="me-3 r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Date:</Typography.Text><br />
                    {Utility?.getFormattedDate(request?.scheduled_time)}
                  </div>
                  <div className="r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Time</Typography.Text><br />
                    {Utility?.getFormattedTime(request?.scheduled_time)}
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={9} xs={24} className="d-flex justify-content-sm-end align-items-start">
                {isRequestStarted && (
                <Button className="fs-16 green_bg white border-radius border-0 pt-1 pb-1 start_btn_resp r-bold h-40" onClick={onClickHandler}>
                  Start Task
                </Button>
                ) }
                {!isRequestStarted && (<div className="label_warning rounded-pill pt-1 pb-1">In Progress </div>)}
              </Col>
            </Row>
            <Divider className="mt-3 mb-3 border-bottom border-xs-bottom" />
          </Col>
        </Row>
      </div>
      ) }
      <Row gutter={16}>
        <Col lg={9} md={9} sm={9} xs={24}>
          { !request && (
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
              <Icon className="icon-20" iconRef={IconRef} />
            </div>
            {statusName}
          </div>
          ) }
        </Col>
        <Col lg={9} md={9} sm={9} xs={24} className="ps-4 ps-sm-2 pe-4 pe-sm-2">
          <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">{label} - {levelIndicator()}</Typography.Text>
          <Slider
            trackStyle={{ height: '12px', background: sliderColor(), borderRadius: '25px' }}
            handleStyle={{
              width: '24px', height: '24px', marginTop: '-6px', background: '#F2F2F2', boxShadow: '0px 2px 2px #00000029', border: '1px solid #E3E3E3',
            }}
            step={1}
            defaultValue={0}
            max={10}
            tipFormatter={null}
            value={sliderValue}
            className="cmn_slider"
            onChange={onChangeHandler}
          />
          { !isRequestStarted && (
          <Checkbox
            className="chkbox_style primary r-regular fs-16 mt-3"
            onChange={onCheckboxHandler}
            checked={isStatusChecked}
          >{checkBoxText} Checked
          </Checkbox>
          ) }
          { sliderStatus?.last_updated_time && <Typography.Paragraph className="fs-16 mt-2 mb-0 inactive">Last updated - {Utility.getFormattedDate(sliderStatus?.last_updated_time)}</Typography.Paragraph>}
          { request && !isRequestStarted
        && (
        <Button
          className="yellow_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
          disabled={!isStatusChecked}
          onClick={onFinishHandler}
        >Finish Task
        </Button>
        )}
        </Col>
        { !request && isStatusChecked && (
          <Col
            lg={5}
            className="d-flex justify-content-end
                  align-items-start mt-3 mt-sm-0"
          ><Action onAccept={onConfirm} onReject={onCancel} />
          </Col>
        )}
      </Row>
    </div>
  );
}
export default SliderItem;
