/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Button, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Typography,
} from 'antd';
import Link from 'antd/lib/typography/Link';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import {
  Icon, ListDocument, UploadDocument, UploadImage,
} from '..';
import rules from '../../Pages/CreateEmployee/create-employee-form.validation';
import utility from '../../Global/Utility';
import masterStore from '../../Store/masterStore';
import fileService from '../../Services/fileService';
import { EmployeeForm } from '../../Global/interface';
import 'react-phone-number-input/style.css';

interface EmployeeFormProps {
  isUpdate: boolean
  isLoading: boolean
  employeeDetails: EmployeeForm
  onCancel: () => void
  onFinish: (value: EmployeeForm) => void
}

function EmployeeForms(props: EmployeeFormProps) {
  const {
    isUpdate, isLoading, employeeDetails, onFinish, onCancel,
  } = props;

  const [selectedCountryName, setSelectedCountryName] = useState<null|String>(null);
  const [selectedStateName, setSelectedStateName] = useState<null|String>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDocUploading, setIsDocUploading] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>(null);
  const [isProfileUploading, setIsProfileUploading] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);
  const [phone, setPhone] = useState<any>();
  const { Option } = Select;
  const countryData = masterStore.countries;
  const countryCodes = masterStore?.contryCodes;
  const [form] = useForm();

  const countryChanged = (countryName: any) => {
    setSelectedCountryName(countryName);
    form.setFieldsValue({ state: null, city: null });
  };

  const stateChanged = (stateName: any) => {
    setSelectedStateName(stateName);
    form.setFieldsValue({ city: null });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const uploadDocuments = async (selectedDocuments: any) => {
    setIsDocUploading(true);
    const fileFormData = new FormData();
    selectedDocuments.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
    const response = await fileService.uploadFile(fileFormData);
    setIsModalVisible(false);
    setIsDocUploading(false);
    if (uploadedDocuments) {
      setUploadedDocuments([...uploadedDocuments, ...response?.data]);
    } else {
      setUploadedDocuments(response?.data);
    }
  };

  const uploadProfile = async (fileInfo:any) => {
    if (fileInfo?.fileList?.length) {
      setIsProfileUploading(true);
      const fileFormData = new FormData();
      fileFormData.set('document', fileInfo?.fileList[0].originFileObj);
      const response = await fileService.uploadFile(fileFormData);
      setProfile(response?.data[0]);
      setIsProfileUploading(false);
    }
  };

  const handleOnChangeProfile = (fileInfo: any) => {
    uploadProfile(fileInfo);
  };

  const removeProfile = () => {
    setProfile(null);
  };

  const removeFileFromUploaded = (index:number) => {
    setUploadedDocuments(
      uploadedDocuments.filter((value : any, actualIndex:number) => actualIndex !== index),
    );
  };

  const onFinishForm = (user: EmployeeForm) => {
    user.profile = profile;
    user.documents = uploadedDocuments;
    onFinish(user);
  };

  useEffect(() => {
    if (isUpdate) {
      form.setFieldsValue({
        first_name: employeeDetails?.first_name,
        middle_name: employeeDetails?.middle_name,
        last_name: employeeDetails?.last_name,
        gender: employeeDetails?.gender,
        email: employeeDetails?.email,
        phone: employeeDetails?.phone,
        country: employeeDetails?.address?.country,
        state: employeeDetails?.address?.state,
        address_line_1: employeeDetails?.address?.address_line_1,
        address_line_2: employeeDetails?.address?.address_line_2,
        zip_code: employeeDetails?.address?.zip_code,
        city: employeeDetails?.address?.city,
        dob: employeeDetails?.dob && moment(employeeDetails?.dob),
      });
      setSelectedCountryName(employeeDetails?.address?.country);
      setSelectedStateName(employeeDetails?.address?.state);
      if (employeeDetails?.profile?.fileUrl) {
        setProfile(employeeDetails?.profile);
      }
      setUploadedDocuments(employeeDetails?.documents);
    }
  }, [employeeDetails]);

  return (
    <Form initialValues={{ gender: 'Male' }} layout="vertical" onFinish={onFinishForm} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
      <Row
        gutter={{
          xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
        }}
        className="m-0"
      >
        <Col md={24} lg={4}>
          <Typography.Title className="m-0" level={5}>
            Profile
          </Typography.Title>
        </Col>
        <Col md={24} lg={20}>
          <Typography.Text className="m-0 create_para">
            Enter employee basic informations
          </Typography.Text>
          <div className="d-flex mt-3 mb-3">
            <Form.Item name="profile" className="mb-0">
              <div className="position-relative">
                <UploadImage
                  handleOnChangeImage={handleOnChangeProfile}
                  image={profile}
                  removeImage={removeProfile}
                  isLoading={isProfileUploading}
                />
              </div>
            </Form.Item>
            {profile?.fileUrl
          && <Link className="d-flex ml-auto ms-3 r-bold align-items-center delete-btn" onClick={removeProfile}>Remove</Link>}
          </div>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="FIRST NAME" className="mandatory" name="first_name" normalize={utility.trimSpacesInValues} required={false} rules={rules.name}>
                <Input placeholder="Enter first name" className="inp_style" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="MIDDLE NAME" name="middle_name" normalize={utility.trimSpacesInValues} required={false} rules={rules.middle_name}>
                <Input placeholder="Enter middle name" className="inp_style" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="LAST NAME" className="mandatory" name="last_name" normalize={utility.trimSpacesInValues} required={false} rules={rules.last_name}>
                <Input placeholder="Enter last name" className="inp_style" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item name="gender" label="gender" className="mandatory" rules={rules.gender}>
                <Radio.Group className="cust_radio_btn">
                  <Radio className="radio_inp" value="Male">Male</Radio>
                  <Radio className="radio_inp" value="Female">Female</Radio>
                  <Radio className="radio_inp" value="Other">Other</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="DATE OF BIRTH" name="dob">
                <DatePicker
                  className="inp_style date_picker"
                  dropdownClassName="date_picker_drp_dwn"
                  showToday={false}
                  format="DD-MM-YYYY"
                  disabledDate={utility.disabledFutureDates}
                  suffixIcon={(
                    <Icon className="icon-16" iconRef="#calendar" />
                )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="EMAIL" name="email" className="mandatory" normalize={utility.trimSpacesInValues} rules={rules.email}>
                <Input placeholder="Enter email" className="inp_style" disabled={isUpdate} maxLength={50} />
              </Form.Item>
            </Col>
            {/* phone update */}
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="PHONE NUMBER" name="phone" normalize={utility.trimSpacesInValues} rules={rules.phone}>
                {countryCodes
                && (
                <PhoneInput
                  defaultCountry="KW"
                  international
                  countries={countryCodes}
                  className="phn_inp_style"
                  countryCallingCodeEditable={false}
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={setPhone}
                  maxLength={16}
                />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider className="m-0 border-top" />
        </Col>
      </Row>
      <Row
        gutter={{
          xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
        }}
        className="m-0 mt-3"
      >
        <Col md={24} lg={4}>
          <Typography.Title className="m-0" level={5}>
            Address
          </Typography.Title>
        </Col>
        <Col md={24} lg={20}>
          <Typography.Text className="m-0 create_para">
            Enter employee address
          </Typography.Text>
          <Row gutter={16} className="mt-3">
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="country" className="mandatory" name="country" rules={rules.country}>
                <Select
                  onChange={countryChanged}
                  suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                  placeholder="Choose country"
                  className="slct_style"
                  dropdownClassName="slct_drp_dwn border-radius"
                >
                  {countryData && Array.from(countryData?.keys())
                    ?.map(
                      (country:any) => <Option key={country} value={country}>{country}</Option>,
                    )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="state" className="mandatory" name="state" rules={rules.state}>
                <Select
                  onChange={stateChanged}
                  suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                  placeholder="Choose state"
                  className="slct_style"
                  dropdownClassName="slct_drp_dwn border-radius"
                >
                  {selectedCountryName
                && countryData?.get(selectedCountryName)?.keys()
                && Array.from(countryData?.get(selectedCountryName)?.keys())
                  ?.map((state:any) => <Option key={state} value={state}>{state}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="city" className="mandatory" name="city" rules={rules.city}>
                <Select
                  suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                  placeholder="Choose city"
                  className="slct_style"
                  dropdownClassName="slct_drp_dwn border-radius"
                >
                  {selectedStateName
                 && countryData?.get(selectedCountryName)?.keys()
                && countryData?.get(selectedCountryName).get(selectedStateName)
                  ?.cities?.map((city:any) => <Option key={city} value={city}>{city}</Option>)}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="POST CODE" name="zip_code" normalize={utility.trimSpacesInValues} rules={rules.zip_code}>
                <Input placeholder="Enter post code" className="inp_style" maxLength={15} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="Address Line 1" name="address_line_1" normalize={utility.trimSpacesInValues} rules={rules.addressLine1}>
                <Input placeholder="Enter address line 1" className="inp_style" maxLength={50} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
              <Form.Item label="Address Line 2" name="address_line_2" normalize={utility.trimSpacesInValues} rules={rules.addressLine2}>
                <Input placeholder="Enter address line 2" className="inp_style" maxLength={50} />
              </Form.Item>
            </Col>
          </Row>
          <Divider className="m-0 border-top" />
        </Col>
      </Row>
      <Row
        gutter={{
          xs: 24, sm: 24, md: 24, lg: 32, xl: 32, xxl: 64,
        }}
        className="m-0 mt-3"
      >
        <Col lg={4} md={24} sm={24} xs={24}>
          <Typography.Title className="m-0" level={5}>
            Documents
          </Typography.Title>
        </Col>
        <Col lg={20} md={24} sm={24} xs={24}>
          <div className="d-flex justify-content-between">
            <Typography.Text className="m-0 create_para me-2 me-sm-0">
              Upload verification documents such as ID cards here
            </Typography.Text>
            <Button className="d-flex ml-auto mb-3 r-bold align-items-center back_link p-0" type="link" onClick={showModal}>
              Upload
              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
            </Button>
          </div>
          { uploadedDocuments?.length > 0
          && (
          <ListDocument
            documents={uploadedDocuments}
            size={20}
            removeFile={(index: number) => removeFileFromUploaded(index)}
          />
          )}
          <Divider className="m-0 border-top" />
          <div className="d-flex mt-4 ps-lg-0">
            <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
              {isUpdate ? 'Update' : 'Create'}
            </Button>
            <Button type="text" className="cancel_btn fs-16 r-bold" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </Col>
        <UploadDocument
          isModalVisible={isModalVisible}
          handleModelCancel={handleCancel}
          uploadDocuments={uploadDocuments}
          uploadedDocuments={uploadedDocuments}
          isLoading={isDocUploading}
          acceptedExtension=".pdf,.doc,.jpeg,.png"
        />
      </Row>
    </Form>
  );
}

export default EmployeeForms;
