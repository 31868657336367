/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable class-methods-use-this */
import moment, { Moment } from 'moment';
import { phone } from 'phone';
import { parsePhoneNumber } from 'react-phone-number-input'; // phone update
import Constant from './Constant';
import defaultModelCar from '../Assets/Images/modelCar.png';
import defaultModelBike from '../Assets/Images/modelBike.png';
import defaultModelMarine from '../Assets/Images/modelMarine.png';
import defaultModelOthers from '../Assets/Images/modelOthers.png';

const { CLIENT_DELETE, MODEL_DELETE, FACILITY_DELETE } = Constant.employeeRequest;

class Utility {
  parseQueryString(path: string) {
    const parsedQueryString: any = {};

    const queryString = path?.split('?')[1];
    const queryStringList = queryString?.split('&');

    queryStringList?.forEach((item) => {
      const key = item.split('=')[0];
      const value = item.split('=')[1];
      parsedQueryString[decodeURIComponent(key)] = this.decodeValue(value);
    });

    return parsedQueryString;
  }

  decodeValue(value: any) {
    const decodedValue = decodeURIComponent(value || '');
    try {
      return JSON.parse(decodedValue);
    } catch (e: any) {
      return decodedValue;
    }
  }

  parseJwt(token: string) {
    try {
      if (token) {
        const base64Url = token?.split('.')[1];
        const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
        return JSON.parse(jsonPayload);
      }
      return null;
    } catch (err: any) {
      return null;
    }
  }

  getRefreshTokenTime = () => {
    if (localStorage?.refreshToken) {
      const refreshToken = this.parseJwt(localStorage?.refreshToken);
      return refreshToken?.exp;
    }
    return null;
  };

  trimSpacesInValues(value: string) {
    if (value !== undefined && value !== null) {
      return value?.trimLeft()?.replace(/( )\1+/g, '$1');
    }
    return '';
  }

  getFormattedDate = (date: Date | number) => {
    if (date) {
      return moment(date).format('DD MMM YYYY');
    }
    return null;
  }

  getFormattedTime = (date: Date | number) => {
    if (date) {
      return moment(date).format('hh:mm a');
    }
    return null;
  }

  getRelativeDate = (date: Date | number) => {
    if (date) {
      const timeDifference = this.calculateDateDifference(date, Date.now());
      if (timeDifference <= 2) {
        return moment.utc(date).fromNow();
      }
      return moment(date).format('DD/MM/YYYY');
    }
    return null;
  }

  calculateDateDifference = (startDate: any, endDate: any) => moment(endDate).diff(moment(startDate), 'days')

  getPercentageColor = (percentage: number) => {
    if (percentage >= 50) {
      return '#26D65B';
    }
    if (percentage > 20 && percentage < 50) {
      return '#FFC041';
    }
    return '#FF0000';
  };

  getRequestText = (request: string) => {
    const requests = new Map([[CLIENT_DELETE, 'Client Delete'], [FACILITY_DELETE, 'Facility Delete'], [MODEL_DELETE, 'Model Delete']]);
    return requests.get(request);
  }

  getContractTypeText = (contractType: string) => {
    const contractTypes = new Map([[Constant.VAULT_CONTRACT, 'Vault Contract'], [Constant.SERVICE_CONTRACT, 'Service Contract']]);
    return contractTypes.get(contractType);
  }

  disabledFutureDates = (current: Moment): boolean => current && current > moment();

  getMonthFromDays = (days: number) => {
    const month = days / 30;
    if (month <= 1) return `${month} month`;
    return `${month} months`;
  }

  downloadFile = async (url: any, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName,
    );
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
  };

  // Create blob link from url to download
  createObjectUrlFromUrl = async (url: string) => {
    const blob = await fetch(url).then((file: any) => file.blob());
    const objectUrl = window.URL.createObjectURL(blob);
    return objectUrl;
  };

  // Create blob link from buffer to download
  createObjectUrlFromData = (data: any) => window.URL.createObjectURL(new Blob([data]))

  // phone update
  phoneOptionalValidator = (field : any, value : any, callback : Function) => {
    if (value && parsePhoneNumber(value)?.nationalNumber) {
      if (!phone(value, { country: '' })?.isValid) {
        callback(Constant.invalidPhone);
      }
    }
    callback();
  }

  // phone update
  phoneRequiredValidator = (field:any, value:any, callback:Function) => {
    if (!phone(value, { country: '' })?.isValid) {
      if (!value) callback(Constant.phoneRequired);
      else callback(Constant.invalidPhone);
    }
    callback();
  };

  defaultModalImage = (categories:any) => {
    switch (categories) {
      case 'car':
        return defaultModelCar;
      case 'motorbike':
        return defaultModelBike;
      case 'marine':
        return defaultModelMarine;
      default:
        return defaultModelOthers;
    }
  };

  saveMileagePreference = (unit: string) => {
    localStorage.setItem('mileageUnit', unit);
  }

  // phone update
  phoneParser = (phoneNumber: any) => {
    if (phoneNumber && parsePhoneNumber(phoneNumber)?.nationalNumber) {
      return phoneNumber;
    }
    return null;
  };

  getRelativeDays = (date: Date | number) => {
    if (date) {
      const endDate = moment(date).utc();
      const currentDate = moment().utc().startOf('day');
      const days = Math.abs(endDate.diff(currentDate, 'days'));

      return `${days} ${days > 1 ? 'Days' : 'Day'}`;
    }
    return null;
  };

  calculateDatePercentage = (
    startDate: number|undefined, dueDate: number|undefined, isPlanClosed?: boolean,
  ) => {
    if (isPlanClosed) return 0;
    const totalContractDays = this.calculateDateDifference(startDate, dueDate);
    const contractDaysLeft = this.calculateDateDifference(moment(Date.now()), dueDate);
    const percentage = ((contractDaysLeft) / (totalContractDays)) * 100;
    return percentage > 10 ? percentage : 10;
  };
}

export default new Utility();
