/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Constant from '../../Global/Constant';

const rule: any = {
  contract: [{
    required: true,
    message: Constant.contractPeriodRequired,
  }],
  start_date: [{
    required: true,
    message: Constant.startDateRequired,
  }],
  expiry_date: [{
    required: true,
    message: Constant.expiryDateRequired,
  }],
  vehicle_no: [{
    required: true,
    message: Constant.vehicleNoRequired,
  },
  {
    type: 'string',
    pattern: /^[a-zA-Z0-9 ]*[a-zA-Z][a-zA-Z0-9 ]*$/,
    message: Constant.invalidVehicleNumber,
  }],
  chassis_no: [{
    required: true,
    message: Constant.chassisNoRequired,
  },
  {
    type: 'string',
    pattern: /([A-Za-z0-9])/,
    message: Constant.invalidChassisNumber,
  }],
  facility: [{
    required: true,
    message: Constant.facilityRequired,
  }],
  parked_date: [{
    required: true,
    message: Constant.parkedDateRequired,
  }],
};

export default rule;
