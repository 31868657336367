/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  useParams, Link, useHistory,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Radio,
  Row,
  Skeleton,
  Spin,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import debounce from 'lodash.debounce';
import { Link as ScrollLinks, Element } from 'react-scroll';
import classnames from 'classnames';
import moment from 'moment';

import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';

import {
  UserCard, ContactCard, Address, Icon, Pagination, Search, NoData, Toast, SortTh, Status, AddPlan,
} from '../../Components';
import ConfirmModal from '../../Components/ConfirmModal';
import clientService from '../../Services/clientService';
import requestService from '../../Services/requestService';
import contractService from '../../Services/contractService';
import ActivateAccount from './ActivateAccount';
import { Contract, Vehicle } from '../../Global/interface';

import './Clients.less';

const { Sider } = Layout;
const { Title, Paragraph } = Typography;
const { contractTypes } = Constant;
const { ACTIVE, EXPIRED, PAYMENT_PENDING } = contractTypes;
const contractStatus = new Map([
  ['REQUESTED', 'Requested'],
  ['APPROVED', 'Payment Pending'],
]);

function ClientDetails(props: any) {
  const { authStore, clientStore, masterStore } = props;
  const { facilities } = masterStore;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [vehiclePageNo, setVehiclePageNo] = useState<number>(Constant.defaultPageNumber);
  const [vehicleKeyword, setVehicleKeyword] = useState<string>('');
  const [vehicleOrder, setVehicleOrder] = useState('model.company');
  const [vehicleOrderBy, setVehicleOrderBy] = useState(-1);
  const [transactionPageNo, setTransactionPageNo] = useState<number>(Constant.defaultPageNumber);
  const [transactionOrder, setTransactionOrder] = useState('');
  const [transactionOrderBy, setTransactionOrderBy] = useState(1);
  const [contractPageNo, setContractPageNo] = useState<number>(Constant.defaultPageNumber);
  const [contractKeyword, setContractKeyword] = useState<string>('');
  const [contractOrder, setContractOrder] = useState('contract_code');
  const [contractOrderBy, setContractOrderBy] = useState(-1);
  const [contractPlanType, setContractPlanType] = useState(ACTIVE);
  const [sharedVehicle, setSharedVehicle] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPlanModel, setShowPlanModel] = useState(false);
  const [isPlanPaid, setIsPlanPaid] = useState(false);
  const [isVehicleInside, setIsVehicleInside] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string>('');

  const { id } = useParams<any>();
  const history = useHistory();
  const {
    vehicles, activeContracts, contracts, client, memberCountDetails, clientTransactions,
  } = clientStore;

  const memberCount = memberCountDetails?.sharedMemberTotal?.length
  && memberCountDetails?.sharedMemberTotal[0]?.count;

  const accessCount = memberCountDetails?.sharedAccessTotal?.length
  && memberCountDetails?.sharedAccessTotal[0]?.count;

  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);

  const vehicleColumn = [
    {
      title: <SortTh className="srt_arw_position" title="Manufacturer" dataIndex="model.company" order="model.company" orderBy={vehicleOrderBy} />,
      dataIndex: 'company',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable('model.company');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      ellipsis: true,
    },
    {
      title: 'Year',
      dataIndex: 'year',
      ellipsis: true,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      ellipsis: true,
    },
    {
      title: 'Plan',
      dataIndex: 'contract',
      ellipsis: true,
    },
    {
      title: 'Number',
      dataIndex: 'number',
      ellipsis: true,
    },
  ];

  if (sharedVehicle) {
    vehicleColumn.splice(2, 1); // remove year from column
    vehicleColumn.push({
      title: 'Client',
      dataIndex: 'client',
      ellipsis: true,
    }, {
      title: 'Access',
      dataIndex: 'access',
      ellipsis: true,
    });
  }
  const sortTable = (dataIndex: string) => {
    const nextSort = vehicleOrderBy === -1 ? 1 : -1;
    setVehicleOrderBy(nextSort);
    setVehicleOrder(dataIndex);
    getClientVehicle(sharedVehicle, id, vehiclePageNo, vehicleKeyword, nextSort, dataIndex);
  };
  const TransactionColumn = [
    {
      title: <SortTh className="srt_arw_position" title="Transactions" dataIndex="payment_type" order="payment_type" orderBy={transactionOrderBy} />,
      dataIndex: 'payment_type',
      onHeaderCell: () => ({
        onClick: () => {
          sortTransactionTable('payment_type');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      ellipsis: true,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      ellipsis: true,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice',
      ellipsis: true,
    },
  ];

  const sortTransactionTable = (dataIndex: string) => {
    const nextSort = transactionOrderBy === -1 ? 1 : -1;
    setTransactionOrderBy(nextSort);
    setTransactionOrder(dataIndex);
    getClientTransactions(id, transactionPageNo, nextSort, dataIndex);
  };

  const columndata = [
    {
      title: <SortTh title="Plan ID" dataIndex="contract_code" order="contract_code" orderBy={contractOrderBy} />,
      dataIndex: 'contract_code',
      onHeaderCell: () => ({
        onClick: () => {
          sortContractTable('contract_code');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Plan Type',
      dataIndex: 'planType',
      ellipsis: true,
    },
    {
      title: 'Plan Term',
      dataIndex: 'planTerm',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      ellipsis: true,
    },
    {
      title: contractPlanType === PAYMENT_PENDING ? 'Status' : 'Due Date',
      dataIndex: contractPlanType === PAYMENT_PENDING ? 'status' : 'due_date',
      ellipsis: true,
    },
  ];

  const sortContractTable = (dataIndex: string) => {
    const nextSort = contractOrderBy === -1 ? 1 : -1;
    setContractOrderBy(nextSort);
    setContractOrder(dataIndex);
    getClientContracts(id, contractPlanType, contractPageNo, contractKeyword, nextSort, dataIndex);
  };

  const getClientById = async (cid: string) => {
    setIsLoading(true);
    try {
      await clientStore.getClientById(cid);
    } catch (error: any) {
      Toast.error(error);
      history.goBack();
    }
    setIsLoading(false);
  };
  const getClientVehicle = async (
    shared_vehicle: boolean, clientId: string,
    page: number, keyword?: string, orderBy?: number, order?: string,
  ) => {
    setIsLoading(true);
    await clientStore.getClientVehicle({
      clientId, page, limit: Constant.clientDetailsPage, keyword, order, orderBy, shared_vehicle,
    })
      .catch((error:any) => {
        Toast.error(error);
      });
    setIsLoading(false);
  };

  const getClientTransactions = async (
    clientId: string, page: number, orderBy?: number, order?: string,
  ) => {
    setIsLoading(true);
    await clientStore.getClientTransactions({
      client: clientId, page, limit: Constant.clientDetailsPage, order, orderBy, status: 'PAYMENT_SUCCESS',
    })
      .catch((error:any) => {
        Toast.error(error);
      });
    setIsLoading(false);
  };

  const getClientContracts = async (
    clientId: string, contract_status: string, page: number,
    keyword?: string, orderBy?: number, order?: string,
  ) => {
    setIsLoading(true);
    await clientStore.getClientContracts({
      client: clientId,
      contract_status,
      page,
      limit: Constant.clientDetailsPage,
      keyword,
      order,
      orderBy,
    })
      .catch((error:any) => {
        Toast.error(error);
      });
    setIsLoading(false);
  };

  const empDeleteRequest = async () => {
    await requestService.createEmployeeRequest({
      target_id: id, request: Constant.employeeRequest.CLIENT_DELETE,
    })
      .then(() => history.push(`${Routes.clients}`))
      .catch((error: any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const deleteClient = async () => {
    await clientService.deleteClient(id)
      .then(() => history.push(`${Routes.clients}`))
      .catch((error: any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const goToClientEdit = () => history.push(`${Routes.create_client}/${id}`);

  const viewDeleteConfirmModal = () => {
    if (isSuperAdmin) {
      ConfirmModal('Delete Account', 'Do you want to delete this client?', deleteClient);
    } else {
      ConfirmModal('Request for Account Deletion', 'Do you want to delete this client?', empDeleteRequest, 'Request');
    }
  };

  const disableDeleteBtn = !!((!isSuperAdmin
    && clientStore?.client?.delete_requested));

  const iscolor = disableDeleteBtn ? '#7777' : '';

  const searchVehicle = useCallback(
    debounce((nextValue: any, orderBy: number, order: string, shared_Vehicle: boolean) => {
      setVehiclePageNo(Constant.defaultPageNumber);
      getClientVehicle(
        shared_Vehicle, id, Constant.defaultPageNumber, nextValue, orderBy, order,
      );
    }, Constant.debaounceTime),
    [],
  );

  const onVehicleSearch = (event: any) => {
    const { value: nextValue } = event.target;
    setVehicleKeyword(nextValue);
    searchVehicle(nextValue, vehicleOrderBy, vehicleOrder, sharedVehicle);
  };

  const downloadInvoice = async (orderId: string) => {
    try {
      setIsLoading(true);
      const response = await contractService?.downloadInvoice(orderId);
      const url = await Utility.createObjectUrlFromUrl(response?.data?.url);
      Utility.downloadFile(url, `Invoice-${orderId}.pdf`);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error || Constant.defaultErrorMessage);
    }
  };

  const vehicleTableData = vehicles?.data?.map((vehicle: Vehicle) => ({
    company: <span className="text-capitalize text-truncate d-block">{vehicle?.model[0]?.company}</span>,
    model: <span className="text-capitalize text-truncate d-block">{vehicle?.model[0]?.model}</span>,
    year: vehicle?.model[0]?.manufacture_year,
    color: <span className="veh_color d-inline-block me-1 rounded-circle align-middle sm" style={{ backgroundColor: vehicle?.model[0]?.color }} />,
    number: vehicle?.vehicle_no || '-',
    id: vehicle?._id,
    client: `${vehicle?.clientDetail?.first_name} ${vehicle?.clientDetail?.middle_name || ''} ${vehicle?.clientDetail?.last_name}`,
    access: vehicle?.role,
    clientId: id,
    contract:
  <div className="d-flex align-items-center">
    {vehicle?.contract?.contract_code
      ? (
        <>
          <span className="text-truncate" title={`${vehicle?.contract?.contract_code}`}>
            {`${vehicle?.contract?.contract_code}`}
          </span>
          <Tooltip
            title={
              `${vehicle?.contractPlan?.plan} Plan`
            }
          >
            <div className="lh_0">
              <Icon className="icon-16 ms-2" iconRef="#info" />
            </div>
          </Tooltip>
        </>
      ) : '-'}
  </div>,
  }));

  const getPaymentText = (transaction: any) => {
    let transactionText;
    if (transaction?.payment_type === Constant.activities.SERVICE_REQUEST) {
      transactionText = Constant?.SERVICES?.get(transaction?.serviceRequest?.service);
      return `${transactionText} - ${transaction?.vehicle_model?.company} ${transaction?.vehicle_model?.model}`;
    }
    transactionText = Constant.paymentType?.get(transaction?.payment_type);
    return `${transactionText} - ${transaction?.contractPlan?.plan} plan`;
  };

  const transactionTableData = clientTransactions?.data?.map((transaction: any) => ({
    payment_type: <span className="text-capitalize text-truncate">{getPaymentText(transaction)}</span>,
    date: Utility.getFormattedDate(transaction?.payment_done_date),
    time: Utility.getFormattedTime(transaction?.payment_done_date),
    amount: <span className="text-capitalize">{transaction?.total_amount} KD</span>,
    invoice: (
      <Tooltip title="download invoice">
        <Button onClick={() => downloadInvoice(transaction?.order_id)} className="rounded-circle ms-2 p-2 d-flex justify-content-center align-items-center text-center">
          <Icon className="icon-14" iconRef="#download" />
        </Button>
      </Tooltip>),
  }));

  const intializeState = () => {
    setContractPageNo(Constant.defaultPageNumber);
    setContractKeyword('');
    setContractOrderBy(-1);
    setContractOrder('contract_code');
    setContractPlanType(ACTIVE);
    setVehicleKeyword('');
    setVehicleOrder('model.company');
    setVehicleOrderBy(-1);
    setVehiclePageNo(Constant.defaultPageNumber);
    setTransactionOrder('');
    setTransactionOrderBy(-1);
    setTransactionPageNo(Constant.defaultPageNumber);
    setSharedVehicle(false);
  };

  useEffect(() => {
    intializeState();
    getClientById(id);
    getClientVehicle(sharedVehicle, id, Constant.defaultPageNumber, '', -1, 'model.company');
    getClientTransactions(id, Constant.defaultPageNumber, -1, '');
    getClientContracts(
      id, ACTIVE, Constant.defaultPageNumber, '', -1, 'contract_code',
    );
  }, [id]);

  const contractSearch = useCallback(
    debounce((nextValue: any, contractPlan: string, orderBy: number, order: string) => {
      setContractPageNo(Constant.defaultPageNumber);
      getClientContracts(
        id, contractPlan, Constant.defaultPageNumber, nextValue, orderBy, order,
      );
    }, Constant.debaounceTime),
    [],
  );

  const onContractSearch = (event: any) => {
    const { value: nextValue } = event.target;
    setContractKeyword(nextValue);
    contractSearch(nextValue, contractPlanType, contractOrderBy, contractOrder);
  };

  const contractTabHandler = (event: any) => {
    setContractPageNo(Constant.defaultPageNumber);
    setContractKeyword('');
    setContractOrderBy(-1);
    setContractPlanType(event?.target?.value);
    getClientContracts(
      id, event?.target?.value, Constant.defaultPageNumber, '', -1, 'contract_code',
    );
  };

  const contractTableData = contracts?.data?.map((contract: Contract) => ({
    id: contract?._id,
    contract_code: contract?.contract_code,
    planType: contract?.contract[0]?.plan,
    planTerm: Utility.getMonthFromDays(contract?.duration),
    due_date: Utility.getFormattedDate(contract?.due_date),
    vehicle:
  <div className="d-flex align-items-center">
    {contract?.model?.company
      ? (
        <>
          <span className="text-truncate" title={`${contract?.model?.company} ${contract?.model?.model}`}>
            {`${contract?.model?.company} ${contract?.model?.model}`}
          </span>
          <Tooltip
            title={
              contract?.active_vehicle[0]?.vehicle_no || contract?.active_vehicle[0]?.vehicle_code
            }
          >
            <div className="lh_0">
              <Icon className="icon-16 ms-2" iconRef="#info" />
            </div>
          </Tooltip>
        </>
      ) : '-'}
  </div>,
    status: <Status
      status={contract?.contract_status}
      statusClassName={Constant.contractStatusColor}
      statusText={contractStatus}
    />,

  }));

  const gotoSharedAccess = () => history.push(`${Routes.shared_access}/${id}`);
  const gotoSharedMember = () => history.push(`${Routes.shared_member}/${id}`);
  const gotoVehicleDetailPage = (record:any) => { history.push(`${Routes.vehicleDetails}/${record?.id}${sharedVehicle ? `?shared=${record?.clientId}` : ''}`); };
  const gotoPlanDetailPage = (record:any) => history.push(`${Routes.contract_detail}/${record?.id}`);

  const contractPageHandler = (page: number) => {
    setContractPageNo(page);
    getClientContracts(
      id, contractPlanType, page, contractKeyword, contractOrderBy, contractOrder,
    );
  };

  const vehiclePageHandler = (page: number) => {
    setVehiclePageNo(page);
    getClientVehicle(
      sharedVehicle, id, page, vehicleKeyword, vehicleOrderBy, vehicleOrder,
    );
  };

  const transactionPageHandler = (page: number) => {
    setTransactionPageNo(page);
    getClientTransactions(
      id, page, transactionOrderBy, transactionOrder,
    );
  };

  const gotoVehicleCreate = () => history.push(`${Routes.client}/${id}${Routes.create_vehicle}`);

  const activeBtnHandler = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    try {
      if (!masterStore?.vaultContractPlans) {
        masterStore.getContractPlans();
      }
      masterStore.getFacilities();
    } catch (error: any) {
      Toast.error(error);
    }
  }, []);

  const createContract = async (values: any) => {
    try {
      setIsLoading(true);
      const {
        facility, start_date, plan_duration, contract_plan, plan_paid, parked_date, vehicle,
      } = values;

      if (parked_date) {
        const dueDate = moment(start_date).add(JSON.parse(plan_duration), 'days');
        if (parked_date > dueDate || parked_date < start_date) {
          setIsLoading(false);
          return Toast.error(Constant.parkedDateError);
        }
      }

      const contractForm: any = {
        plan_paid,
        plan_duration,
        contract_plan,
        facility,
        vehicle,
        start_date: new Date(start_date).getTime(),
      };

      if (parked_date) {
        contractForm.parked_date = new Date(parked_date).getTime();
      }

      await clientService.createContract(id, contractForm);
      setShowPlanModel(false);
      setIsPlanPaid(false);
      setIsVehicleInside(false);
      setSelectedPlan('');
      getClientContracts(
        id, contractPlanType, contractPageNo, contractKeyword, contractOrderBy, contractOrder,
      );
      getClientVehicle(sharedVehicle, id, vehiclePageNo, vehicleKeyword,
        vehicleOrderBy, vehicleOrder);
      if (plan_paid) getClientTransactions(id, Constant.defaultPageNumber, -1, 'payment_done_date');
      setIsLoading(false);
      return null;
    } catch (error: any) {
      setIsLoading(false);
      return Toast.error(error);
    }
  };

  const addPlan = () => setShowPlanModel(true);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Tooltip title={disableDeleteBtn ? 'Request for deleting the client is already made' : ''}>
          <Button className="d-flex ml-auto r-bold align-items-center delete-btn p-0 h-auto resp_req_btn" style={{ color: iscolor }} disabled={disableDeleteBtn} onClick={viewDeleteConfirmModal}>
            { isSuperAdmin ? 'Delete Account'
              : 'Request for delete' }
            {!disableDeleteBtn && (
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
            ) }
          </Button>
        </Tooltip>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2">
        <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={gotoVehicleCreate}>
          Add vehicle
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      {isSuperAdmin && !client?.active && (
      <Menu.Item key="3">
        <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={activeBtnHandler}>
          { client?.email ? 'Reinvite' : 'Activate account'}
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      )}
      {isSuperAdmin && (
      <Menu.Item key="4">
        <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={addPlan}>
          Add plan
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
      )}
    </Menu>
  );

  const vehicleRadiobtnHandler = (event: any) => {
    setVehiclePageNo(Constant.defaultPageNumber);
    setSharedVehicle(event?.target?.value);
    getClientVehicle(
      event?.target?.value, id,
      Constant.defaultPageNumber,
      vehicleKeyword,
      vehicleOrderBy,
      vehicleOrder,
    );
  };

  const sendInvitation = async (values: any) => {
    setIsLoading(true);
    await clientService.activateAccount(id, values)
      .then(() => {
        getClientById(id);
        setIsModalVisible(false);
      })
      .catch((error: any) => Toast.error(error));
    setIsLoading(false);
  };

  const onCancel = () => setIsModalVisible(false);

  const closePlanModel = () => setShowPlanModel(false);

  return (
    <Layout.Content className="detail_wrapper clnt_detail">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classnames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={Routes.clients}>Clients</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{client?.client_code}</Breadcrumb.Item>
              </Breadcrumb>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className="mb-3">
              <div className="d-sm-flex align-items-center justify-content-between w-100 mb-3">
                <div className="d-flex align-items-center">
                  <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                    {client?.client_code}
                  </Typography.Title>
                  {!client?.is_invitation_send && client?.is_invited && <div className="pending_signup rounded-pill d-flex align-items-center justify-content-center fs-16 ms-2 ms-sm-3 label_inactive ps-2 pe-2 fs-xs-14">Not inivited</div>}
                  {!client?.active && client?.is_invitation_send && <div className="pending_signup rounded-pill d-flex align-items-center justify-content-center fs-16 ms-2 ms-sm-3 label_warning ps-2 pe-2 fs-xs-14">Pending sign up</div>}
                </div>
                { isSuperAdmin && (
                <Button className="main_btn fs-16 r-bold mt-2 mt-sm-0" onClick={goToClientEdit}>
                  Edit Information
                </Button>
                ) }
              </div>
              <Row gutter={16}>
                <Col lg={8} md={8} xs={24}>
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <UserCard
                      profileUrl={client?.profile?.fileUrl}
                      name={(client?.first_name && client?.last_name) ? `${client?.first_name} ${client?.middle_name || ''} ${client?.last_name}` : ''}
                      cardName="Client"
                      id={client?.client_code}
                      contractCount={activeContracts}
                      sharedAccessCount={accessCount}
                      membersCount={memberCount}
                      handleAccessClick={gotoSharedAccess}
                      handleMemberClick={gotoSharedMember}
                    />
                  </Card>
                  {/* phone update */}
                  {(client?.phone || client?.email)
                  && (
                  <Card bordered={false} className="mb-3 detail_card_pad">
                    <ContactCard contactDetails={client} />
                  </Card>
                  )}
                  <Card bordered={false} className="detail_card_pad">
                    <Address addressInfo={client?.address} />
                  </Card>

                </Col>
                <Col lg={16} md={16} xs={24} className="mt-3 mt-md-0">
                  <Element name="Client Plans">
                    <Card className="table_card_title">
                      <div className="d-xxl-flex d-xl-block d-sm-flex align-items-center flex-fill pt-3 ps-xl-3 pe-xl-3 mb-3 mb-xl-0 justify-content-between">
                        <div className="d-sm-flex align-items-center srch_p_resp">
                          <h4 className="mb-0 table-header r-bold text-truncate" title="Client Plans">Client Plans</h4>
                          <Search
                            value={contractKeyword}
                            placeholder="Search"
                            titleToolTip="Search Client Plans"
                            size="small"
                            onChange={onContractSearch}
                          />
                        </div>
                        <Radio.Group
                          onChange={contractTabHandler}
                          value={contractPlanType}
                          buttonStyle="solid"
                          className="radio_tab client_plan_tab d-flex gray_bg w-100 border-radius ms-xxl-4 ms-xl-0 ms-sm-4 mt-xxl-0 mt-xl-3 mt-sm-0 mt-3"
                        >
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={ACTIVE}>ACTIVE</Radio.Button>
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={PAYMENT_PENDING}>PENDING</Radio.Button>
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14 fs-md-14 ps-1 ps-lg-3 pe-1 pe-lg-3" value={EXPIRED}>EXPIRED</Radio.Button>
                        </Radio.Group>
                      </div>
                      <Table
                        columns={columndata}
                        dataSource={contractTableData}
                        pagination={false}
                        size="small"
                        rowClassName="cursor-pointer"
                        className={classnames('table_wrapper ins_table_wrapper', { client_detail_listing: contractPlanType !== PAYMENT_PENDING }, { client_detail_st_listing: contractPlanType === PAYMENT_PENDING })}
                        locale={{
                          emptyText: (
                            <NoData subTitle="plans" size={105} />),
                        }}
                        onRow={(record) => ({ onClick: () => gotoPlanDetailPage(record) })}
                      />
                    </Card>
                    <Pagination
                      total={contracts?.total}
                      pageSize={Constant.clientDetailsPage}
                      currentPage={contractPageNo}
                      onChange={contractPageHandler}
                    />
                  </Element>
                  <Element name="Vehicle">
                    <Card className="table_card_title mt-3">
                      <div className="d-xxl-flex d-xl-block d-sm-flex align-items-center flex-fill pt-3 ps-xl-3 pe-xl-3 mb-3 mb-xl-0 justify-content-between">
                        <div className="d-sm-flex align-items-center srch_p_resp">
                          <h4 className="mb-0 table-header r-bold">Vehicles</h4>
                          <Search
                            value={vehicleKeyword}
                            placeholder="Search"
                            titleToolTip="Search Vehicle"
                            size="small"
                            onChange={onVehicleSearch}
                          />
                        </div>
                        <Radio.Group
                          onChange={vehicleRadiobtnHandler}
                          defaultValue={sharedVehicle}
                          buttonStyle="solid"
                          className="radio_tab gray_bg w-100 border-radius mt-3 mt-sm-0 mt-xl-3 mt-xxl-0"
                        >
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16" value={false}>Personal</Radio.Button>
                          <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16" value>Shared</Radio.Button>
                        </Radio.Group>
                      </div>
                      <Table
                        columns={vehicleColumn}
                        dataSource={vehicleTableData}
                        pagination={false}
                        size="small"
                        rowClassName="cursor-pointer"
                        className={classnames('table_wrapper ins_table_wrapper', { client_veh_p_listing: sharedVehicle === false }, { client_veh_sh_listing: sharedVehicle === true })}
                        locale={{
                          emptyText: (
                            (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="vehicles" size={105} />),
                        }}
                        onRow={(record) => ({ onClick: () => gotoVehicleDetailPage(record) })}
                      />
                    </Card>
                    <Pagination
                      total={vehicles?.total}
                      pageSize={Constant.clientDetailsPage}
                      currentPage={vehiclePageNo}
                      onChange={vehiclePageHandler}
                    />
                  </Element>
                  <Element name="Vehicle">
                    <Card className="table_card_title mt-3">
                      <div className="d-sm-flex align-items-center flex-fill pt-3 ps-xl-3 pe-xl-3 mb-3 mb-xl-0">
                        <h4 className="mb-0 table-header r-bold">Transactions</h4>
                      </div>
                      <Table
                        columns={TransactionColumn}
                        dataSource={transactionTableData}
                        pagination={false}
                        size="small"
                        className="transaction_listing table_wrapper ins_table_wrapper"
                        scroll={{ x: '500px' }}
                        locale={{
                          emptyText: (
                            (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="transactions" size={105} />),
                        }}
                      />
                    </Card>
                    <Pagination
                      total={clientTransactions?.total[0]?.counts}
                      pageSize={Constant.clientDetailsPage}
                      currentPage={transactionPageNo}
                      onChange={transactionPageHandler}
                    />
                  </Element>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the client</Paragraph>
            </Typography>
            <div className="d-none d-xl-block">
              <h6 className="text-uppercase r-medium r-side-info mt-5">Sections</h6>
              <div className="v_tab d-flex flex-column">
                <ScrollLinks
                  to="Client Plans"
                  activeClass="tab_active"
                  offset={-110}
                  className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
                  spy
                >
                  Plans
                </ScrollLinks>
                <ScrollLinks
                  to="Vehicles"
                  activeClass="tab_active"
                  offset={-110}
                  className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
                  spy
                >
                  Vehicles
                </ScrollLinks>
                <ScrollLinks
                  to="Vehicles"
                  activeClass="tab_active"
                  offset={-110}
                  className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
                  spy
                >
                  Transactions
                </ScrollLinks>
              </div>
            </div>
            <div className="disabled_btn"><h6 className="text-uppercase r-medium r-side-info mt-4">Action</h6>
              <Tooltip title={disableDeleteBtn ? 'Request for deleting the client is already made' : ''}>
                <Button className="d-flex ml-auto mb-3 r-bold align-items-center delete-btn p-0 h-auto" style={{ color: iscolor }} disabled={disableDeleteBtn} onClick={viewDeleteConfirmModal}>
                  { isSuperAdmin ? 'Delete Account'
                    : 'Request for delete' }
                  {!disableDeleteBtn && (
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                  ) }
                </Button>
              </Tooltip>
              <Tooltip title="Add a new vehicle">
                <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={gotoVehicleCreate}>
                  Add vehicle
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              {isSuperAdmin && (
              <Tooltip title="Add a plan">
                <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={addPlan}>
                  Add plan
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              )}
              {isSuperAdmin && !client?.active && (
              <Tooltip title={client?.is_invitation_send ? 'Re-invite' : 'Activate account'}>
                <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={activeBtnHandler}>
                  { client?.is_invitation_send ? 'Reinvite' : 'Activate account'}
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
              )}
            </div>
          </Sider>
        </Row>
        <ActivateAccount
          isModalVisible={isModalVisible}
          is_invitation_send={client?.is_invitation_send}
          onOk={sendInvitation}
          onCancel={onCancel}
          email={client?.email}
          isLoading={isLoading}
        />
        <AddPlan
          isModalVisible={showPlanModel}
          onOk={createContract}
          onCancel={closePlanModel}
          isLoading={isLoading}
          planTypes={masterStore?.vaultContractPlans}
          facilities={facilities}
          isPlanPaid={isPlanPaid}
          setIsPlanPaid={setIsPlanPaid}
          isVehicleInside={isVehicleInside}
          setIsVehicleInside={setIsVehicleInside}
          vehicles={vehicles?.data}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('clientStore', 'authStore', 'masterStore')(observer(ClientDetails));
