/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Col, Layout, Row, Spin, Typography, Tabs,
} from 'antd';
import Link from 'antd/lib/typography/Link';
import queryString from 'query-string';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import moment from 'moment';
import classNames from 'classnames';

import Routes from '../../Global/Routes';
import { Icon } from '../../Components';
import CreateAccount from './AccountTab';
import CreateContract from './ContractTab';
import { ClientForm } from '../../Global/interface';
import Toast from '../../Components/Toast';

import './CreateClient.less';
import Utility from '../../Global/Utility';

const { TabPane } = Tabs;
const { Sider } = Layout;

function CreateClients(props: any) {
  const { clientStore } = props;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams<any>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const isCreate = queryParams?.create;
  const [collapsed, setCollapsed] = useState(false);

  const createClient = async (client:ClientForm) => {
    try {
      const params: ClientForm = client;
      setIsLoading(true);
      params.dob = client?.dob ? moment(client?.dob)?.format('YYYY-MM-DD') : null;
      params.joined_date = client?.joined_date ? moment(client?.joined_date)?.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      params.phone = Utility.phoneParser(params?.phone); // phone update
      await clientStore.createClient(params);
      setIsUpdate(true);
      setIsLoading(false);
      setActiveTabKey('2');
      history.push(`create_client/${clientStore?.client?._id}?create=true`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const updateClient = async (client: ClientForm) => {
    try {
      const params: ClientForm = client;
      setIsLoading(true);
      params.dob = client?.dob ? moment(client?.dob)?.format('YYYY-MM-DD') : null;
      params.joined_date = client?.joined_date ? moment(client?.joined_date)?.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      params.phone = Utility.phoneParser(params?.phone); // phone update
      await clientStore.updateClient(id, params);
      setIsLoading(false);
      history.push(`${Routes.client_detail}/${id}`);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };

  const onFinishBasicInfo = (values: ClientForm) => {
    if (isUpdate) updateClient(values);
    else createClient(values);
  };

  const goToClients = () => {
    if (isUpdate) history.push(`${Routes.client_detail}/${id}`);
    else history.push(Routes.clients);
  };

  const fetchClientData = async (userId: string) => {
    setIsLoading(true);
    await clientStore.getClientById(userId)
      .catch((error: any) => {
        Toast.error(error?.response?.data?.error?.message);
      });
    setIsLoading(false);
  };

  const handleTabChange = (key:any) => setActiveTabKey(key);

  useEffect(() => {
    clientStore.client = [];
    if (id) {
      fetchClientData(id);
      setIsUpdate(true);
    }
  }, [id]);

  const infoText = isUpdate ? 'See and manage the client' : 'Add existing client';

  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-xl-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Link className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content" onClick={goToClients}>
              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Link>
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24">
              {!isCreate ? 'Edit Client' : 'Add existing client'}
            </Typography.Title>
            { isCreate && (
            <Tabs className="exis_tab" defaultActiveKey="1" activeKey={activeTabKey} onTabClick={handleTabChange}>
              <TabPane tab={<Typography.Title level={4} className="mb-0 primary r-blod">Account <Icon className="icon-16 ms-2" iconRef="#arrow_right" /></Typography.Title>} key="1">
                <CreateAccount
                  isUpdate={isUpdate}
                  onFinish={onFinishBasicInfo}
                  onCancel={goToClients}
                />
              </TabPane>
              <TabPane tab={<Typography.Title level={4} className="mb-0 r-regular">Plans</Typography.Title>} key="2" disabled={!isUpdate}>
                <CreateContract isUpdate={isUpdate} />
              </TabPane>
            </Tabs>
            )}
            {!isCreate && (
            <CreateAccount
              isUpdate={isUpdate}
              onFinish={onFinishBasicInfo}
              onCancel={goToClients}
            />
            )}
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>{activeTabKey === '2' ? 'Add existing plans' : infoText }</Paragraph>
            </Typography>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('clientStore')(observer(CreateClients));
