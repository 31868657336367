/* eslint-disable camelcase */
import { Result } from 'antd';
import React from 'react';
import nodataimg from '../../Assets/Images/img-empty.png';

interface NoDataProps{
  subTitle?: String,
  size?: number,
  image?: any,
  displayText?: string
}

NoData.defaultProps = {
  size: 200,
  image: nodataimg,
  subTitle: '',
  displayText: '',
};

function NoData(props: NoDataProps) {
  const {
    subTitle, size, image, displayText,
  } = props;
  return (
    <Result
      className="text-capitalize"
      icon={<img src={image || nodataimg} alt="no_data_img" className="w-100 m-auto" style={{ maxWidth: size }} />}
      subTitle={displayText || `No ${subTitle} found`}
    />
  );
}

export default NoData;
