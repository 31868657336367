/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col,
  Layout,
  Row,
  Spin,
  Table,

  Typography,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import './planHistory.less';
import classNames from 'classnames';
import Toast from '../../Components/Toast';
import {
  Icon,
  NoData, Pagination, Search,
} from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import SortTh from '../../Components/SortTh';
import Routes from '../../Global/Routes';
import { IContractStore } from '../../Store/contractStore';

const { Title, Paragraph } = Typography;
const { CLOSED } = Constant.customerContract;
const { Sider } = Layout;

interface IPlanHistory {
  contractStore: IContractStore
}

function PlanHistory(props: IPlanHistory) {
  const { contractStore } = props;
  const { planHistory } = contractStore;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState('contract_code');
  const [orderBy, setorderBy] = useState(-1);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [keyword, setKeyword] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  const fetchContract = async (sort: string,
    sortBy: number, page: number, planKeyword: string) => {
    setIsLoading(true);
    try {
      contractStore.getPlanHistory(sort, sortBy, page, planKeyword, CLOSED);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const Columns = [
    {
      title: <SortTh title="Plan ID" dataIndex="contract_code" orderBy={orderBy} order={order} />,
      dataIndex: 'contract_code',
      onHeaderCell: () => ({
        onClick: () => {
          sortTable('contract_code');
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Plan Type',
      dataIndex: 'plan_type',
      key: 'plan_type',
      ellipsis: true,
    },
    {
      title: 'Plan Term',
      dataIndex: 'plan_term',
      key: 'plan_term',
      ellipsis: true,
    },
    {
      title: 'Client',
      dataIndex: 'name',
      key: 'client',
      ellipsis: true,
    },
    {
      title: 'Closed Date',
      dataIndex: 'closed_date',
      key: 'closed_date',
      ellipsis: true,
    },
  ];

  const sortTable = (dataIndex: string) => {
    const nextSort = orderBy === 1 ? -1 : 1;
    setorderBy(nextSort);
    setOrder(dataIndex);
    fetchContract(dataIndex, nextSort, pageNumber, keyword);
  };

  const DataSource = planHistory?.data?.map((contract: any) => ({
    contract_code: contract?.contract_code,
    plan_type: contract?.contract[0]?.plan,
    name: contract?.client[0]?.first_name,
    plan_term: Utility.getMonthFromDays(contract?.duration),
    closed_date: Utility.getFormattedDate(contract?.updatedAt),
    rowKey: contract?._id,
  }));

  const pageHandler = (page: number) => {
    setPageNumber(page);
    fetchContract(order, orderBy, page, keyword);
  };

  const planDebouncedSave = useCallback(
    debounce((nextValue: string, sort: string, sortBy: number) => {
      setPageNumber(Constant.defaultPageNumber);
      fetchContract(
        sort,
        sortBy,
        Constant.defaultPageNumber, nextValue,
      );
    }, Constant.debaounceTime),
    [],
  );

  const handleSearchTextChange = (event: any) => {
    const { value: nextValue } = event.target;
    setKeyword(nextValue);
    planDebouncedSave(nextValue, order, orderBy);
  };

  const goToContractDetails = (record: any) => { history.push(`${Routes.contract_detail}/${record?.rowKey}`); };

  useEffect(() => {
    fetchContract(order, orderBy, pageNumber, keyword);
  }, []);

  const locale = {
    emptyText: (
      (isLoading) ? <Spin spinning /> : <NoData subTitle="Plans" />
    ),
  };

  return (
    <Layout.Content className="listing_wrapper">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <Link className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content primary" to={Routes.contracts}>
            <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_left" />
            </div>
            Back
          </Link>
          <div className="d-flex justify-content-between align-items-center mb-2 pb-3 border-bottom">
            <Typography.Title className="m-0 header_main border-0 d-flex align-items-center fs-xs-24">
              Plans History
            </Typography.Title>
          </div>
          <div className="contract_listing">
            <div className="" id="dd">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-sm-flex align-items-center flex-fill">
                  <h4 className="mb-0 table-header text-capitalize r-bold">Closed Plans</h4>
                  <Search
                    onChange={handleSearchTextChange}
                    value={keyword}
                    titleToolTip="Search Closed"
                    placeholder="Search"
                    size="small"
                  />
                </div>
              </div>
              <Table
                rowClassName="cursor-pointer"
                columns={Columns}
                dataSource={DataSource}
                pagination={false}
                size="small"
                scroll={{ x: '800px' }}
                onRow={(record) => ({ onClick: () => goToContractDetails(record) })}
                className="table_wrapper plan_history_listing"
                locale={locale}
              />
              <Pagination
                total={planHistory?.total}
                currentPage={pageNumber}
                pageSize={Constant.contractPerPage}
                onChange={pageHandler}
              />
            </div>
          </div>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See the plan history</Paragraph>
          </Typography>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('contractStore')(observer(PlanHistory));
