/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import queryString from 'query-string';
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Spin,
  Typography,
} from 'antd';
import './ServiceRequestDetail.less';
import classNames from 'classnames';
import {
  Icon, QRCodeModal, Toast, VehicleInfoCard, VehicleStatusCard,
} from '../../Components';
import { IServiceStore } from '../../Store/serviceRequestStore';
import { VehicleStoreProps } from '../../Store/vehicleStore';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

interface ServiceReqDetailProps {
  serviceStore: IServiceStore
  vehicleStore: VehicleStoreProps
}

const { OPEN } = Constant.serviceRequestStatus;

function ServiceRequestDetail(props: ServiceReqDetailProps) {
  const { vehicleStore, serviceStore } = props;
  const { serviceRequest } = serviceStore;
  const { vehicle } = vehicleStore;
  const { id } = useParams<any>();
  const location = useLocation();
  const history = useHistory();
  const queryParams: any = queryString.parse(location?.search);
  const vehicleId = queryParams?.vId;
  const showStatus = queryParams?.status;
  const defaultReqTab = queryParams?.tab || OPEN;
  const [isLoading, setIsLoading] = useState(false);
  const [isQRCodeModal, setIsQRCodeModal] = useState<boolean>(false);
  const facility = serviceRequest?.contract?.facility;
  const email = vehicle?.client?.email;
  const phone = vehicle?.client?.phone;
  const [collapsed, setCollapsed] = useState(false);

  const vehicleAddress = vehicle?.contractPlan?.plan_type === Constant.plan_type?.VAULT
    ? null : vehicle?.address;

  const getVehicleById = async () => {
    setIsLoading(true);
    try {
      await vehicleStore.getVehicleById(vehicleId);
    } catch (error: any) {
      Toast.error(error);
      history.goBack();
    }
    setIsLoading(false);
  };

  const getServiceReqById = async () => {
    setIsLoading(true);
    try {
      await serviceStore.getServiceReqById(id);
    } catch (error:any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getVehicleById();
    getServiceReqById();
  }, []);

  const showQRCodeModal = () => {
    setIsQRCodeModal(true);
  };

  const closeQRCodeModal = () => {
    setIsQRCodeModal(false);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={`${Routes.client_detail}/${vehicle?.client?._id}`} className="d-flex ml-auto r-bold align-items-center p-0 link_text">
          Client Details
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
      {serviceRequest?.contract?._id && (
      <div>
        <Menu.Divider />
        <Menu.Item key="2">
          <>
            <Link to={`${Routes.contract_detail}/${serviceRequest?.contract?._id}`} className="d-flex ml-auto r-bold align-items-center p-0 link_text">
              Plan Information
              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_right" />
              </div>
            </Link>
          </>
        </Menu.Item>
      </div>
      )}
      <Menu.Divider />
      <Menu.Item key="4">
        <>
          <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={showQRCodeModal}>
            Download QR
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="detail_wrapper fac_detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
              <Breadcrumb className="text-uppercase m-0 breadcrumb border-bottom-0">
                <Breadcrumb.Item>
                  <Link to={`${Routes.request}?tab=${defaultReqTab}`}>Service Requests</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{id}</Breadcrumb.Item>
              </Breadcrumb>
              { vehicle
                && (
                <div className="action_resp d-xl-none">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
                )}
            </div>
            <div className="mb-3">
              {vehicle?.vehicle_no && (
              <div className="d-flex align-items-center w-100 mb-3">
                <div className="number_icn primary_bg white r-bold d-flex alig-items-center justify-content-center fs-16 me-2">
                  N
                </div>
                <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                  {vehicle?.vehicle_no}
                </Typography.Title>
              </div>
              ) }
              <Row gutter={16}>
                <Col lg={16} md={24} sm={24} xs={24}>
                  <VehicleStatusCard
                    vehicle={vehicle}
                    vehicleId={vehicleId}
                    facility={facility}
                    requestCode={id}
                    showStatus={showStatus}
                    clientEmail={email}
                    clientPhone={phone}
                    contract={serviceRequest?.contract}
                  />
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                  <VehicleInfoCard vehicle={vehicle} location={vehicleAddress} />
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper hidden d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the client requests</Paragraph>
            </Typography>
            { vehicle
            && (
            <div><h6 className="text-uppercase r-medium r-side-info mt-5">Action</h6>
              <Link to={`${Routes.client_detail}/${vehicle?.client?._id}`} className="d-flex ml-auto mb-3 r-bold align-items-center p-0 link_text">
                Client Details
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
              {serviceRequest?.contract?._id && (
              <>
                <Link to={`${Routes.contract_detail}/${serviceRequest?.contract?._id}`} className="d-flex ml-auto mb-3 r-bold align-items-center p-0 link_text">
                  Plan Information
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Link>
              </>
              ) }
              <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary h-auto" onClick={showQRCodeModal}>
                Download QR
                <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Button>
            </div>
            ) }
          </Sider>
        </Row>
        <QRCodeModal
          vehicleNo={vehicle?.vehicle_no || vehicle?.vehicle_code}
          id={vehicleId}
          isQRCodeModal={isQRCodeModal}
          closeQRCodeModal={closeQRCodeModal}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('serviceStore', 'vehicleStore')(observer(ServiceRequestDetail));
