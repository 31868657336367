/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Typography,
} from 'antd';
import { Vehicle } from '../../Global/interface';

export interface ModelInfoProps {
    vehicle: Vehicle
}

function ModelInfo(props: ModelInfoProps) {
  const { vehicle } = props;
  return (
    <div>
      <Typography.Text className="secondary r-medium text-uppercase">Manufacturer</Typography.Text>
      <Typography.Title className="mt-0 primary text-capitalize" level={3}>{vehicle?.model?.company}</Typography.Title>
      <Typography.Text className="secondary r-medium text-uppercase">model</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.model?.model}</Typography.Paragraph>
      <Typography.Text className="secondary r-medium text-uppercase">Year</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.model?.manufacture_year}</Typography.Paragraph>
      <Typography.Text className="secondary r-medium text-uppercase">Color</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">
        <span className="veh_color d-inline-block me-1 rounded-circle align-middle sm" style={{ backgroundColor: vehicle?.model?.color }} />
      </Typography.Paragraph>
      <Typography.Text className="secondary r-medium text-uppercase">Category</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.model?.category}</Typography.Paragraph>
      <Typography.Text className="secondary r-medium text-uppercase">Number</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.vehicle_no ? vehicle?.vehicle_no : '-'}</Typography.Paragraph>
      <Typography.Text className="secondary r-medium text-uppercase">Chassis Number</Typography.Text>
      <Typography.Paragraph className="primary fs-16 r-bold">{vehicle?.chassis_no ? vehicle?.chassis_no : '-'}</Typography.Paragraph>
    </div>
  );
}

export default ModelInfo;
