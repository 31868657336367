/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout, Skeleton } from 'antd';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import PrivateRoute from './PrivateRoute';
import AccessRoute from './AccessRoute';
import Routes from '../Global/Routes';
import {
  Orders, Profile, SetPassword, Settings, Customers, Products,
  Analytics, CustomOrders, ReturnOrders, AbandonedCart,
} from '../Pages';
import { Header, Sidebar } from '../Components';
import authStore from '../Store/authStore';
import Constant from '../Global/Constant';
import PublicRoute from './PublicRoute';

const ClientListing = lazy(() => import('../Pages/ClientListing'));
const Contracts = lazy(() => import('../Pages/ContractsListing'));
const CreateEmployee = lazy(() => import('../Pages/CreateEmployee'));
const Dashboard = lazy(() => import('../Pages/Dashboard'));
const EmployeeDetails = lazy(() => import('../Pages/EmployeeDetails'));
const EmployeesListing = lazy(() => import('../Pages/EmployeesListing'));
const ForgotPassword = lazy(() => import('../Pages/ForgotPassword'));
const Login = lazy(() => import('../Pages/Login'));
const Models = lazy(() => import('../Pages/ModelsListing'));
const NotFound = lazy(() => import('../Pages/NotFound'));
const CreateClient = lazy(() => import('../Pages/CreateClient'));
const CreateFacility = lazy(() => import('../Pages/CreateFacility'));
const FacilityListing = lazy(() => import('../Pages/FacilityListing'));
const ClientDetails = lazy(() => import('../Pages/ClientDetails'));
const FacilityDetails = lazy(() => import('../Pages/FacilityDetails'));
const CreateModel = lazy(() => import('../Pages/CreateModel'));
const ApprovalListing = lazy(() => import('../Pages/ApprovalListing'));
const ContractDetails = lazy(() => import('../Pages/ContractDetails'));
const RequestListing = lazy(() => import('../Pages/RequestListing'));
const ApprovalHistory = lazy(() => import('../Pages/ApprovalHistory'));
const ServiceRequestDetail = lazy(() => import('../Pages/ServiceRequestDetail'));
const ModelHistory = lazy(() => import('../Pages/ModelHistory'));
const ClientHistory = lazy(() => import('../Pages/ClientHistory'));
const MemberListing = lazy(() => import('../Pages/MemberListing'));
const SharedAccess = lazy(() => import('../Pages/SharedAccess'));
const FacilityHistory = lazy(() => import('../Pages/FacilityHistory'));
const ModelDetails = lazy(() => import('../Pages/ModelDetails'));
const RenewPlan = lazy(() => import('../Pages/RenewPlan'));
const UpdateProfile = lazy(() => import('../Pages/UpdateProfile'));
const VehicleDetails = lazy(() => import('../Pages/VehicleDetails'));
const ClientParkUnpark = lazy(() => import('../Pages/ClientParkUnpark'));
const PlanHistory = lazy(() => import('../Pages/PlanHistory'));
const CreateVehicle = lazy(() => import('../Pages/CreateVehicle'));

const {
  SUPER_ADMIN,
  EMPLOYEE,
} = Constant.userAccess;

const getaccessRoute = () => {
  const { currentUser } = authStore;
  let redictTo = Routes.notFound;
  if (currentUser) redictTo = Routes.dashboard;
  return redictTo;
};

const MainComponent = inject('UIStore', 'userStore')(observer((props: any) => (
  <Layout>
    <Header />
    <Layout className={classNames({ side_menu_collapse: props.UIStore?.collapsed })}>
      <Sidebar />
      <Layout.Content className="p-4 body_layout">
        <Switch>
          <Route path={Routes.home} exact>
            <Redirect to={getaccessRoute()} />
          </Route>
          <AccessRoute
            path={Routes.contracts}
            component={Contracts}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.plan_history}
            component={PlanHistory}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.dashboard}
            component={Dashboard}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.employees_detail}/:id`}
            component={EmployeeDetails}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={Routes.create_employee}
            component={CreateEmployee}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={Routes.employees}
            component={EmployeesListing}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={Routes.clients}
            component={ClientListing}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.facilities}
            component={FacilityListing}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.models}
            component={Models}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          /> <AccessRoute
            path={Routes.settings}
            component={Settings}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={Routes.profile}
            component={Profile}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.orders}
            component={Orders}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.customers}
            component={Customers}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.products}
            component={Products}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.analytics}
            component={Analytics}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.customOrders}
            component={CustomOrders}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.returnOrders}
            component={ReturnOrders}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.abandonedCart}
            component={AbandonedCart}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.create_client}/:id?`}
            component={CreateClient}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={`${Routes.client_detail}/:id`}
            component={ClientDetails}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.create_facility}/:id?`}
            component={CreateFacility}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={`${Routes.facility_detail}/:id`}
            component={FacilityDetails}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.create_model}/:id?`}
            component={CreateModel}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={`${Routes.model_detail}/:id`}
            component={ModelDetails}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.contract_detail}/:id`}
            component={ContractDetails}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.vehicleDetails}/:id`}
            component={VehicleDetails}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.approval}
            component={ApprovalListing}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={Routes.approval_history}
            component={ApprovalHistory}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={Routes.request}
            component={RequestListing}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.service_request_detail}/:id`}
            component={ServiceRequestDetail}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.model_history}
            component={ModelHistory}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.client_history}
            component={ClientHistory}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.shared_member}/:id`}
            component={MemberListing}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.shared_access}/:id`}
            component={SharedAccess}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={Routes.facility_history}
            component={FacilityHistory}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={[`${Routes.renew_plan}/:id`, `${Routes.payment_plan}/:id`]}
            component={RenewPlan}
            access={[SUPER_ADMIN]}
            exact
          />
          <AccessRoute
            path={Routes.updateProfile}
            component={UpdateProfile}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.client_park_unpark}/:id`}
            component={ClientParkUnpark}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <AccessRoute
            path={`${Routes.client}/:client_id${Routes.create_vehicle}/:vehicle_id?`}
            component={CreateVehicle}
            access={[SUPER_ADMIN, EMPLOYEE]}
            exact
          />
          <Redirect to={Routes.notFound} />
        </Switch>
      </Layout.Content>
    </Layout>
  </Layout>
)));

const Router = () => (
  <Suspense fallback={<Skeleton />}>
    <Switch>
      <PublicRoute exact path={Routes.login} component={Login} restricted />
      <Route
        exact
        path={Routes.forgotPassword}
        component={ForgotPassword}
      />
      <Route
        exact
        path={Routes.setPassword}
        component={SetPassword}
      />
      <Route path={Routes.notFound} component={NotFound} exact />
      <PrivateRoute path={Routes.home} component={MainComponent} />
    </Switch>
  </Suspense>
);

export default Router;
