/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, {
  useRef, useState,
} from 'react';
import {
  Button, Layout, List, Menu, Typography,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { Link, useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Routes from '../../Global/Routes';
import './Header.less';
import {
  AppSearch, Icon, QRScanner, Notifications, ProfileImage,
} from '..';
import authStore from '../../Store/authStore';
import useOnClickOutside from '../../Hooks/useOnClickOutside';

import { ITenantStore } from '../../Store/tenantStore';

interface HeaderProps{
  UIStore?: any,
  userStore?: any,
  tenantStore?: ITenantStore,
}

function Header(props: HeaderProps) {
  const { UIStore, userStore, tenantStore } = props;
  const history = useHistory();
  const [isQRScannerModal, setIsQRScannerModal] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const wrapperRef = useRef(null);
  useOnClickOutside(wrapperRef, () => setShowPopUp(false));

  const closeQRScannerModal = () => {
    setIsQRScannerModal(false);
  };

  const showQRScannerModal = () => setIsQRScannerModal(true);
  const onProfileClickHandler = () => setShowPopUp(!showPopUp);
  const gotoProfileEdit = () => {
    setShowPopUp(false);
    history.push(Routes.updateProfile);
  };
  const signOut = () => authStore.signOut();
  const goToProfile = () => {
    setShowPopUp(false);
    history.push(Routes.profile);
  };

  const toggleSidebarCollapse = () => {
    if (!UIStore?.collapsed) {
      UIStore?.collapseSideBar();
    } else {
      UIStore.expandSideBar();
    }
  };

  const profileName = `${userStore?.loggedUser?.first_name} ${userStore?.loggedUser?.middle_name || ''} ${userStore?.loggedUser?.last_name}`;

  return (
    <Layout.Header id="header" className="px-4 d-flex justify-content-between align-items-center">
      <Layout.Content className="d-flex justify-content-between">
        <div className="d-flex align-items-center header_logo">
          <Button type="link" className="p-0 primary me-3 d-none d-xl-block bg-transparent border-0 shadow-none" onClick={toggleSidebarCollapse}>
            <Icon className="icon-18" iconRef="#menu" />
          </Button>
          <Link className="text-start w-100" to={Routes.dashboard}><img width="50px" src={tenantStore?.tenantLogo} alt={tenantStore?.tenantName} /></Link>
        </div>
        <Menu mode="horizontal" className="flex-fill d-none d-xl-block">
          <Menu.Item key="portal" className="h_nav_link">
            <Link to={Routes.dashboard} className="active">Portal</Link>
          </Menu.Item>
          {/* <Menu.Item key="support" className="h_nav_link">
            <Link to=''>Support</Link>
          </Menu.Item>
          <Menu.Item key="team" className="h_nav_link">
            <Link to=''>Team</Link>
          </Menu.Item> */}
        </Menu>
        <List itemLayout="horizontal" className="ms-auto justify-content-end flex-fill d-flex hdr_menu_list">
          <AppSearch />
          <List.Item key="notification" className="p-0 h_rgt_menu border-0">
            {/* <Button type="link">
              <Badge dot offset={['-3px', '8px']} color="#ff4141">
                <Icon className="icon-14" iconRef="#Notification" />
              </Badge>
            </Button> */}
            <Notifications />
          </List.Item>
          <List.Item key="qr-code" className="p-0 h_rgt_menu border-0">
            <Button type="link" className="qr_code_btn r-bold pt-0 pb-0" onClick={showQRScannerModal}>
              <Icon className="icon-14 me-2" iconRef="#qr" />
              <span className="d-none d-xl-inline-block">Scan QR</span>
            </Button>
          </List.Item>
          <List.Item key="User" className="p-0 h_rgt_menu ms-3 position-relative d-none d-xl-block border-0">
            <div ref={wrapperRef}>
              <Button type="link" className="h_user_btn p-0 rounded-circle overflow-hidden" ref={wrapperRef} onClick={onProfileClickHandler}>
                <ProfileImage />
              </Button>
              { showPopUp && (
              <div
                className="bg-white border-radius position-absolute end-0 user_popup box_shadow
            d-flex flex-column w-100"
              >
                <Button className="p-0 bg-transparent border-0 shadow-none h-auto" onClick={goToProfile}>
                  <div className="d-flex justify-content-between p-3 pb-0">
                    <Typography className="text-start w-75">
                      <Title
                        level={3}
                        className="mb-2 lh-1 text-capitalize text-truncate"
                        title={profileName}
                      >{profileName}
                      </Title>
                      {/* <Paragraph className="fs-14 secondary r-medium text-uppercase lh-sm mb-0">
                      super admin
                    </Paragraph> */}
                    </Typography>
                    <div className="ms-2 d-flex justify-content-center align-items-center
                  text-center p-0 icon-24 primary_bg text-white rounded-circle"
                    >
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </div>
                </Button>
                <Button className="p-0 bg-transparent border-0 shadow-none h-auto" onClick={gotoProfileEdit}>
                  <div className="d-flex border-bottom align-items-center p-3 primary">
                    <Icon className="icon-14" iconRef="#edit_icn" />
                    <Typography.Text className="primary fs-16 r-bold lh-sm ms-3">
                      Edit Profile
                    </Typography.Text>
                  </div>
                </Button>
                <Button className="p-0 bg-transparent border-0 shadow-none h-auto" onClick={signOut}>
                  <div className="d-flex align-items-center p-3">
                    <Icon className="icon-14 red" iconRef="#signout" />
                    <Typography.Text className="red fs-16 r-bold lh-sm ms-3">
                      Sign Out
                    </Typography.Text>
                  </div>
                </Button>
              </div>
              ) }
            </div>
          </List.Item>
          <List.Item key="SideBar" className="p-0 h_rgt_menu ms-3 d-xl-none border-0">
            <Button type="link" className="h_user_btn p-0 rounded-circle primary" onClick={() => UIStore?.openSideBar()}>
              <Icon className="icon-18" iconRef="#menu" />
            </Button>
          </List.Item>
        </List>
      </Layout.Content>
      <QRScanner isQRScannerModal={isQRScannerModal} closeQRScannerModal={closeQRScannerModal} />
    </Layout.Header>
  );
}

export default inject('UIStore', 'userStore', 'tenantStore')(observer(Header));
