/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Typography,
} from 'antd';
import PhoneInput from 'react-phone-number-input';
import { useForm } from 'antd/lib/form/Form';

import utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import rules from './PaymentMethodValidation';
import { Toast } from '..';

const CheckboxGroup = Checkbox.Group;
const { GATEWAY, CASH, POS } = Constant.payment_mode;

interface finishHandlerData {
    payment_mode: string,
    email: string,
}

interface PaymentMethodProps {
  paymentHeading: string,
  checkBox?: string,
  finishHandler: (data: finishHandlerData) => void;
  email: string,
  phone: string,
  okButtonText?: string,
}

PaymentMethod.defaultProps = {
  checkBox: 'PAID',
  okButtonText: 'Renew',
};

function PaymentMethod(props: PaymentMethodProps) {
  const {
    paymentHeading, checkBox, finishHandler, email, okButtonText, phone,
  } = props;

  const [paymentMethod, setPaymentMethod] = useState(GATEWAY);
  const [isEmailSelected, setIsEmailSelected] = useState(false);
  const [isPhoneSelected, setIsPhoneSelected] = useState(false);
  const [form] = useForm();

  const paymentModeHandler = (event:any) => {
    setPaymentMethod(event?.target?.value);
    setIsEmailSelected(false);
    setIsPhoneSelected(false);
  };

  const onFinish = (values:any) => {
    if (values.email || values.phone) {
      const data = {
        payment_mode: paymentMethod,
        email: values?.email,
        phone: values?.phone,
      };
      finishHandler(data);
    } else {
      Toast.error('Please select Email or Phone');
    }
  };

  useEffect(() => {
    form.setFieldsValue({ phone });
  }, [phone]);

  useEffect(() => {
    form.setFieldsValue({ email });
  }, [email]);

  const handleEmailCheckbox = (e: any) => {
    setIsEmailSelected(e.target.checked);
  };
  const handlePhoneCheckbox = (e: any) => {
    setIsPhoneSelected(e.target.checked);
  };
  return (
    <Col xs={24}>
      <Card className="create_card">
        <Typography.Title level={3}>Payment method</Typography.Title>
        <Form layout="vertical" onFinish={onFinish} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError form={form}>
          <Row
            gutter={{
              xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
            }}
          >
            <Col md={24} lg={4}>
              <Typography.Title className="m-0" level={5}>
                Method
              </Typography.Title>
            </Col>
            <Col md={24} lg={20}>
              <Typography.Text className="m-0 secondary fs-16">
                {paymentHeading}
              </Typography.Text>
              <Form.Item name="payment_mode" className="mandatory mt-4">
                <Radio.Group className="cust_radio_btn" onChange={paymentModeHandler} defaultValue={GATEWAY}>
                  <Radio className="radio_inp" value={GATEWAY}>Send link</Radio>
                  <Radio className="radio_inp" value={CASH}>Cash payment</Radio>
                  <Radio className="radio_inp" value={POS}>POS payment</Radio>
                </Radio.Group>
              </Form.Item>
              <Divider className="border-top" />
              {paymentMethod === 'GATEWAY'
              && (
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                  <Typography.Text className="m-0 secondary fs-16">
                    Select how you want to send the link
                  </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                  <CheckboxGroup>
                    <Checkbox className="chkbox_style primary r-regular fs-16 radio_inp" onChange={handleEmailCheckbox}>Email</Checkbox>
                  </CheckboxGroup>
                  <CheckboxGroup className="p-3">
                    <Checkbox className="chkbox_style primary r-regular fs-16 radio_inp" onChange={handlePhoneCheckbox}>Phone</Checkbox>
                  </CheckboxGroup>
                </Col>
              </Row>
              )}
              <Row gutter={16}>
                {(isEmailSelected || paymentMethod !== 'GATEWAY')
                && (
                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                  <Form.Item
                    rules={rules.email}
                    label={<Typography.Text className="secondary r-medium">Email</Typography.Text>}
                    className="mandatory"
                    name="email"
                  >
                    <Input placeholder="Enter email" className="inp_style" maxLength={50} />
                  </Form.Item>
                </Col>
                )}
                {isPhoneSelected
                && (
                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                  <Form.Item label="PHONE NUMBER" className="mandatory" name="phone" normalize={utility.trimSpacesInValues} rules={rules.phone}>
                    <PhoneInput
                      defaultCountry="KW"
                      international
                      countries={Constant.myfatoorahSupportedCountries as any}
                      className="phn_inp_style"
                      countryCallingCodeEditable={false}
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={() => {}}
                      maxLength={16}
                    />
                  </Form.Item>
                </Col>
                )}
              </Row>
              {(isEmailSelected || isPhoneSelected || paymentMethod !== 'GATEWAY')
              && (
              <Typography.Text className="m-0 secondary fs-16">
                The invoice will be shared with client through mail once the payment is completed
              </Typography.Text>
              )}
              {paymentMethod !== GATEWAY && checkBox === 'PAID'
            && (
            <div>
              <Form.Item name="paid" required={false} className="mandatory" rules={[{ required: true, message: 'Mark as Paid is required' }]}>
                <CheckboxGroup>
                  <Checkbox className="chkbox_style primary r-regular fs-16 me-2" />Mark as Paid
                </CheckboxGroup>
              </Form.Item>
            </div>
            )}
              {paymentMethod !== GATEWAY && checkBox === 'CLOSE'
            && (
            <div>
              <Form.Item name="close" required={false}>
                <CheckboxGroup>
                  <Checkbox className="chkbox_style primary r-regular fs-16" />
                </CheckboxGroup>
              </Form.Item>
              Close Plan
            </div>
            )}
              <div>
                <Divider className="border-top" />
                <div className="d-flex mt-4 ps-lg-0 ps-4">
                  {paymentMethod === GATEWAY
                    ? (
                      <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                        Send Link
                      </Button>
                    )
                    : (
                      <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                        {checkBox === 'PAID' ? okButtonText : 'Unpark'}
                      </Button>
                    )}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </Col>
  );
}

export default inject('contractStore')(observer(PaymentMethod));
