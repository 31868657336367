/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Card, Skeleton, Table, Tooltip,
} from 'antd';
import { ActivityResponse } from '../../Global/interface';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import NoData from '../NoData';
import { Icon, SortTh } from '..';
import './ContractActivities.less';

interface ActivitiesProps {
  activities: Array<ActivityResponse>;
  isLoading: boolean;
  sortActivity: any;
  orderBy: any;
}

function ContractActivities(props: ActivitiesProps) {
  const {
    activities, isLoading, sortActivity, orderBy,
  } = props;

  const columns = [
    {
      title: 'Activity',
      dataIndex: 'activity',
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      ellipsis: true,
    },
    {
      title: 'User',
      dataIndex: 'user',
      ellipsis: true,
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
    },
    {
      title: 'Swapped vehicle',
      dataIndex: 'swapped_vehicle',
    },
    {
      dataIndex: 'date',
      title: <SortTh title="Date" dataIndex="date" order="date" orderBy={orderBy} />,
      onHeaderCell: () => ({
        onClick: () => {
          sortActivity();
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      ellipsis: true,
    },
  ];

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="activities" />
    ),
  };

  const activityData = activities?.map((item:ActivityResponse) => {
    const { vehicle, swapped_vehicle, employee } = item;
    return {
      activity:
        Constant?.SERVICES.get(item?.service) || Constant?.vehicleActivities?.get(item?.activity),
      action: Constant?.ACTIVITIES.get(item?.activity) || '-',
      user: item.client ? <div className="label_warning rounded-pill ps-2 pe-2 w-100 w-lg-auto d-inline-block ellipse text-center" title="Client">Client</div>
        : (
          <div
            className="label_warning rounded-pill ps-2 pe-2 w-100 w-lg-auto d-inline-block ellipse text-center"
            title={`${employee?.first_name} ${employee?.middle_name || ''} ${employee?.last_name}`}
          >
            {`${employee?.first_name}  ${employee?.middle_name || ''} ${employee?.last_name}`}
          </div>
        ),
      date: Utility.getFormattedDate(item?.createdAt),
      time: Utility.getFormattedTime(item?.createdAt),
      vehicle:
      vehicle
        ? (
          <div className="d-flex align-items-center w-100">
            <span className="text-truncate" title={`${vehicle?.model?.company} ${vehicle?.model?.model}`}>
              {`${vehicle?.model?.company} ${vehicle?.model?.model}`}
            </span>
            <Tooltip title={vehicle?.vehicle_no || vehicle?.vehicle_code}>
              <div className="lh_0">
                <Icon className="icon-16 ms-2" iconRef="#info" />
              </div>
            </Tooltip>
          </div>
        ) : '-',
      swapped_vehicle:
      swapped_vehicle
        ? (
          <div className="d-flex align-items-center w-100">
            <span className="text-truncate" title={`${swapped_vehicle?.model?.company} ${swapped_vehicle?.model?.model}`}>
              {`${swapped_vehicle?.model?.company} ${swapped_vehicle?.model?.model}`}
            </span>
            <Tooltip title={swapped_vehicle?.vehicle_no || swapped_vehicle?.vehicle_code}>
              <div className="lh_0">
                <Icon className="icon-16 ms-2" iconRef="#info" />
              </div>
            </Tooltip>
          </div>
        ) : '-',

    };
  });

  return (
    <Card title="Plan Activities" className="table_card_title">
      <Table
        columns={columns}
        dataSource={activityData}
        pagination={false}
        size="small"
        scroll={{ x: '500px' }}
        className="contract_act_listing table_wrapper ins_table_wrapper"
        locale={locale}
      />
    </Card>
  );
}

export default ContractActivities;
