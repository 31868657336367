/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import Constant from '../../Global/Constant';

const rule: any = {
  contract: [{
    required: true,
    message: Constant.planRequired,
  }],
  contract_type: [{
    required: true,
    message: Constant.contractPeriodRequired,
  }],
  start_date: [{
    required: true,
    message: Constant.startDateRequired,
  }],
  facility: [{
    required: true,
    message: Constant.facilityRequired,
  }],
  parked_date: [{
    required: true,
    message: Constant.parkedDateRequired,
  }],
  vehicle: [{
    required: true,
    message: Constant.vehicleRequired,
  }],
};

export default rule;
