import React from 'react';
import { observer } from 'mobx-react';
import {
  Layout,
  Typography,
} from 'antd';

function AbandonedCart() {
  return (
    <Layout.Content>
      <Typography.Title level={1} className="mb-4">
        Abandoned Cart
      </Typography.Title>
    </Layout.Content>
  );
}

export default observer(AbandonedCart);
