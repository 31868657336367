/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Typography,
} from 'antd';
import PhoneInput from 'react-phone-number-input';

import { useForm } from 'antd/lib/form/Form';
import { Action, Icon, Toast } from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import rules from './vehicleWashValidation';

const CheckboxGroup = Checkbox.Group;

interface Props {
    statusName: string
    date: number
    IconRef: any
    request?: any
    name: string
    price?: number
    showStatus?: boolean
    clientEmail: string
    wash_details?: any,
    clientPhone: string,
    updateVehicleStatus: () => void
    updateServiceRequest: (id: string, action: any) => void
    sendPaymentLink: (requestId: string, data: any) => void
  }

VehicleWash.defaultProps = {
  request: null,
  price: null,
  showStatus: false,
  wash_details: null,
};

const { OPEN, PAYMENT_PENDING } = Constant.serviceRequestStatus;
const { START, COMPLETE, PAYMENT_DONE } = Constant.serviceRequestActions;
const { GATEWAY, POS, CASH } = Constant.payment_mode;

function VehicleWash(props: Props) {
  const {
    date, statusName, IconRef, request, updateServiceRequest, name, price, updateVehicleStatus,
    showStatus, sendPaymentLink, clientEmail, wash_details, clientPhone,
  } = props;
  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const [washedCount, setWashedCount] = useState(wash_details?.used_count || 0);
  const [markedAsPaid, setMarkedAsPaid] = useState(false);
  const [paymentMode, setPaymentMode] = useState(GATEWAY);
  const [isEmailSelected, setIsEmailSelected] = useState(false);
  const [isPhoneSelected, setIsPhoneSelected] = useState(false);
  const [email, setEmail] = useState(clientEmail);
  const isRequestInOpen = request?.status === OPEN;
  const isPaymentPending = request?.status === PAYMENT_PENDING;

  const [form] = useForm();

  const onConfirm = () => {
    const newCount = washedCount + 1;
    if (newCount > wash_details?.count) {
      Toast.error('Maximum wash reached for this plan.');
      return;
    }
    setWashedCount(newCount);
    setIsStatusChecked(false);
    updateVehicleStatus();
  };

  useEffect(() => {
    setEmail(clientEmail);
    form.setFieldsValue({ email: clientEmail });
  }, [clientEmail]);

  useEffect(() => {
    form.setFieldsValue({ phone: clientPhone });
  }, [clientPhone]);

  useEffect(() => {
    setWashedCount(wash_details?.used_count || 0);
  }, [wash_details?.used_count]);

  const onCancel = () => setIsStatusChecked(false);

  const onCheckboxHandler = () => setIsStatusChecked(!isStatusChecked);
  const onPaymentCheckBoxHandler = () => setMarkedAsPaid(!markedAsPaid);
  const onClickHandler = () => updateServiceRequest(request?._id, { action: START });

  const onFinishHandler = () => {
    setIsStatusChecked(false);
    updateServiceRequest(request?._id, { action: COMPLETE });
    updateVehicleStatus();
  };

  const completeHandler = async () => {
    if (paymentMode === GATEWAY) {
      if (!(isPhoneSelected || isEmailSelected)) {
        Toast.error('Please select Email or Phone');
      } else if (await form.validateFields()) {
        const phoneNumber = isPhoneSelected ? form.getFieldValue('phone') : false;
        const clientMail = isEmailSelected ? form.getFieldValue('email') : false;

        const paymentLinkParams: any = {};
        if (clientMail) paymentLinkParams.email = clientMail;
        if (phoneNumber) paymentLinkParams.phone = phoneNumber;
        sendPaymentLink(request?._id, paymentLinkParams);
      }
    } else {
      updateServiceRequest(request?._id,
        { action: PAYMENT_DONE, payment_mode: paymentMode, email });
    }
  };

  const radioHandler = (event: any) => {
    setPaymentMode(event?.target?.value);
    setMarkedAsPaid(false);
    setIsEmailSelected(false);
    setIsPhoneSelected(false);
  };

  const handleEmailCheckbox = (e: any) => {
    setIsEmailSelected(e.target.checked);
  };
  const handlePhoneCheckbox = (e: any) => {
    setIsPhoneSelected(e.target.checked);
  };

  return (
    <div className={request && 'last_brd_0 border-bottom pb-3 mb-4'}>
      { request && (
      // extra wash
      <div>
        <Row>
          <Col lg={9} md={9} sm={9} xs={24}>
            <div className="d-flex align-items-center fs-16">
              <div className="icn_bg_44 primary rounded-circle d-flex align-items-center
                     justify-content-center me-3"
              ><Icon className="icon-20" iconRef={IconRef} />
              </div>
              <div className="lh-sm r-bold fs-16 me-3">
                <Typography.Text className="r-medium fs-14 secondary text-uppercase">Request</Typography.Text><br />
                {statusName}
              </div>
            </div>
          </Col>
          <Col lg={15} md={15} sm={15} xs={24}>
            <Row>
              <Col lg={15} md={15} sm={15} xs={24}>
                <div className="d-flex justify-content-between justify-content-sm-start mt-3 mt-sm-0 mb-3 mb-sm-0">
                  <div className="me-3 r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Date:</Typography.Text><br />
                    {Utility?.getFormattedDate(request?.scheduled_time)}
                  </div>
                  <div className="r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Time</Typography.Text><br />
                    {Utility?.getFormattedTime(request?.scheduled_time)}
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={9} xs={24} className="d-flex justify-content-sm-end align-items-start">
                {isRequestInOpen && (
                <Button
                  className="fs-16 green_bg white border-radius border-0 pt-1 pb-1 r-bold h-40 start_btn_resp"
                  onClick={onClickHandler}
                >
                  Start Task
                </Button>
                ) }
                {!isRequestInOpen
            && (
            <div className="label_warning rounded-pill pt-1 pb-1 text-truncate">
              {Constant.ServiceReqStatusText.get(request?.status)}
            </div>
            )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      ) }
      {(!showStatus || request) && (
      <Row>
        {!isPaymentPending
        && (
        <Col lg={9} md={9} sm={9} xs={24}>
          { !request
          // wash
          && (
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
              <Icon className="icon-20" iconRef={IconRef} />
            </div>
            {statusName} (Free - {washedCount} / {wash_details?.count})
          </div>
          ) }
        </Col>
        )}
        <Col
          lg={isPaymentPending ? 24 : 9}
          md={isPaymentPending ? 24 : 9}
          sm={isPaymentPending ? 24 : 9}
          xs={24}
        >
          {isPaymentPending
        && (
          <Divider className="mt-3 mb-3 border-bottom border-xs-bottom" />
        )}
          <div className={isPaymentPending ? 'ps-60 ps_xs_0' : ''}>
            <div className="r-bold fs-16">{name}{showStatus && `(${price} KD)` }</div>
            {date
            && (
            <Typography.Paragraph className="fs-16 mt-2 mb-0 inactive">
              Last updated - {Utility.getFormattedDate(date)}
            </Typography.Paragraph>
            )}

            { !isRequestInOpen
            // in progress or payment pending
            && (
              !isPaymentPending ? (
                <Checkbox className="chkbox_style primary r-regular fs-16" onChange={onCheckboxHandler} checked={isStatusChecked}>
                  Vehicle Washed
                </Checkbox>
              )
                : (
                  <>
                    <Radio.Group
                      className="cust_radio_btn d-sm-flex"
                      value={paymentMode}
                      onChange={radioHandler}
                    >
                      <Radio className="radio_inp" value={GATEWAY}>Send link</Radio>
                      <Radio className="radio_inp" value={CASH}>Cash payment</Radio>
                      <Radio className="radio_inp" value={POS}>POS payment</Radio>
                    </Radio.Group>
                    {paymentMode === 'GATEWAY'
              && (
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                  <Typography.Text className="m-0 secondary fs-16">
                    Select how you want to send the link
                  </Typography.Text>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                  <CheckboxGroup>
                    <Checkbox className="chkbox_style primary r-regular fs-16 radio_inp" onChange={handleEmailCheckbox}>Email</Checkbox>
                  </CheckboxGroup>
                  <CheckboxGroup className="p-3">
                    <Checkbox className="chkbox_style primary r-regular fs-16 radio_inp" onChange={handlePhoneCheckbox}>Phone</Checkbox>
                  </CheckboxGroup>
                </Col>
              </Row>
              )}
                  </>
                )
            ) }

            { request && !isRequestInOpen
            // request is in progress or payment pending
          && (
            <div>
              {paymentMode !== GATEWAY
            && (
            <Typography.Paragraph className="fs-16 mt-2 mb-0 secondary">
              Use this option if the client paid the service request through {paymentMode === CASH ? 'cash' : 'pos'}
            </Typography.Paragraph>
            )}
              { isPaymentPending
              && (
                <Form layout="vertical" validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError form={form}>
                  <div className="mt-3">
                    <Row gutter={16}>
                      {(isEmailSelected || paymentMode !== 'GATEWAY')
                && (
                  <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                    <Form.Item
                      rules={rules.email}
                      label={<Typography.Text className="secondary r-medium">Email</Typography.Text>}
                      className="mandatory"
                      name="email"
                    >
                      <Input type="email" placeholder="email" className="border-0 border-radius mt-2" value={email} onChange={(event) => setEmail(event?.target?.value)} />
                    </Form.Item>
                  </Col>
                )}
                      {isPhoneSelected
               && (
               <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                 <Form.Item label="PHONE NUMBER" className="mandatory" name="phone" normalize={Utility.trimSpacesInValues} rules={rules.phone}>
                   <PhoneInput
                     defaultCountry="KW"
                     international
                     countries={Constant.myfatoorahSupportedCountries as any}
                     className="phn_inp_style border-0 border-radius mt-2"
                     countryCallingCodeEditable={false}
                     placeholder="Enter phone number"
                     value={clientPhone}
                     onChange={() => {}}
                     maxLength={16}
                   />
                 </Form.Item>
               </Col>
               )}
                      <Typography.Paragraph className="fs-16 mt-2 mb-0 secondary">
                        The invoice will be shared with
                        client through mail once the payment is completed
                      </Typography.Paragraph>
                    </Row>
                  </div>
                </Form>
              ) }

              {paymentMode !== GATEWAY
            && (
            <Checkbox className="chkbox_style primary r-regular fs-16" value={markedAsPaid} onChange={onPaymentCheckBoxHandler}>
              Mark As Paid
            </Checkbox>
            ) }
              { isPaymentPending && (
                <div>
                  <Button
                    className="primary_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
                    onClick={completeHandler}
                    disabled={paymentMode !== GATEWAY && !markedAsPaid}
                  >
                    { paymentMode === GATEWAY ? 'Send Link' : 'Complete Request' }
                  </Button>
                </div>
              ) }
              { !isPaymentPending && (
              <Button
                className="yellow_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
                onClick={onFinishHandler}
                disabled={!isStatusChecked}
              >
                Finish Task
              </Button>
              ) }
            </div>
          )}
          </div>
        </Col>

        { !request && isStatusChecked && (
        // wash
        <Col
          lg={5}
          className="d-flex justify-content-end
                  align-items-start mt-3 mt-sm-0"
        ><Action onAccept={onConfirm} onReject={onCancel} />
        </Col>
        )}

      </Row>
      ) }
    </div>
  );
}

export default VehicleWash;
