/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Col,
  Typography,
  Input,
  Row,
  Divider,
  Button,
  InputNumber,
  Radio,
} from 'antd';
import { Action, Icon, Toast } from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

interface Props {
  statusName: string
  checkBoxText: String
  IconRef: any,
  milageStatus: any,
  request?: any
  updateServiceRequest: (id: string, action: any) => void
  updateVehicleStatus: (statusData: any, id?: string) => void
}

VehicleMilage.defaultProps = {
  request: null,
};

const { OPEN } = Constant.serviceRequestStatus;
const { START, COMPLETE } = Constant.serviceRequestActions;

function VehicleMilage(props: Props) {
  const {
    checkBoxText, statusName, IconRef, updateVehicleStatus,
    milageStatus, request, updateServiceRequest,
  } = props;

  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const [mileageValue, setMileageValue] = useState(0);
  const isRequestStarted = request?.status === OPEN;
  const [mileageUnit, setMileageUnit] = useState<string>(localStorage?.getItem('mileageUnit') || Constant.MI);

  const onConfirm = () => {
    setIsStatusChecked(false);
    if (mileageValue) {
      let currentMilageValue = mileageValue;
      if (mileageUnit === Constant.KM) currentMilageValue = mileageValue * Constant.KMToMI;
      updateVehicleStatus(currentMilageValue);
      Utility.saveMileagePreference(mileageUnit);
    } else {
      Toast.error(Constant.invalidMileage);
    }
  };

  const onCancel = () => {
    setMileageValue(milageStatus?.value);
    setIsStatusChecked(false);
  };

  const onCheckboxHandler = () => setIsStatusChecked(!isStatusChecked);
  const onClickHandler = () => updateServiceRequest(request?._id, { action: START });

  const onChangeHandler = (value: any) => setMileageValue(value);

  const onFinishHandler = () => {
    if (mileageValue) {
      let currentMilageValue = mileageValue;
      if (mileageUnit === Constant.KM) currentMilageValue = mileageValue * Constant.KMToMI;
      updateVehicleStatus(currentMilageValue, request?._id);
      updateServiceRequest(request?._id, { action: COMPLETE });
      Utility.saveMileagePreference(mileageUnit);
    } else {
      Toast.error(Constant.invalidMileage);
    }
  };

  const radioHandler = (event: any) => {
    const { value } = event?.target;
    if (value === Constant.KM) setMileageValue((mileValue) => mileValue * Constant.MIToKM);
    else if (value === Constant.MI) setMileageValue((kmValue) => kmValue * Constant.KMToMI);
    setMileageUnit(value);
  };

  useEffect(() => {
    if (mileageUnit === Constant.KM) setMileageValue(milageStatus?.value * Constant.MIToKM);
    else setMileageValue(milageStatus?.value);
  }, [milageStatus?.value]);

  const unitText = mileageUnit === Constant.KM ? 'KM' : 'MI';
  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      { request && (
      <div>
        <Row gutter={16}>
          <Col lg={9} md={9} sm={9} xs={24}>
            <div className="d-flex align-items-center fs-16  mb-2 mb-sm-0">
              <div className="icn_bg_44 primary rounded-circle d-flex align-items-center
                     justify-content-center me-3"
              ><Icon className="icon-20" iconRef={IconRef} />
              </div>
              <div className="lh-sm r-bold fs-16">
                <Typography.Text className="r-medium fs-14 secondary text-uppercase">Request</Typography.Text><br />
                {statusName}
              </div>
            </div>
            <div className="metrices_wrap mt-2 mb-2 mb-sm-0 ps_xs_0">
              <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">METRICS</Typography.Text>
              <Radio.Group
                value={mileageUnit}
                buttonStyle="solid"
                className="radio_tab gray_bg w-100 border-radius p-0"
                onChange={radioHandler}
              >
                <Radio.Button value={Constant.KM} className="radio_inp brd_left_radius bg-transparent border-0 w-50 text-center fs-16 p-0">KM</Radio.Button>
                <Radio.Button value={Constant.MI} className="radio_inp brd_right_radius bg-transparent border-0 w-50 text-center fs-16 p-0">MI</Radio.Button>
              </Radio.Group>
            </div>
          </Col>
          <Col lg={15} md={15} sm={15} xs={24}>
            <Row>
              <Col lg={15} md={15} sm={15} xs={24}>
                <div className="d-flex justify-content-between justify-content-sm-start mt-3 mt-sm-0 mb-3 mb-sm-0">
                  <div className="me-3 r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Date</Typography.Text><br />
                    {Utility?.getFormattedDate(request?.scheduled_time)}
                  </div>
                  <div className="r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Time</Typography.Text><br />
                    {Utility?.getFormattedTime(request?.scheduled_time)}
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={9} xs={24} className="d-flex justify-content-sm-end align-items-start">
                {isRequestStarted && (
                <Button className="fs-16 green_bg white border-radius border-0 pt-1 pb-1 r-bold h-40 start_btn_resp" onClick={onClickHandler}>
                  Start Task
                </Button>
                ) }
                {!isRequestStarted && (<div className="label_warning rounded-pill pt-1 pb-1">In Progress </div>)}
              </Col>
            </Row>
            <Divider className="mt-3 mb-3 border-bottom border-xs-bottom" />
          </Col>
        </Row>
      </div>
      ) }
      <Row gutter={16}>
        <Col lg={9} md={9} sm={9} xs={24}>
          { !request && (
            <div>
              <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
                <div className="icn_bg_44 primary rounded-circle d-flex align-items-center justify-content-center me-3">
                  <Icon className="icon-20" iconRef={IconRef} />
                </div>
                {statusName}
              </div>
              <div className="metrices_wrap mt-2 mb-2 mb-sm-0 ps_xs_0">
                <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">METRICS</Typography.Text>
                <Radio.Group
                  value={mileageUnit}
                  buttonStyle="solid"
                  className="radio_tab gray_bg w-100 border-radius p-0"
                  onChange={radioHandler}
                >
                  <Radio.Button value={Constant.KM} className="radio_inp brd_left_radius bg-transparent border-0 w-50 text-center fs-16 p-0">KM</Radio.Button>
                  <Radio.Button value={Constant.MI} className="radio_inp brd_right_radius bg-transparent border-0 w-50 text-center fs-16 p-0">MI</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          ) }
        </Col>
        <Col lg={9} md={9} sm={9} xs={24}>
          <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Value</Typography.Text>
          <div className="position-relative">
            <InputNumber
              className={`${request ? 'white_bg' : 'off-white-bg'} border-radius border-0 pe-5 ser_req_inp h-40 w-100 shadow-none`}
              value={mileageValue}
              disabled={isRequestStarted}
              onChange={onChangeHandler}
              min={0}
              max={mileageUnit === Constant.KM
                ? Constant.mileageKiloMeterMaximumValue : Constant.mileageMilesMaximumValue}
              maxLength={6}
            />
            <Typography.Text className="position-absolute inactive unit">{unitText}</Typography.Text>
          </div>
          { !isRequestStarted && (
          <Checkbox
            className="chkbox_style primary r-regular fs-16 mt-3"
            onChange={onCheckboxHandler}
            checked={isStatusChecked}
          >
            {checkBoxText}
          </Checkbox>
          ) }
          {milageStatus?.last_updated_time
          && (
          <Typography.Paragraph className="fs-16 mt-2 inactive">Last updated - {Utility.getFormattedDate(milageStatus?.last_updated_time)}
          </Typography.Paragraph>
          )}
          { request && !isRequestStarted
        && (
        <Button
          className="yellow_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
          disabled={!isStatusChecked}
          onClick={onFinishHandler}
        >Finish Task
        </Button>
        )}
        </Col>
        { !request && isStatusChecked && (
          <Col
            lg={5}
            className="d-flex justify-content-end
                  align-items-start mt-3 mt-sm-0"
          ><Action onAccept={onConfirm} onReject={onCancel} />
          </Col>
        )}
      </Row>
    </div>
  );
}
export default VehicleMilage;
