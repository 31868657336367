/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Card, Col, DatePicker, Divider, Form, Input, Radio, Row, Select, Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';
import PhoneInput from 'react-phone-number-input';
import rules from './create-client-form.validation';
import utility from '../../Global/Utility';
import './CreateClient.less';
import Toast from '../../Components/Toast';
import { Icon } from '../../Components';
import 'react-phone-number-input/style.css';

function CreateAccount(props: any) {
  const {
    isUpdate, onFinish, onCancel, clientStore, masterStore,
  } = props;
  const [selectedCountryName, setSelectedCountryName] = useState<any>(null);
  const [selectedStateName, setSelectedStateName] = useState<any>(null);
  const [phone, setPhone] = useState<any>();
  const [form] = useForm();
  const { Option } = Select;
  const clientData = clientStore.client;
  const countryCodes = masterStore?.contryCodes;
  const countryChanged = (countryName: any) => {
    setSelectedCountryName(countryName);
    form.setFieldsValue({ state: null, city: null });
  };
  const stateChanged = (stateName: any) => {
    setSelectedStateName(stateName);
    form.setFieldsValue({ city: null });
  };

  async function getCountryData() {
    await masterStore?.getCountryData()
      .catch((error:any) => { Toast.error(error?.response?.data?.error?.message); });
  }

  const countryData = masterStore?.countries;

  const setFormData = () => {
    form.setFieldsValue({
      first_name: clientData?.first_name,
      middle_name: clientData?.middle_name,
      last_name: clientData?.last_name,
      gender: clientData?.gender,
      email: clientData?.email,
      phone: clientData?.phone,
      country: clientData?.address?.country,
      state: clientData?.address?.state,
      address_line_1: clientData?.address?.address_line_1,
      address_line_2: clientData?.address?.address_line_2,
      zip_code: clientData?.address?.zip_code,
      city: clientData?.address?.city,
      dob: clientData?.dob && moment(clientData?.dob),
      joined_date: moment(clientData?.joined_date || clientData?.createdAt),
    });
    setSelectedCountryName(clientData?.address?.country);
    setSelectedStateName(clientData?.address?.state);
  };

  useEffect(() => {
    if (!masterStore?.countries) {
      getCountryData();
    }
  }, []);

  useEffect(() => {
    if (clientData && isUpdate) {
      setFormData();
    }
  }, [clientData]);

  const disabledDateForDob = (current: Moment): boolean => current && current > moment();
  const disableEmail = !!clientData?.active;

  return (
    <Card className="create_card">
      <Form initialValues={{ gender: 'Male' }} layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <Row
          gutter={{
            xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
          }}
          className="m-0"
        >
          <Col sm={24}>
            <Typography.Title level={3} className="mb-4">Basic information</Typography.Title>
          </Col>
          <Col lg={4} md={24} sm={24} xs={24}>
            <Typography.Title className="m-0" level={5}>
              Profile
            </Typography.Title>
          </Col>
          <Col lg={20} md={24} sm={24} xs={24}>
            <Typography.Text className="m-0 create_para">
              {isUpdate ? 'Edit client basic informations' : 'Enter client basic informations'}
            </Typography.Text>
            <Row gutter={16} className="mt-3">
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="FIRST NAME" name="first_name" className="mandatory" required={false} normalize={utility.trimSpacesInValues} rules={rules.name}>
                  <Input placeholder="Enter first name" className="inp_style" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="MIDDLE NAME" name="middle_name" normalize={utility.trimSpacesInValues} required={false} rules={rules.middle_name}>
                  <Input placeholder="Enter middle name" className="inp_style" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="LAST NAME" name="last_name" className="mandatory" required={false} normalize={utility.trimSpacesInValues} rules={rules.last_name}>
                  <Input placeholder="Enter last name" className="inp_style" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="DATE OF BIRTH" name="dob">
                  <DatePicker
                    className="inp_style date_picker"
                    dropdownClassName="date_picker_drp_dwn"
                    placeholder="Choose date"
                    showToday={false}
                    format="DD-MM-YYYY"
                    disabledDate={disabledDateForDob}
                    suffixIcon={(
                      <Icon className="icon-16 primary" iconRef="#calendar" />
                            )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item name="gender" label="gender" className="mandatory" rules={rules.gender}>
                  <Radio.Group className="cust_radio_btn" defaultValue="Male">
                    <Radio className="radio_inp" value="Male">Male</Radio>
                    <Radio className="radio_inp" value="Female">Female</Radio>
                    <Radio className="radio_inp" value="Other">Other</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="EMAIL" name="email" normalize={utility.trimSpacesInValues} rules={rules.email}>
                  <Input placeholder="Enter email" type="text" className="inp_style" disabled={disableEmail} maxLength={50} />
                </Form.Item>
              </Col>
              {/* phone update */}
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="PHONE NUMBER" name="phone" rules={rules.phone} normalize={utility.trimSpacesInValues}>
                  {countryCodes
                && (
                <PhoneInput
                  defaultCountry="KW"
                  international
                  countries={countryCodes}
                  className="phn_inp_style"
                  countryCallingCodeEditable={false}
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={setPhone}
                  maxLength={16}
                />
                )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="JOINED DATE" name="joined_date">
                  <DatePicker
                    className="inp_style date_picker"
                    dropdownClassName="date_picker_drp_dwn"
                    placeholder="Choose date"
                    showToday={false}
                    format="DD-MM-YYYY"
                    disabledDate={disabledDateForDob}
                    suffixIcon={(
                      <Icon className="icon-16 primary" iconRef="#calendar" />
                            )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider className="m-0 border-top" />
          </Col>
        </Row>
        <Row
          gutter={{
            xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
          }}
          className="m-0 mt-3"
        >
          <Col md={24} lg={4}>
            <Typography.Title className="m-0" level={5}>
              Address
            </Typography.Title>
          </Col>
          <Col md={24} lg={20}>
            <Typography.Text className="m-0 create_para">
              Manage customers billing address here
            </Typography.Text>
            <Row gutter={16} className="mt-3">
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="country" className="mandatory" name="country" rules={rules.country}>
                  <Select
                    suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                    placeholder="Choose country"
                    className="slct_style"
                    dropdownClassName="slct_drp_dwn border-radius"
                    onChange={countryChanged}
                  >
                    {countryData && Array.from(countryData?.keys())
                      ?.map((country:any) => <Option value={country}>{country}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="state" className="mandatory" name="state" rules={rules.state}>
                  <Select
                    suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                    placeholder="Choose State"
                    className="slct_style"
                    dropdownClassName="slct_drp_dwn border-radius"
                    onChange={stateChanged}
                  >
                    {selectedCountryName
                            && countryData?.get(selectedCountryName)?.keys()
                            && Array?.from(countryData?.get(selectedCountryName)?.keys())
                              ?.map((state:any) => <Option value={state}>{state}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="city" className="mandatory" name="city" rules={rules.city}>
                  <Select
                    suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                    placeholder="Choose City"
                    className="slct_style"
                    dropdownClassName="slct_drp_dwn border-radius"
                  >
                    {selectedStateName
                            && countryData?.get(selectedCountryName)?.keys()
                            && countryData.get(selectedCountryName).get(selectedStateName)
                              ?.cities?.map((state:any) => <Option value={state}>{state}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="POST CODE" name="zip_code" normalize={utility.trimSpacesInValues} rules={rules.zip_code}>
                  <Input placeholder="Enter post code" className="inp_style" maxLength={15} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="Address Line 1" name="address_line_1" normalize={utility.trimSpacesInValues} rules={rules.addressLine1}>
                  <Input placeholder="Enter address line 1" className="inp_style" maxLength={50} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                <Form.Item label="Address Line 2" name="address_line_2" normalize={utility.trimSpacesInValues} rules={rules.addressLine2}>
                  <Input placeholder="Enter address line 2" className="inp_style" maxLength={50} />
                </Form.Item>
              </Col>
            </Row>
            <Divider className="m-0 border-top" />
            <div>
              <div className="d-flex mt-4">
                <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
                  {isUpdate ? 'Update' : 'Create Account'}
                </Button>
                <Button type="text" className="cancel_btn fs-16 r-bold" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default inject('clientStore', 'masterStore')(observer(CreateAccount));
