/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import { Tooltip } from 'antd';
import React from 'react';
import { Icon } from '..';

import './SortTh.less';

interface SortThprops {
  title:string,
  dataIndex:string,
  order:string,
  orderBy:number,
  className?:string,
}
SortTh.defaultProps = {
  className: '',
};
function SortTh(props:SortThprops) {
  const {
    title, dataIndex, order, orderBy, className,
  } = props;
  const getTitle = () => {
    if (order === dataIndex) {
      if (orderBy === 1) {
        return 'click to sort descending';
      }
      return 'click to sort ascending';
    }
    return 'click to sort descending';
  };

  const classNames = [`cursor-pointer icon-11 ${className}`];
  if (orderBy === 1) classNames.push('srt_arw_down');
  else classNames.push('srt_arw_up');

  return (
    <Tooltip className="cursor-pointer" title={getTitle()}>{title}
      <Icon className={order !== dataIndex ? `srt_arw_down ${classNames}` : classNames.join(' ')} iconRef="#sort" />
    </Tooltip>
  );
}

export default SortTh;
