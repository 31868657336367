/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Col, DatePicker, Divider, Form, Input,
  Row, Select, Typography, Spin, Layout, Card, Checkbox,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  Icon, Toast, UploadDocument, ListDocument,
} from '../../Components';
import vehicleService from '../../Services/vehicleService';
import fileService from '../../Services/fileService';
import Constant from '../../Global/Constant';
import './CreateVehicle.less';
import CreateModel from '../CreateClient/CreateModel';
import Routes from '../../Global/Routes';
import Rules from './create-vehicle-form.validation';
import Utility from '../../Global/Utility';

const { REGISTRATION, INSURANCE } = Constant?.vehicleDocTypes;
const { Sider } = Layout;

function CreateVehicle(props: any) {
  const {
    masterStore, vehicleStore,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const { client_id, vehicle_id } = useParams<any>();
  const history = useHistory();
  const { vehicleCategories, countries: countryData } = masterStore;
  const { Option } = Select;

  const [isDocUploading, setIsDocUploading] = useState<boolean>(false);
  const [regDocuments, setRegDocuments] = useState<any>([]);
  const [InsDocuments, setInsDocuments] = useState<any>([]);
  const [isCreateModelVisible, setIsCreateModelVisible] = useState<boolean>(false);
  const [vehicleDocType, setVehicleDocType] = useState<string>('');
  const [isUploadVisible, setIsUploadVisible] = useState<boolean>(false);
  const [isVehicleUpdate, setIsVehicleUpdate] = useState<boolean>(false);
  const [selectedCountryName, setSelectedCountryName] = useState<any>(null);
  const [selectedStateName, setSelectedStateName] = useState<any>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isAddressPresent, setIsAddressPresent] = useState<boolean>(false);

  const addressCheckboxHanlder = () => setIsAddressPresent(!isAddressPresent);

  const countryChanged = (countryName: any) => {
    setSelectedCountryName(countryName);
    form.setFieldsValue({ state: null, city: null });
  };

  const stateChanged = (stateName: any) => {
    setSelectedStateName(stateName);
    form.setFieldsValue({ city: null });
  };

  async function getCountryData() {
    masterStore?.getCountryData()
      .catch((error:any) => { Toast.error(error?.response?.data?.error?.message); });
  }

  useEffect(() => {
    if (!masterStore?.countries) {
      getCountryData();
    }
  }, []);

  const createVehicle = async (value: any) => {
    try {
      setIsLoading(true);
      const {
        chassis_no, vehicle_no, insurance_exp_date, registration_exp_date, model,
        country, state, city, zip_code, address_line_1, address_line_2,
      } = value;

      const vehicleForm = {
        chassis_no,
        vehicle_no,
        model,
        insurance_exp_date: insurance_exp_date ? moment(insurance_exp_date).format('YYYY-MM-DD') : undefined,
        registration_exp_date: registration_exp_date ? moment(registration_exp_date).format('YYYY-MM-DD') : undefined,
        registration_doc: regDocuments,
        insurance_doc: InsDocuments,
        clientId: client_id,
        country,
        state,
        city,
        zip_code,
        address_line_1,
        address_line_2,
      };
      const response = await vehicleService.createVehicle(vehicleForm);
      setIsLoading(false);
      gotoVehicleDetails(response?.data?.id);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const gotoVehicleDetails = (vehicleId: string) => history.push(`${Routes.vehicleDetails}/${vehicleId}`);
  const updateVehicle = async (values: any) => {
    try {
      const {
        chassis_no, vehicle_no, insurance_exp_date, registration_exp_date, model,
        country, state, city, zip_code, address_line_1, address_line_2,
      } = values;

      const vehicleForm: any = {
        chassis_no,
        vehicle_no,
        model,
        insurance_exp_date: insurance_exp_date
          ? moment(insurance_exp_date).format('YYYY-MM-DD') : null,
        registration_exp_date: registration_exp_date
          ? moment(registration_exp_date).format('YYYY-MM-DD') : null,
        registration_doc: regDocuments,
        insurance_doc: InsDocuments,
        clientId: client_id,
        country: country || null,
        state: state || null,
        city: city || null,
        zip_code: zip_code || null,
        address_line_1: address_line_1 || null,
        address_line_2: address_line_2 || null,
      };

      await vehicleService.updateVehicle(vehicle_id, vehicleForm);
      gotoVehicleDetails(vehicle_id);
    } catch (error: any) {
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const onFinish = async (values: any) => {
    if (isVehicleUpdate) updateVehicle(values);
    else createVehicle(values);
  };

  const setUploadedDocuments = (documents:any) => {
    if (vehicleDocType === REGISTRATION) {
      setRegDocuments([...regDocuments, ...documents]);
    } else {
      setInsDocuments([...InsDocuments, ...documents]);
    }
  };

  const uploadDocuments = async (documents: any) => {
    setIsDocUploading(true);
    const fileFormData = new FormData();
    documents.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
    const response = await fileService.uploadFile(fileFormData);
    setIsUploadVisible(false);
    setIsDocUploading(false);
    setUploadedDocuments(response?.data);
  };

  const removeFileFromUploaded = (index:number, docType: string) => {
    const vehicleDoc = docType === REGISTRATION
      ? regDocuments.filter((value : any, actualIndex:number) => actualIndex !== index)
      : InsDocuments.filter((value : any, actualIndex:number) => actualIndex !== index);

    if (docType === REGISTRATION) setRegDocuments(vehicleDoc);
    else setInsDocuments(vehicleDoc);
  };

  const populateVehicleDetails = async () => {
    await vehicleStore.getVehicleById(vehicle_id);
    const {
      model,
      registration_exp_date,
      insurance_exp_date,
      chassis_no,
      vehicle_no,
      registration_doc,
      insurance_doc,
      address,
    } = vehicleStore?.vehicle;
    vehicleCategoryChange(model?.category);
    const formData: any = {
      category: model?.category,
      model: model?._id,
      chassis_no,
      vehicle_no,
    };
    if (address?.country) {
      formData.country = address?.country;
      setSelectedCountryName(address?.country);
    }
    if (address?.state) {
      formData.state = address?.state;
      setSelectedStateName(address?.state);
    }
    if (address?.city) formData.city = address?.city;
    if (address?.zip_code) formData.zip_code = address?.zip_code;
    if (address?.address_line_1) formData.address_line_1 = address?.address_line_1;
    if (address?.address_line_2) formData.address_line_2 = address?.address_line_2;
    if (address?.country || address?.state || address?.city) setIsAddressPresent(true);

    form.setFieldsValue(formData);
    if (registration_exp_date) {
      form.setFieldsValue({
        registration_exp_date: moment(registration_exp_date),
      });
    }
    if (insurance_exp_date) {
      form.setFieldsValue({
        insurance_exp_date: moment(insurance_exp_date),
      });
    }
    setRegDocuments(registration_doc || []);
    setInsDocuments(insurance_doc || []);
  };

  const getInitials = async () => {
    try {
      setIsLoading(true);
      if (!masterStore?.vehicleCategories) {
        await masterStore.getVehicleCategories();
      }
      if (vehicle_id) {
        setIsVehicleUpdate(true);
        populateVehicleDetails();
      }
      setIsLoading(false);
    } catch (error: any) {
      Toast.error(error);
    }
  };

  useEffect(() => {
    getInitials();
  }, []);

  const closeUploadModal = () => setIsUploadVisible(false);

  const showUploadModal = (docType: string) => {
    setVehicleDocType(docType);
    setIsUploadVisible(true);
  };
  const vehicleCategoryChange = (value:string) => {
    form.resetFields(['model']);
    getModels(value);
  };

  const getModels = async (
    category: string, user_created: Boolean = true, published: Boolean = true,
  ) => {
    setIsLoading(true);
    await masterStore.getModels({ category, user_created, published });
    setIsLoading(false);
  };
  const modelFilterOption = (input: any, option: any) => (
    option?.children?.props?.children[0]?.props?.children[0]?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0
  );

  const showModelCreate = () => {
    setIsCreateModelVisible(true);
  };

  const closeModelCreate = () => {
    setIsCreateModelVisible(false);
  };

  const onFinishModelCreate = async (model: string, category: string) => {
    closeModelCreate();
    await getModels(category);
    form.setFieldsValue({ category, model });
  };

  const gotoClientDetailsPage = () => history.goBack();

  return (
    <Layout.Content className="create_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className={classNames(('mb-4 mb-xl-0'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Button className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content bg-transparent border-0 p-0 shadow-none" onClick={gotoClientDetailsPage}>
              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Button>
            <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24">
              {isVehicleUpdate ? 'Edit vehicle' : 'Create vehicle'}
            </Typography.Title>
            <Card className="create_card">
              <Form layout="vertical" onFinish={onFinish} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Details
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <Typography.Paragraph className="create_para">
                        Choose or enter model details
                      </Typography.Paragraph>
                      <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={showModelCreate}>
                        Add new model
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                    </div>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="vehicle category" className="mandatory" name="category" rules={Rules?.vehicle_category}>
                          <Select
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Choose"
                            className="slct_style"
                            dropdownClassName="slct_drp_dwn border-radius"
                            onChange={vehicleCategoryChange}
                          >
                            {vehicleCategories?.map((vehicleCategory: any) => (
                              <Option key={vehicleCategory?._id} value={vehicleCategory.category}>
                                {vehicleCategory.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item label="Choose Model" className="mandatory" name="model" rules={Rules?.model}>
                          <Select
                            showSearch
                            suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                            placeholder="Select a model"
                            className="slct_style w-100"
                            dropdownClassName="slct_drp_dwn border-radius"
                            filterOption={modelFilterOption}
                          >
                            {masterStore?.models?.map((model: any) => (
                              <Option key={model?._id} value={model?._id}>
                                <div className="d-flex justify-content-between">
                                  <div className="text-truncate">
                                    {`${model?.company} ${model?.model}`}<br />
                                    <span className="text-uppercase secondary r-regular d-flex align-items-center">{model?.manufacture_year}</span>
                                  </div>
                                  <span className="veh_color d-inline-block ms-3 rounded-circle v_color_display" style={{ backgroundColor: model?.color }} />
                                </div>
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top mb-3" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24}>
                    <Typography.Title className="m-0" level={5}>
                      Documents
                    </Typography.Title>
                  </Col>
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="m-0 create_para">
                        Add registration documents
                      </Typography.Text>
                      <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={() => showUploadModal(REGISTRATION)}>
                        Add
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                    </div>
                    {regDocuments
                && (
                  <ListDocument
                    documents={regDocuments}
                    removeFile={
                      (index: number) => removeFileFromUploaded(index, REGISTRATION)
                    }
                  />
                )}
                    {regDocuments?.length !== 0
                    && (
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="EXPIRY DATE"
                          name="registration_exp_date"
                        >
                          <DatePicker
                            className="inp_style date_picker primary"
                            dropdownClassName="date_picker_drp_dwn"
                            placeholder="Choose expiry date"
                            showToday={false}
                            format="DD-MM-YYYY"
                            suffixIcon={(
                              <Icon className="icon-16 primary" iconRef="#calendar" />
                      )}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10} />
                    </Row>
                    )}
                    <Divider className="m-0 border-top mb-3" />
                    <div className="d-flex justify-content-between">
                      <Typography.Text className="m-0 create_para">
                        Add insurance documents
                      </Typography.Text>
                      <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={() => showUploadModal(INSURANCE)}>
                        Add
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                      </Button>
                    </div>
                    {InsDocuments
                && (
                  <ListDocument
                    documents={InsDocuments}
                    removeFile={
                      (index: number) => removeFileFromUploaded(index, INSURANCE)
                    }
                  />
                )}
                    {InsDocuments?.length !== 0
                    && (
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="EXPIRY DATE"
                          name="insurance_exp_date"
                        >
                          <DatePicker
                            className="inp_style date_picker primary"
                            dropdownClassName="date_picker_drp_dwn"
                            placeholder="Choose expiry date"
                            showToday={false}
                            format="DD-MM-YYYY"
                            suffixIcon={(
                              <Icon className="icon-16 primary" iconRef="#calendar" />
                      )}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10} />
                    </Row>
                    )}
                    <Divider className="m-0 border-top mb-3" />
                    <Typography.Paragraph className="mt-3 create_para">
                      Enter other vehicle details
                    </Typography.Paragraph>
                    <Row gutter={16}>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="vehicle number"
                          name="vehicle_no"
                          required={false}
                          normalize={Utility.trimSpacesInValues}
                        >
                          <Input placeholder="Enter vehicle number" className="inp_style" maxLength={10} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                        <Form.Item
                          label="chassis number"
                          name="chassis_no"
                          required={false}
                          normalize={Utility.trimSpacesInValues}
                        >
                          <Input placeholder="Enter chassis number" className="inp_style" maxLength={17} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Divider className="m-0 border-top" />
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0"
                >
                  <Col lg={4} md={24} sm={24} xs={24} />
                  <Col lg={20} md={24} sm={24} xs={24}>
                    <Checkbox
                      className="veh_park_chkbox_pad chkbox_style primary r-regular"
                      checked={isAddressPresent}
                      onChange={addressCheckboxHanlder}
                    >
                      Vehicle address
                    </Checkbox>
                  </Col>
                </Row>
                <Row
                  gutter={{
                    xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                  }}
                  className="m-0 mt-3"
                >
                  <Col md={24} lg={4}>
                    { isAddressPresent && (
                    <Typography.Title className="m-0" level={5}>
                      Address
                    </Typography.Title>
                    ) }
                  </Col>
                  <Col md={24} lg={20}>
                    { isAddressPresent && (
                    <Typography.Text className="m-0 create_para">
                      Manage vehicle address here
                    </Typography.Text>
                    ) }
                    { isAddressPresent && (
                    <>
                      <Row gutter={16} className="mt-3">
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="country" className="mandatory" name="country" rules={Rules.country}>
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose country"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                              onChange={countryChanged}
                            >
                              {countryData && Array.from(countryData?.keys())
                                ?.map((country:any) => <Option value={country}>{country}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="state" className="mandatory" name="state" rules={Rules.state}>
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose State"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                              onChange={stateChanged}
                            >
                              {selectedCountryName
                            && countryData?.get(selectedCountryName)?.keys()
                            && Array?.from(countryData?.get(selectedCountryName)?.keys())
                              ?.map((state:any) => <Option value={state}>{state}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="city" className="mandatory" name="city" rules={Rules.city}>
                            <Select
                              suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                              placeholder="Choose City"
                              className="slct_style"
                              dropdownClassName="slct_drp_dwn border-radius"
                            >
                              {selectedStateName
                            && countryData?.get(selectedCountryName)?.keys()
                            && countryData.get(selectedCountryName).get(selectedStateName)
                              ?.cities?.map((state:any) => <Option value={state}>{state}</Option>)}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="POST CODE" name="zip_code" normalize={Utility.trimSpacesInValues} rules={Rules.zip_code}>
                            <Input placeholder="Enter post code" className="inp_style" maxLength={15} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="Address Line 1" name="address_line_1" normalize={Utility.trimSpacesInValues} rules={Rules.addressLine1}>
                            <Input placeholder="Enter address line 1" className="inp_style" maxLength={50} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                          <Form.Item label="Address Line 2" name="address_line_2" normalize={Utility.trimSpacesInValues} rules={Rules.addressLine2}>
                            <Input placeholder="Enter address line 2" className="inp_style" maxLength={50} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider className="m-0 border-top" />
                    </>
                    )}
                    <div>
                      <div className="d-flex mt-4">
                        <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
                          {isVehicleUpdate ? 'Edit vehicle' : 'Add vehicle'}
                        </Button>
                        <Button className="cancel_btn border-0 primary shadow-none me-3 fs-16 r-bold" disabled={isLoading} onClick={gotoClientDetailsPage}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Card>
            <UploadDocument
              isModalVisible={isUploadVisible}
              handleModelCancel={closeUploadModal}
              uploadDocuments={uploadDocuments}
              uploadedDocuments={vehicleDocType === REGISTRATION ? regDocuments : InsDocuments}
              isLoading={isDocUploading}
            />
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography.Title className="text-uppercase r-medium r-side-info" level={5}>Info</Typography.Title>
            <Typography.Paragraph>{isVehicleUpdate ? 'See and manage the vehicle' : 'Create a new vehicle'}</Typography.Paragraph>
          </Sider>
        </Row>
        <CreateModel
          handleModelCancel={closeModelCreate}
          isModalVisible={isCreateModelVisible}
          handleOnFinish={onFinishModelCreate}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('masterStore', 'vehicleStore')(observer(CreateVehicle));
