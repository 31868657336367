/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import Constant from '../Global/Constant';
import api from './api';

class ContractService {
  getContracts = (order: string, orderBy: number,
    page: number, limit: number, contract_status?: string | Array<string>, keyword?: string|null,
    contract_request_status?: string) => {
    const query = queryString.stringify({
      order,
      orderBy,
      keyword,
      limit,
      page,
      contract_status,
      contract_request_status,
    }, { skipNull: true });
    const url = `/customer-contracts?${query}`;
    return api.get(url);
  }

 getContractById = async (id: string) => {
   try {
     const response = await api.get(`/customer-contracts/${id}`);
     return Promise.resolve(response);
   } catch (error: any) {
     return Promise.reject(error);
   }
 }

 createContract = async (contract: any) => {
   try {
     const response = await api.post('/customer-contracts', contract);
     return Promise.resolve(response);
   } catch (error: any) {
     return Promise.reject(error);
   }
 }

 updateContract = async (id: string|undefined, contract: any) => {
   try {
     const response = await api.put(`/customer-contracts/${id}`, contract);
     return Promise.resolve(response);
   } catch (error: any) {
     return Promise.reject(error);
   }
 }

 uploadScannedCopies = async (id: string|undefined, contract: any) => {
   try {
     const response = await api.put(`/customer-contracts/scanned-copies/${id}`, contract);
     return Promise.resolve(response);
   } catch (error: any) {
     return Promise.reject(error);
   }
 }

 getPlansCount = async (queryParams : any) => {
   try {
     const query = queryString.stringify(queryParams, { skipNull: true });
     const response = await api.get(`/customer-contracts/count?${query}`);
     return Promise.resolve(response);
   } catch (error: any) {
     return Promise.reject(error);
   }
 }

 getContractActivities = async (queryParams : any) => {
   try {
     const query = queryString.stringify(queryParams, { skipNull: true });
     const response = await api.get(`/customer-contracts/activities?${query}`);
     return Promise.resolve(response);
   } catch (error: any) {
     return Promise.reject(error);
   }
 }

  handleOverDue = async (id: string, data: any) => {
    try {
      const response = await api.post(`/customer-contracts/over-due/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  renewContract = async (id: string, data:any) => {
    try {
      const response = await api.post(`/customer-contracts/renew/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  makePayment = async (id: string, data:any) => {
    try {
      const response = await api.post(`/customer-contracts/purchase/${id}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  downloadInvoice = async (id: any) => {
    try {
      const response = await api.get(`/customer-contracts/invoice/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  addVehicleToPlan = async (data: any) => {
    try {
      const response = await api.put('/customer-contracts/swap-vehicle', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  closePlan = async (planId: any) => {
    try {
      const response = await api.put(`/customer-contracts/change-status/${planId}`, { action: 'CLOSED' });
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new ContractService();
