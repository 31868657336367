/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Col, Layout, Row, Skeleton, Table, Tooltip, Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import './EmployeesListing.less';
import moment from 'moment';
import classNames from 'classnames';
import {
  Icon, NoData, Pagination, Search,
} from '../../Components/index';
import { Employee } from '../../Global/interface';
import Constant from '../../Global/Constant';
import SortTh from '../../Components/SortTh';
import Toast from '../../Components/Toast';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';
import Status from './Status';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function EmployeesListing(props: any) {
  const { userStore } = props;
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<any>(-1);
  const [order, setOrder] = useState<any>('emp_id');
  const [collapsed, setCollapsed] = useState(false);

  const columns: any = [
    {
      title: <SortTh title="Employee ID" dataIndex="emp_id" order={order} orderBy={orderBy} />,
      dataIndex: 'emp_id',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: <SortTh title="Employee Name" dataIndex="first_name" order={order} orderBy={orderBy} />,
      dataIndex: 'first_name',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      ellipsis: true,
    },
    {
      title: <SortTh title="Joined Date" dataIndex="createdAt" order={order} orderBy={orderBy} />,
      dataIndex: 'createdAt',
      onHeaderCell: (column: any) => ({
        onClick: () => sortTable(column?.dataIndex),
      }),
      ellipsis: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      ellipsis: true,
    },
    {
      title: 'Last Activity',
      dataIndex: 'last_activity',
      ellipsis: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const sortTable = (dataIndex: any) => {
    let sortBy = -1;
    if (orderBy === -1 && dataIndex === order) sortBy = 1;
    else if (dataIndex !== order) setOrder(dataIndex);
    setOrderBy(sortBy);
    fetchEmployees(dataIndex, sortBy);
  };

  const fetchEmployees = async (dataIndex: any, sortBy: any) => {
    setIsLoading(true);
    userStore.getUsers(
      keyword, pageNumber, Constant.employeePerPage, dataIndex, sortBy, true,
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch((error:any) => {
        setIsLoading(false);
        Toast.error(error);
      });
  };

  useEffect(() => { fetchEmployees(order, orderBy); }, [pageNumber, keyword]);
  const { total, users } = userStore;
  const employeesTableData = users?.map((employee: Employee) => ({
    emp_id: employee?.emp_id,
    first_name: `${employee?.first_name} ${employee?.middle_name || ''} ${employee?.last_name}`,
    createdAt: moment(employee?.createdAt).format('DD/MM/YYYY'),
    role: employee?.role?.role,
    phone_number: employee?.phone,
    last_activity: Utility.getRelativeDate(employee?.contractActivities?.createdAt) || '-',
    status: employee?.active ? <Status statusClassName="label_success" status="Active" />
      : <Status statusClassName="label_warning" status="Pending sign up" />,
    rowKey: employee?._id,
    key: employee?._id,
  }));

  const goToEmployeeDetails = (record: any) => { history.push(`${Routes.employees_detail}/${record?.rowKey}`); };

  const handleSearchTextChange = (event: any) => {
    setKeyword(event.target.value);
    setPageNumber(Constant.defaultPageNumber);
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="employees" />
    ),
  };

  const goToEmployeeCreate = () => history.push('/create_employee');
  return (

    <Layout.Content className="listing_wrapper employee_listing">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <Typography.Title className="m-0 header_main mb-2 pb-3 fs-xs-24 d-flex align-items-center">
            Employees
            <Tooltip title="Create Employee">
              <Button className="d-flex justify-content-center align-items-center text-center p-0 icon_circle ms-3" type="link" shape="circle" onClick={goToEmployeeCreate}>
                <Icon className="icon-13" iconRef="#add" />
              </Button>
            </Tooltip>
          </Typography.Title>
          <div className="d-flex justify-content-between align-items-sm-center mb-3">
            <div className="d-sm-flex align-items-center flex-fill">
              <h4 className="mb-0 table-header r-bold">All Employees</h4>
              <Search
                value={keyword}
                placeholder="Search"
                titleToolTip="Search Employee"
                size="small"
                onChange={handleSearchTextChange}
              />
            </div>
          </div>
          <Table
            onRow={(record) => ({ onClick: () => goToEmployeeDetails(record) })}
            className="emp_table_wrap table_wrapper"
            rowClassName="cursor-pointer"
            columns={columns}
            dataSource={employeesTableData}
            pagination={false}
            size="small"
            scroll={{ x: '800px' }}
            locale={locale}
          />
          <Pagination
            total={total}
            pageSize={Constant.employeePerPage}
            currentPage={Number(pageNumber)}
            onChange={(page: number) => setPageNumber(page)}
          />
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See and manage the employees</Paragraph>
          </Typography>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('userStore')(observer(EmployeesListing));
