/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Divider,
  Radio,
  Row,
  Typography,
} from 'antd';
import { Action, Icon } from '../../Components';
import Utility from '../../Global/Utility';
import Constant from '../../Global/Constant';

interface Props {
  statusName: string
  IconRef: any
  attachmentStatus: any
  request?: any
  updateServiceRequest: (id: string, action: any) => void
  updateVehicleStatus: (statusData: any, id?: string) => void,
}

VehicleAttachment.defaultProps = {
  request: null,
};

const { OPEN } = Constant.serviceRequestStatus;
const { START, COMPLETE } = Constant.serviceRequestActions;

function VehicleAttachment(props: Props) {
  const {
    statusName, IconRef, attachmentStatus, request, updateVehicleStatus,
    updateServiceRequest,
  } = props;

  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const [isAttached, setIsAttached] = useState(
    attachmentStatus?.value || Constant.defaultAttachment,
  );
  const isRequestStarted = request?.status === OPEN;

  const onConfirm = () => {
    setIsStatusChecked(false);
    updateVehicleStatus(isAttached);
  };

  const onCancel = () => {
    setIsStatusChecked(false);
    setIsAttached(attachmentStatus?.value || Constant.defaultAttachment);
  };

  const radioBtnHandler = () => {
    setIsStatusChecked(!isStatusChecked);
    setIsAttached(!isAttached);
  };

  const onClickHandler = () => updateServiceRequest(request?._id, { action: START });

  const onFinishHandler = () => {
    updateVehicleStatus(isAttached, request?._id);
    updateServiceRequest(request?._id, { action: COMPLETE });
  };

  useEffect(() => {
    setIsAttached(attachmentStatus?.value || Constant.defaultAttachment);
  }, [attachmentStatus?.value]);
  return (
    <div className="last_brd_0 border-bottom pb-3 mb-4">
      { request && (
      <div>
        <Row gutter={16}>
          <Col lg={9} md={9} sm={9} xs={24}>
            <div className="d-flex align-items-center fs-16">
              <div className="icn_bg_44 white rounded-circle d-flex align-items-center justify-content-center me-3 primary_bg">
                <Icon className="icon-24" iconRef={IconRef} />
              </div>
              <div className="lh-sm r-bold fs-16">
                <Typography.Text className="r-medium fs-14 secondary text-uppercase">Request</Typography.Text><br />
                {statusName}
              </div>
            </div>
          </Col>
          <Col lg={15} md={15} sm={15} xs={24}>
            <Row>
              <Col lg={15} md={15} sm={15} xs={24}>
                <div className="d-flex justify-content-between justify-content-sm-start mt-3 mt-sm-0 mb-3 mb-sm-0">
                  <div className="me-3 r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Date</Typography.Text><br />
                    {Utility?.getFormattedDate(request?.scheduled_time)}
                  </div>
                  <div className="r-bold fs-16 lh-sm">
                    <Typography.Text className="r-medium fs-14 secondary text-uppercase">Time</Typography.Text><br />
                    {Utility?.getFormattedTime(request?.scheduled_time)}
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={9} xs={24} className="d-flex justify-content-sm-end align-items-start">
                {isRequestStarted && (
                <Button className="fs-16 green_bg white border-radius border-0 pt-1 pb-1 start_btn_resp r-bold h-40" onClick={onClickHandler}>
                  Start Task
                </Button>
                ) }
                {!isRequestStarted && (<div className="label_warning rounded-pill pt-1 pb-1">In Progress </div>)}
              </Col>
            </Row>
            <Divider className="mt-3 mb-3 border-bottom border-xs-bottom" />
          </Col>
        </Row>
      </div>
      ) }
      <Row gutter={16}>
        <Col lg={9} md={9} sm={9} xs={24}>
          { !request
          && (
          <div className="d-flex align-items-center fs-16 mb-2 mb-sm-0">
            <div className="icn_bg_44 white rounded-circle d-flex align-items-center justify-content-center me-3 primary_bg">
              <Icon className="icon-24" iconRef={IconRef} />
            </div>
            {statusName}
          </div>
          ) }
        </Col>
        <Col lg={9} md={9} sm={9} xs={24}>
          <Typography.Text className="secondary r-medium text-uppercase d-block mb-2">Status</Typography.Text>
          <Radio.Group
            className="cust_radio_btn d-sm-flex"
            disabled={isRequestStarted}
            onChange={radioBtnHandler}
            value={isAttached}
          >
            <Radio className="radio_inp" value>Attached</Radio>
            <Radio className="radio_inp" value={false}>Detached</Radio>
          </Radio.Group>
          {attachmentStatus?.last_updated_time && <Typography.Paragraph className="fs-16 mt-2 mb-0 inactive">Last updated - {Utility.getFormattedDate(attachmentStatus?.last_updated_time)}</Typography.Paragraph>}
          { request && !isRequestStarted
        && (
        <Button
          className="yellow_bg white h-40 border-radius r-bold ps-3 pe-3 border-0 fs-16 mt-3"
          disabled={!isStatusChecked}
          onClick={onFinishHandler}
        >Finish Task
        </Button>
        )}
        </Col>
        { !request && isStatusChecked && (
          <Col
            lg={5}
            className="d-flex justify-content-end
                  align-items-start mt-3 mt-sm-0"
          ><Action onAccept={onConfirm} onReject={onCancel} />
          </Col>
        )}
      </Row>
    </div>
  );
}
export default VehicleAttachment;
