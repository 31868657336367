/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import { serviceRequestParams } from '../Global/interface';
import api from './api';

class ServiceRequest {
  getServices = () => {
    const url = '/services/';
    return api.get(url);
  }

  getRequests = (queryParams: serviceRequestParams) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/services/request?${query}`;
    return api.get(url);
  }

  getServiceReqById = async (id: String) => {
    try {
      const response = await api.get(`/services/request/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateServiceRequest = async (requestId: string, data : any) => {
    try {
      const response = await api.put(`/services/request/${requestId}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }

  sendPaymentLink = async (requestId: string, data : any) => {
    try {
      const response = await api.post(`/services/send-payment-link/${requestId}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }
}

export default new ServiceRequest();
