/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Layout,
  Form,
  Input,
  Button,
  Tooltip,
} from 'antd';
import { Footer } from 'antd/lib/layout/layout';

import { inject, observer } from 'mobx-react';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';
import authStore from '../../Store/authStore';
import userStore from '../../Store/userStore';
import './Login.less';
import Toast from '../../Components/Toast';
import Utility from '../../Global/Utility';
import { Icon } from '../../Components';
import rule from './login.validation';
import { ITenantStore } from '../../Store/tenantStore';

interface ILogin {
  tenantStore: ITenantStore
}

function Login(props: ILogin) {
  const { tenantStore } = props;

  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);

  const loginHandler = (values: any) => {
    const { email, password } = values;
    setLoading(true);
    authStore.signIn(email, password, (err: any) => {
      setLoading(false);
      if (err) {
        Toast.error(err);
        return;
      }
      if (authStore?.currentUser?.id) {
        userStore?.getLoggedUser(authStore?.currentUser?.id);
      }
      history.push(Routes.dashboard);
    });
  };

  const validateEmailField = () => form.validateFields(['email']).then(() => setShowPasswordField(true));

  return (
    <Layout.Content id="login-container" className="d-sm-flex align-items-center">
      <div className="login-box w-100">
        <div className="text-center mb-5">
          <img src={tenantStore?.tenantLogo} style={{ marginBottom: '20px' }} width="80px" alt={tenantStore?.tenantName} />
          <h4 className="mb-5 r-regular">Portal Login</h4>
        </div>
        <div className="">
          <Form
            layout="vertical"
            className="login-form"
            form={form}
            onFinish={loginHandler}
            validateTrigger={['onBlur', 'onSubmit', 'onChange']}
          >
            <div className="position-relative">
              <Form.Item
                label="EMAIL"
                className="mb-2"
                name="email"
                normalize={Utility.trimSpacesInValues}
                rules={rule.email}
              >
                <Input
                  placeholder="Enter your email"
                  className={showPasswordField ? 'rem_pad email_input login_input' : 'email_input login_input'}
                  allowClear
                  onKeyPress={(event: any) => {
                    if (event.which === 13) {
                      event.preventDefault();
                      validateEmailField();
                    }
                  }}
                />
              </Form.Item>
              <div className="position-absolute email_icons d-flex">
                {!showPasswordField && (
                  <Tooltip title="Submit">
                    <Button
                      className="login_arw d-flex align-items-center justify-content-center text-center p-0 email_arw_icn"
                      type="primary"
                      shape="circle"
                      onClick={() => validateEmailField()}
                    >
                      <Icon className="icon-10" iconRef="#arrow_right" />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            {showPasswordField && (
            <div className="position-relative">
              <Form.Item
                label="PASSWORD"
                name="password"
                className="mb-2"
                normalize={Utility.trimSpacesInValues}
                rules={rule.password}
              >
                <Input.Password
                  placeholder="Enter your password"
                  maxLength={Constant.passwordLength}
                  className="pe-5 login_input"
                />
              </Form.Item>

              <Tooltip title="Login">
                <Button
                  className="login_arw d-flex align-items-center justify-content-center text-center p-0 position-absolute pswd_arw"
                  type="primary"
                  shape="circle"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  <Icon className="icon-10" iconRef="#arrow_right" />
                </Button>
              </Tooltip>
            </div>
            )}
            <Form.Item className="forg_pass">
              <Link
                to={Routes.forgotPassword}
                className="d-flex ml-auto mb-3 login-form-forgot r-bold align-items-center"
              >
                Forgot Password?
                <div className="forgot_arw text-white ms-2 d-flex justify-content-center align-items-center text-center p-0 rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Footer className="text-center">
        VMS Portal • Copyright © 2022 • <a href="/#">Terms and Conditions</a> •{' '}
        <a href="/">Privacy Policy</a>
      </Footer>
    </Layout.Content>
  );
}

export default inject('tenantStore')(observer(Login));
