/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Layout,
  Progress,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';

import { AxiosError } from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import classnames from 'classnames';
import {
  Icon,
  UploadDocument,
  ListDocument,
  VehicleStatusCard,
  VehicleInfoCard,
  ContractActivities,
  Pagination,
  VehicleSelector,
  Toast,
} from '../../Components';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import './ContractDetails.less';
import fileService from '../../Services/fileService';
import contractService from '../../Services/contractService';
import clientStore from '../../Store/clientStore';
import ConfirmModal from '../../Components/ConfirmModal';

const { activity_type } = Constant;
const { CLOSED } = Constant.customerContract;
const { Title, Paragraph } = Typography;
const { Sider } = Layout;
const { EXPIRED } = Constant.contractTypes;

function ContractDetails(props: any) {
  const { contractStore, authStore, settingsStore } = props;
  const { currentUser } = authStore;
  const { settings } = settingsStore;

  const isSuperAdmin = currentUser?.access?.includes(Constant?.userAccess.SUPER_ADMIN);
  const contractDetails = contractStore?.contract;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [showAddVehicleModel, setShowAddVehicleModel] = useState<boolean>(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [isDocUploading, setIsDocUploading] = useState(false);
  const { id } = useParams<any>();
  const history = useHistory();
  const start_date = contractDetails?.start_date;
  const due_date = contractDetails?.due_date;
  const payment_status = contractDetails?.payment_status;
  const contract_status = contractDetails?.contract_status;
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState(-1);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const defaultPlanType = queryParams?.tab || EXPIRED;
  const daysInMilliSeconds = 86400000;

  const handleCancel = () => setIsModalVisible(false);
  const showModal = () => setIsModalVisible(true);

  const removeFileFromUploaded = async (index:number) => {
    try {
      const uploadedDoc = uploadedDocuments.filter(
        (value : any, actualIndex:number) => actualIndex !== index,
      );
      setUploadedDocuments(uploadedDoc);
      await contractService.uploadScannedCopies(id, { scanned_copies: uploadedDoc });
    } catch (error:any) {
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const uploadDocuments = async (selectedDocuments: any) => {
    try {
      setIsDocUploading(true);
      const fileFormData = new FormData();
      selectedDocuments.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
      const response = await fileService.uploadFile(fileFormData);
      setUploadedDocuments([...uploadedDocuments, ...response?.data]);
      await contractService.uploadScannedCopies(
        id,
        { scanned_copies: [...uploadedDocuments, ...response?.data] },
      );
      setIsModalVisible(false);
      setIsDocUploading(false);
    } catch (error:any) {
      setIsModalVisible(false);
      setIsDocUploading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const getContractById = async () => {
    setIsLoading(true);
    await contractStore.getContractById(id)
      .then(() => {
        setIsLoading(false);
        setUploadedDocuments(contractStore?.contract?.scanned_copies);
      })
      .catch((error:AxiosError) => {
        setIsLoading(false);
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);
      await settingsStore.getSettings();
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      Toast.error(err);
    }
  };
  const isExpiringContract = due_date - Date.now()
  <= settings?.plan_expiring_time * daysInMilliSeconds;
  const isPlanClosed = contractDetails?.contract_status === CLOSED;

  const getContractStatus = (
    dueDate: any, paymentStatus: boolean|undefined, contractStatus: String|undefined,
  ) => {
    if (paymentStatus) {
      return dueDate > moment(Date.now())
        ? Constant?.customerContract?.ACTIVE : Constant?.customerContract?.EXPIRED;
    }
    return contractStatus;
  };

  const getContractActivities = async (sort: number) => {
    setIsLoading(true);
    await contractStore.getContractActivities({
      activity_type: [activity_type.SERVICE_ACTIVITY, activity_type.PARKING_UNPARKING],
      contract: id,
      page: pageNumber,
      order: 'createdAt',
      orderBy: sort,
      limit: Constant.activityPerPage,
    })
      .then(() => setIsLoading(false))
      .catch((error:AxiosError) => {
        setIsLoading(false);
        Toast.error(error?.response?.data?.error?.message);
      });
  };

  const sortActivity = () => {
    const nextSort = orderBy === -1 ? 1 : -1;
    setOrderBy(nextSort);
    getContractActivities(nextSort);
  };

  useEffect(() => { getContractActivities(orderBy); }, [pageNumber]);

  const getClientVehicles = async () => {
    try {
      setIsLoading(true);
      await clientStore.getClientVehicle({
        clientId: contractDetails?.client?._id, sharedVehicle: false,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(true);
      Toast.error(error);
    }
  };

  useEffect(() => {
    if (contractDetails?.client?._id) {
      getClientVehicles();
    }
  }, [contractDetails?.client?._id]);

  useEffect(() => {
    getSettings();
    getContractById();
    getContractActivities(orderBy);
  }, []);

  const contractStatus = getContractStatus(due_date, payment_status, contract_status);
  const percentage = Utility.calculateDatePercentage(start_date, due_date, isPlanClosed);
  const percentColor = Utility.getPercentageColor(percentage);
  const isUploadDisabled = uploadedDocuments?.length >= 2;

  const isPaymentPending = !contractDetails?.payment_status
  && contractStatus === Constant.customerContract.APPROVED && !isPlanClosed && !isExpiringContract;

  const gotoCLient = () => history.push(`${Routes.client_detail}/${contractDetails?.client?._id}`);
  const gotoRenewPage = () => history.push(`${Routes.renew_plan}/${contractDetails?._id}?tab=${defaultPlanType}`);
  const gotoPaymentPage = () => history.push(`${Routes.payment_plan}/${contractDetails?._id}?newPlan=true&tab=${defaultPlanType}`);

  const isResidentialPlan = contractDetails?.contract?.plan_type === 'RESIDENTIAL';
  const showVehicleCard = !isPlanClosed && contractDetails?.active_vehicle;
  const isPlanExpired = contractStatus === Constant.customerContract.EXPIRED;
  const addVehicle = !isPlanClosed && !isPlanExpired
  && !contractDetails?.active_vehicle
  && isResidentialPlan;
  const expiredText = isPlanExpired ? 'Expired on' : 'Expire on';
  // plan can be closed if plan is expired and (plan is vault and no vehicle or plan is residential)
  const planCloseable = (isPlanExpired && !isPlanClosed)
  && ((!isResidentialPlan && !showVehicleCard) || isResidentialPlan) && isSuperAdmin;

  const vehicleAddress = contractDetails?.contract?.plan_type === Constant.plan_type?.VAULT
    ? null : contractDetails?.active_vehicle?.address;

  const deletedUser = contractDetails?.client?.deleted;

  const showAddVehicle = () => setShowAddVehicleModel(true);
  const closeAddVehicle = () => setShowAddVehicleModel(false);
  const addVehicleToPlan = async (value: any) => {
    try {
      setIsLoading(true);
      await contractStore.addVehicleToPlan({
        client: contractDetails?.client?._id, vehicle: value?.vehicle, planId: contractDetails?._id,
      });
      setIsLoading(false);
      setShowAddVehicleModel(false);
      getContractById();
      getContractActivities(orderBy);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const closePlan = async () => {
    try {
      setIsLoading(true);
      await contractService.closePlan(id);
      getContractById();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const closePlanHandler = () => {
    let confirmModelText = '';
    if (contractDetails?.active_vehicle) {
      confirmModelText = 'Vehicle in this plan will be removed, Are you sure you want to close this plan?';
    } else {
      confirmModelText = 'Are you sure you want to close this plan?';
    }
    ConfirmModal('Close plan', confirmModelText, () => closePlan(), 'Close plan');
  };

  const clientName = `${contractDetails?.client?.first_name} ${contractDetails?.client?.middle_name || ''} ${contractDetails?.client?.last_name}`;

  return (
    <Layout.Content className="detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classnames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            {isPlanClosed
            && (
            <Button
              className="d-flex ml-auto mb-3 r-bold align-items-center back_link p-0 prim primary"
              type="link"
              onClick={() => history.push(Routes.plan_history)}
            >
              <div
                className="me-2 d-flex justify-content-center align-items-center text-center small_icon rounded-circle"
              >
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Button>
            )}
            {!isPlanClosed && (
            <Breadcrumb className="text-uppercase m-0 breadcrumb mb-2 pb-3">
              <Breadcrumb.Item>
                <Link to={`${Routes.contracts}?tab=${defaultPlanType}`}>Plans</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{contractDetails?.contract_code}</Breadcrumb.Item>
            </Breadcrumb>
            ) }
            <div className="mb-3">
              {isPlanClosed && (
              <div className="d-flex align-items-center w-100 mb-3">
                <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                  {contractDetails?.contract_code}
                </Typography.Title>
                <div className="label_inactive rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">
                  Closed
                </div>
              </div>
              )}
              {!isPlanClosed && (
              <div className="d-flex align-items-center w-100 mb-3">
                <Typography.Title className="text-uppercase mb-0 fs-xs-24">
                  {contractDetails?.contract_code}
                </Typography.Title>
                {contractStatus === Constant?.customerContract?.ACTIVE
                      && <div className="label_success rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Active</div>}
                {contractStatus === Constant.customerContract.EXPIRED
                     && <div className="label_danger rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Expired</div>}
                {contractStatus === Constant.customerContract.APPROVED
                     && <div className="label_success rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Payment Pending</div>}
                {contractStatus === Constant.customerContract.REJECTED
                     && <div className="label_warning rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Rejected</div>}
                {contractStatus === Constant.customerContract.REQUESTED
                     && <div className="label_warning rounded-pill ps-2 pe-2 d-flex align-items-center justify-content-center fs-16 ms-3">Requested</div>}
              </div>
              )}
              <Row gutter={[16, 16]}>
                <Col lg={16} xs={24}>
                  <Card className="h-100">
                    <div className="position-relative">
                      {contractDetails?.duration
                      && (
                      <div className={classnames('plan_info_img exo_fnt position-absolute position-xs-relative d-flex align-items-center justify-content-start ps-2', { 'fs-60': (contractDetails?.duration / 30 >= 12) })}>
                        { contractDetails?.duration / 30 }<sub className="r-medium secondary fs-16 roboto position-absolute">Month</sub>
                      </div>
                      )}
                      <div className="plan_info_pad ps_xs_0 pt-3 pt-sm-0">
                        {contractDetails?.contract?.plan
                        && (
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <Typography.Title level={3} className="mb-0 fs-xs-20">
                            {`${contractDetails?.contract?.plan} Plan`}
                          </Typography.Title>
                          { (!isPlanClosed && isExpiringContract) && isSuperAdmin
                          && !deletedUser && (
                          <Button className="d-flex ml-auto p-0 r-bold align-items-center primary border-0 shadow-none" onClick={gotoRenewPage}>
                            Renew
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                          )}
                          { isPaymentPending && isSuperAdmin && (
                          <Button className="d-flex ml-auto p-0 r-bold align-items-center primary border-0 shadow-none" onClick={gotoPaymentPage}>
                            Make payment
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                          )}

                        </div>
                        )}
                        {contractDetails?.payment_status
                  && (
                  <div>
                    <Progress
                      className="contract_progress"
                      percent={percentage}
                      strokeColor={percentColor}
                      trailColor="#f2f2f2"
                      strokeWidth={24}
                      showInfo={false}
                    />
                      { !isPlanClosed && (
                      <div className="d-sm-flex justify-content-sm-between mt-3">
                        <Typography.Paragraph
                          className="secondary fs-16 mb-0 text-truncate me-2"
                          title={`${expiredText} ${Utility.getFormattedDate(contractDetails?.due_date)}`}
                        >{expiredText} {Utility.getFormattedDate(contractDetails?.due_date)}
                        </Typography.Paragraph>
                        <Typography.Paragraph
                          className="secondary fs-16 mb-0 text-sm-end text-truncate"
                          title={`Purchased on ${Utility.getFormattedDate(contractDetails?.start_date)}`}
                        >Purchased on {Utility.getFormattedDate(contractDetails?.start_date)}
                        </Typography.Paragraph>
                      </div>
                      )}
                      { isPlanClosed && (
                      <div className="d-flex justify-content-between mt-3">
                        <Typography.Paragraph className="secondary fs-16 mb-0 text-truncate me-2" title={`Closed on ${Utility.getFormattedDate(contractDetails?.updatedAt)}`}>
                          Closed on {Utility.getFormattedDate(contractDetails?.updatedAt)}
                        </Typography.Paragraph>
                      </div>
                      )}
                  </div>
                  )}
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={8} xs={24}>
                  <Card>
                    <div className="d-sm-flex align-items-center position-relative">
                      <div className="large_icn_bg p-0 ms-sm-0 m-auto me-sm-0 rounded-circle justify-content-center d-flex align-items-center sec_info_img position-absolute position-xs-relative">
                        {contractDetails?.client?.profile?.fileUrl
                          ? <img className="large_icn_bg rounded-circle align-items-center position-absolute" src={contractDetails?.client?.profile?.fileUrl} alt="" />
                          : <Icon className="icon-40" iconRef="#User" />}

                      </div>
                      {contractDetails?.client
                      && (
                      <div className="sec_info_pad d-flex align-items-center w-100 lg_icon_info_ht ps_xs_0 text-center text-sm-start">
                        <Typography className="w-100">
                          <Title
                            level={3}
                            className="text-truncate text-capitalize primary r-bold mb-0"
                            title={clientName}
                          >
                            {clientName}
                          </Title>
                          <Paragraph className="secondary mb-0">Client ID - {contractDetails?.client?.client_code}</Paragraph>
                        </Typography>
                      </div>
                      )}
                    </div>
                    <div className="sec_info_pad ps_xs_0 d-flex align-items-center justify-content-center justify-content-sm-start">
                      <Button disabled={deletedUser} className="d-flex p-0 r-bold align-items-center primary border-0 shadow-none bg-transparent" onClick={gotoCLient}>
                        View
                        {!deletedUser && (
                        <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                          <Icon className="icon-8" iconRef="#arrow_right" />
                        </div>
                        )}
                      </Button>
                      {deletedUser && (
                        <Tooltip title="User deleted">
                          <div className="lh_0">
                            <Icon className="icon-16 ms-2" iconRef="#info" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </Card>
                </Col>
                <Col lg={24} xs={24}>

                  <Row gutter={16}>
                    <Col lg={16} xs={24}>
                      { showVehicleCard && (
                      <div className="mb-3">
                        <VehicleStatusCard
                          vehicleId={contractDetails?.active_vehicle?._id}
                          vehicle={contractDetails?.active_vehicle}
                          facility={contractDetails?.facility}
                          clientEmail={contractDetails?.client?.email}
                          clientPhone={contractDetails?.client?.phone}
                          contract={contractDetails}
                        />
                      </div>
                      )}
                      {addVehicle
                      && (
                        <div className="mb-3">
                          <Card className="info_ht">
                            <div className="car_image d-flex justify-content-center m-auto">
                              <img className="img-fluid" src={Utility.defaultModalImage('')} alt="vehicleimage" />

                            </div>
                            <div className="row d-flex mt-4">
                              <div className="d-flex justify-content-center">
                                Empty parking lot, Click to add vehicle

                              </div>
                              <div className="d-flex justify-content-center mt-3">

                                <Button className="main_btn r-bold fs-16" onClick={showAddVehicle}>Add vehicle</Button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                      <div>
                        <ContractActivities
                          activities={contractStore?.activities}
                          isLoading={isLoading}
                          sortActivity={sortActivity}
                          orderBy={orderBy}
                        />
                        <Pagination
                          total={contractStore?.activityTotal}
                          pageSize={Constant.activityPerPage}
                          currentPage={Number(pageNumber)}
                          onChange={(page: number) => setPageNumber(page)}
                        />
                      </div>
                    </Col>
                    <Col lg={8} xs={24}>
                      { showVehicleCard && (
                        <div className="mb-3">
                          <VehicleInfoCard
                            vehicle={contractDetails?.active_vehicle}
                            location={vehicleAddress}
                          />
                        </div>
                      )}
                      <Card bordered={false} className="detail_card_pad">
                        <div className="d-flex justify-content-between text-secondary">
                          <Typography.Title level={4}>Scanned Copies<br /> <span className="secondary fs-14">(Maximum 2 files)</span></Typography.Title>
                          { !isPlanClosed && (
                          <Button className="d-flex ml-auto p-0 r-bold align-items-center back_link primary" type="link" disabled={isUploadDisabled} onClick={showModal}>
                            Upload
                            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                              <Icon className="icon-8" iconRef="#arrow_right" />
                            </div>
                          </Button>
                          )}
                        </div>
                        { uploadedDocuments?.length > 0
                    && (
                    <ListDocument
                      documents={uploadedDocuments}
                      size={24}
                      removeFile={(index: number) => removeFileFromUploaded(index)}
                    />
                    )}
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage the plans</Paragraph>
            </Typography>
            {planCloseable
            && (
            <div className="disabled_btn">
              <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Action</h6>
              <Tooltip title="Close Plan">
                <Button onClick={closePlanHandler} className="d-flex primary ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0">
                  Close Plan
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Button>
              </Tooltip>
            </div>
            )}
          </Sider>
        </Row>
        <UploadDocument
          fileMaxCount={2}
          isModalVisible={isModalVisible}
          handleModelCancel={handleCancel}
          uploadDocuments={uploadDocuments}
          uploadedDocuments={uploadedDocuments}
          isLoading={isDocUploading}
          acceptedExtension=".pdf,.doc,.jpeg,.jpg,.png"
        />
        <VehicleSelector
          isLoading={isLoading}
          isModalVisible={showAddVehicleModel}
          vehicleList={clientStore?.vehicles?.data}
          handleCancel={closeAddVehicle}
          handleOk={addVehicleToPlan}
        />
      </Spin>
    </Layout.Content>
  );
}

export default inject('contractStore', 'authStore', 'settingsStore')(observer(ContractDetails));
