/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
  Col,
  Layout,
  Row,
  Spin,
  Typography,
} from 'antd';
import { Link as Route, useHistory } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import classNames from 'classnames';
import debounce from 'lodash.debounce';

import vehicleStore from '../../Store/vehicleStore';
import Toast from '../../Components/Toast';
import Constant from '../../Global/Constant';
import {
  Pagination, Icon, NoData, Search, ModelCard,
} from '../../Components';
import Routes from '../../Global/Routes';
import { Category, Model, ModelArray } from '../../Global/interface';

import './ModelHistory.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function ModelHistory(props: any) {
  const { modelStore } = props;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const fetchModels = async (page: number,
    limit: number, category: string, keyword?: string) => {
    setIsLoading(true);
    try {
      await modelStore.getModelListHistory(true, page, limit, category, keyword);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const fetchModelData = async () => {
    setIsLoading(true);
    try {
      const categories: any = await vehicleStore.getVehicleCategory();
      const list = categories.map((item: Category) => item?.category);
      await modelStore.getModelHistory(list);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const debounceHandler = useCallback(
    debounce((nextValue: string, category: string) => {
      fetchModels(Constant.defaultPageNumber, Constant.modelPerPage, category, nextValue);
    }, Constant.debaounceTime),
    [],
  );

  const handleSearchTextChange = (event: any, category: string) => {
    const { value } = event.target;
    debounceHandler(value, category);
  };

  const getModelData = (page: number, model: ModelArray) => fetchModels(
    page, Constant.modelPerPage, model?.category, model?.keyword,
  );

  const goToModelDetails = (id: any) => history.push(`${Routes.model_detail}/${id}?deleted=0`);

  useEffect(() => { fetchModelData(); }, []);
  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <Route className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content primary" to={Routes.models}>
              <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                <Icon className="icon-8" iconRef="#arrow_left" />
              </div>
              Back
            </Route>
            <Typography.Title className="m-0 header_main mb-2 pb-3 d-flex align-items-center fs-xs-24">
              Models History
            </Typography.Title>
            <div className="vehicle_wrapper">
              {modelStore?.modelsHistory?.length !== 0
              && (modelStore?.modelsHistory).map((model: any) => (
                model?.category && (
                <Element name={model?.category} className="border-bottom mb-4 pb-4 model_brd_btm" key={model?.category}>
                  <div id={model?.category}>
                    <div className="d-sm-flex align-items-center mb-3">
                      <h4 className="mb-0 table-header r-bold text-capitalize">{model?.category}</h4>
                      <Search
                        placeholder="Search"
                        titleToolTip={`Search ${model?.category}`}
                        size="small"
                        onChange={(event) => handleSearchTextChange(event, model?.category)}
                      />
                    </div>
                    <div className="d-flex flex-wrap">
                      { model?.data?.length !== 0 ? model?.data?.map((item: Model) => (
                        <ModelCard
                          category={model?.category}
                          color={item?.color}
                          company={item?.company}
                          image={item?.image}
                          model={item?.model}
                          updatedAt={item?.updatedAt}
                          onClick={() => goToModelDetails(item?._id)}
                          dateText="Deleted - "
                          key={item?._id}
                        />
                      )) : (
                        <Card>
                          <NoData subTitle={model?.category} />
                        </Card>
                      )}
                    </div>
                    <Pagination
                      total={model?.total}
                      pageSize={Constant.modelPerPage}
                      currentPage={model?.pageNumber}
                      onChange={(page: number) => getModelData(page, model)}
                    />
                  </div>
                </Element>
                )
              ))}
              {modelStore?.modelsHistory?.length === 0 && !isLoading && (<NoData subTitle="models" />)}
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See the models history</Paragraph>
            </Typography>
            { modelStore?.modelsHistory?.length !== 0
            && (
              <div className="d-none d-xl-block">
                <h6 className="text-uppercase r-medium r-side-info mt-5">Sections</h6>
                <div className="v_tab d-flex flex-column">
                  { modelStore?.modelsHistory?.map((model: any) => (
                    <Link
                      to={model?.category}
                      activeClass="tab_active"
                      offset={-100}
                      className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
                      spy
                      key={model?.category}
                    >
                      {model?.category}
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('modelStore')(observer(ModelHistory));
