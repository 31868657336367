/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, makeObservable, runInAction } from 'mobx';
import Constant from '../Global/Constant';
import masterService from '../Services/materService';
import facilityService from '../Services/facilityService';
import modelService from '../Services/modelService';
import { ModelQueryParams } from '../Global/interface';
import countryService from '../Services/countryService';

export interface MasterStoreProps {
  vaultContractPlans: any,
  vehicleCategories: any,
  facilities: any,
  models: any,
  countries: any,
  getContractPlans: (contractType? : string) => void,
  getVehicleCategories: () => any,
  getFacilities: (
    keyword?: string | null, pageNumber?: number, count?: number, order?: string, orderBy?: number
    ) => void
  getModels: (queryParams : ModelQueryParams) => any,
  getCountryData: () => any
}

class MasterStore {
    vaultContractPlans: any = null;

    vehicleCategories: any = null;

    facilities: any = null;

    models: any = null;

    countries: any = null;

    contryCodes: any = null;

    constructor() {
      makeObservable(this, {
        vaultContractPlans: observable,
        vehicleCategories: observable,
        facilities: observable,
        models: observable,
        countries: observable,
        contryCodes: observable,
      });
    }

  getContractPlans = async (contractType? : string) => new Promise((resolve, reject) => {
    masterService.getContractPlan(contractType)
      .then((response: any) => {
        runInAction(() => {
          this.vaultContractPlans = response?.data?.contractPlans;
        });
        resolve(response?.data?.contractPlans);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getVehicleCategories = async () => new Promise((resolve, reject) => {
    masterService.getVehicleCategories()
      .then((response: any) => {
        runInAction(() => {
          this.vehicleCategories = response?.data?.category;
        });
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getFacilities = async (
    keyword?: string | null, pageNumber?: number, count?: number, order?: string, orderBy?: number,
  ) => new Promise((resolve, reject) => {
    facilityService.getFacilities({
      keyword, page: pageNumber, limit: count, order, orderBy,
    })
      .then((response: any) => {
        runInAction(() => {
          this.facilities = response?.data?.facility;
        });
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getModels = async (queryParams : ModelQueryParams) => new Promise((resolve, reject) => {
    modelService.getModels(queryParams)
      .then((response: any) => {
        runInAction(() => {
          this.models = response?.data?.models;
        });
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getCountryData = async () => {
    try {
      const response = await countryService.getCountryData();
      runInAction(() => {
        this.contryCodes = response?.data?.countries?.map((country: any) => country?.code);
      });
      const countryMap = new Map();
      response?.data?.countries.forEach((c: any) => {
        const stateMap = new Map();
        c.states.forEach((s: any) => {
          stateMap.set(s.state, {
            cities: s.cities,
          });
        });
        countryMap.set(c.country, stateMap);
      });
      runInAction(() => {
        this.countries = countryMap;
      });
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new MasterStore();
