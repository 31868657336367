/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col, Layout, Row, Typography, Button, Card, Dropdown, Menu,
} from 'antd';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { AuthStoreProps } from '../../Store/authStore';
import { UserStoreProps } from '../../Store/userStore';
import {
  Icon, UserCard, Address, ContactCard, ResetPasswordModal, Toast, Pagination, UserActivities,
} from '../../Components';
import Routes from '../../Global/Routes';
import Constant from '../../Global/Constant';
import './Profile.less';

interface ProfileProps {
  userStore: UserStoreProps,
  authStore: AuthStoreProps
}

const { Sider } = Layout;
const { Title, Paragraph } = Typography;
function Profile(props: ProfileProps) {
  const { userStore, authStore } = props;
  const { loggedUser: userDetails } = userStore;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState(-1);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const sortActivity = () => {
    const nextSort = orderBy === -1 ? 1 : -1;
    setOrderBy(nextSort);
    getUserActivity(pageNumber, nextSort);
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const resetPasswordModal = () => {
    setShowPasswordModal(!showPasswordModal);
  };

  const getUserActivity = async (page: number, sort: number) => {
    setIsLoading(true);
    try {
      await userStore.getUserActivity({
        employee: authStore?.currentUser?.id,
        page,
        limit: Constant.userActivityPerPage,
        order: 'createdAt',
        orderBy: sort,
        activity_type: Constant.activity_type.SERVICE_ACTIVITY,
      });
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUserActivity(pageNumber, orderBy);
  }, [pageNumber]);

  useEffect(() => {
    if (authStore?.currentUser) {
      userStore.getLoggedUser(authStore?.currentUser?.id);
    }
  }, []);
  const gotoProfileEdit = () => history.push(Routes.updateProfile);

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Button className="d-flex ml-auto r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary" onClick={resetPasswordModal}>
          Change Password
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="emp_listing_wrapper">
      <Row gutter={16}>
        <Col
          md={24}
          lg={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-xl-flex align-items-center justify-content-between w-100 mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <Typography.Title className="m-0 header_main fs-xs-24">
                Profile
              </Typography.Title>
              <div className="action_resp d-xl-none">
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                    <Icon className="icon-12 primary" iconRef="#action" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <Button className="main_btn fs-16 r-bold mt-2 mt-xl-0" onClick={gotoProfileEdit}>
              Manage
            </Button>
          </div>
          <Row gutter={[16, 16]}>
            <Col lg={8} md={8} xs={24}>
              <Card bordered={false} className="detail_card_pad">
                <UserCard
                  id={`${userDetails?.emp_id || ''}`}
                  name={`${userDetails?.first_name || ''} ${userDetails?.middle_name || ''}  ${userDetails?.last_name || ''}`}
                  cardName=""
                  profileUrl={userDetails?.profile?.fileUrl}
                />
              </Card>
            </Col>
            <Col lg={16} md={16} xs={24}>
              <Card bordered={false} className="detail_card_pad">
                <Row gutter={16}>
                  <Col lg={12} md={12} sm={12} xs={24}>
                    <div className="border-right border-right-xs-0">
                      <Address addressInfo={userDetails?.address} />
                    </div>
                  </Col>
                  <Col lg={12} md={12} sm={12} xs={24} className="mt-3 mt-sm-0">
                    <ContactCard contactDetails={userDetails} />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Card title="My activities" className="table_card_title">
                <UserActivities
                  sortActivity={sortActivity}
                  userActivities={userStore?.userActivity}
                  isLoading={isLoading}
                  orderBy={orderBy}
                />
              </Card>
              <Pagination
                total={userStore?.activityTotal}
                pageSize={Constant.userActivityPerPage}
                currentPage={pageNumber}
                onChange={(page: number) => setPageNumber(page)}
              />
            </Col>
          </Row>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See and manage the profile details</Paragraph>
          </Typography>
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Actions</Title>
          </Typography>
          <Button className="d-flex ml-auto mb-3 r-bold align-items-center p-0 bg-transparent border-0 shadow-none primary" onClick={resetPasswordModal}>
            Change Password
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Button>
        </Sider>
      </Row>
      <ResetPasswordModal
        showPasswordModal={showPasswordModal}
        closePasswordModal={closePasswordModal}
      />
    </Layout.Content>
  );
}

export default inject('userStore', 'authStore')(observer(Profile));
