/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState } from 'react';
import {
  Button, Input, Tooltip,
} from 'antd';
import { Icon } from '../index';

interface SearchProps {
    onChange: (event: any) => void,
    value?: string,
    placeholder: string,
    titleToolTip: string,
    size: any,
}

Search.defaultProps = {
  value: undefined,
};

function Search(props: SearchProps) {
  const {
    onChange, value, placeholder, titleToolTip, size,
  } = props;
  const [showSearch, setShowSearch] = useState(false);
  const showSearchField = () => setShowSearch(true);
  return (
    <div>
      {!showSearch ? (
        <Tooltip overlayClassName="text-capitalize" title={titleToolTip}>
          <Button
            className="d-flex justify-content-center align-items-center
        text-center p-0 icon_circle ms-sm-3 mt-2 mt-sm-0"
            type="link"
            shape="circle"
            onClick={showSearchField}
          >
            <Icon className="icon-13" iconRef="#Search" />
          </Button>
        </Tooltip>
      ) : (
        <div className="table_srch ms-sm-3 mt-2 mt-sm-0 w-100">
          <Input.Search
            placeholder={placeholder}
            size={size}
            onChange={onChange}
            allowClear
            autoFocus
            value={value}
            prefix={(
              <Icon className="icon-13" iconRef="#Search" />
)}
          />
        </div>
      )}
    </div>
  );
}

export default Search;
