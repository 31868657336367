/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import {
  Button, Layout, Menu, Typography,
} from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';

import Routes from '../../Global/Routes';
import './Sidebar.less';
import authStore from '../../Store/authStore';
import Constant from '../../Global/Constant';
import { Icon, ProfileImage } from '..';

import config from '../../config.json';

const {
  CLIENT_MANAGEMENT, PLAN_MANAGEMENT, SERVICE_MANAGEMENT,
} = Constant.menuAccess;

const { Title, Paragraph } = Typography;

function Sidebar(props: any) {
  const { UIStore, userStore, tenantStore } = props;
  const history = useHistory();
  const signOut = () => authStore.signOut();

  const goToProfile = () => {
    UIStore?.closeSideBar();
    history.push(Routes?.profile);
  };
  const userAccess = userStore?.loggedUser?.role?.permissions[0];
  const menuAccess = tenantStore?.tenantAccess;

  return (
    <div id="side_bg_resp" className={classNames({ open: UIStore?.sideBarOpen })}>
      <Layout.Sider id="sidebar" width={230} className="sidebar_wdt" trigger={null} collapsible collapsed={UIStore.collapsed} collapsedWidth={65}>
        <div className="d-xl-none d-flex justify-content-between p-4 border-bottom bg-white">
          <div className="d-flex w-75">
            <Button type="link" className="icn_bg_44 secondary p-0 rounded-circle me-3 overflow-hidden" onClick={goToProfile}>
              <ProfileImage />
            </Button>
            <Typography className="w-75">
              <Title
                className="r-bold mb-0 primary fs-xs-20 text-truncate text-capitalize"
                level={3}
              >{`${userStore?.loggedUser?.first_name} ${userStore?.loggedUser?.middle_name || ''} ${userStore?.loggedUser?.last_name}`}
              </Title>
              <Paragraph className="text-uppercase secondary r-medium mb-0">{Constant?.userTypeText?.get(userAccess)}</Paragraph>
            </Typography>
          </div>
          <Button className="p-0 border-0 bg-transparent primary" onClick={() => UIStore?.closeSideBar()}>
            <Icon className="icon-11" iconRef="#close" />
          </Button>
        </div>
        <Menu
          className="border-0 nav_sidebar h-100 pt-4"
          defaultSelectedKeys={['1']}
          mode="inline"
          onClick={() => UIStore?.closeSideBar()}
        >
          <Menu.Item key="1" className="w-auto">
            <NavLink to={Routes.dashboard}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#dashboard" />
              Dashboard
            </NavLink>
          </Menu.Item>
          {process.env.REACT_APP_ENV !== 'PROD'
          && (
          <>
            {!UIStore.collapsed
           && <h6 className="text-uppercase mt-4 mb-2 menu_sub_head">shop {tenantStore?.tenantName}</h6>}
            <Menu.SubMenu
              key="sub1"
              title="Orders"
              icon={(
                <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#orders" />
          )}
            >
              <Menu.Item key="2" className="w-auto">
                <NavLink to={Routes.orders}>
                  Orders
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3" className="w-auto">
                <NavLink to={Routes.customOrders}>
                  Custom Orders
                </NavLink>
              </Menu.Item>
              <Menu.Item key="4" className="w-auto">
                <NavLink to={Routes.returnOrders}>
                  Return Orders
                </NavLink>
              </Menu.Item>
              <Menu.Item key="5" className="w-auto">
                <NavLink to={Routes.abandonedCart}>
                  Abandoned Cart
                </NavLink>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="6" className="w-auto">
              <NavLink to={Routes.customers}>
                <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#User" />
                Customers
              </NavLink>
            </Menu.Item>
            <Menu.Item key="7" className="w-auto">
              <NavLink to={Routes.products}>
                <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#products" />
                Products
              </NavLink>
            </Menu.Item>
            <Menu.Item key="8" className="w-auto">
              <NavLink to={Routes.analytics}>
                <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#analytics" />
                Analytics
              </NavLink>
            </Menu.Item>
          </>
          )}
          {!UIStore.collapsed
          && <h6 className="text-uppercase mt-4 mb-2 menu_sub_head">{config.TENANT_ID === 'vms' ? 'the vault' : 'manage'}</h6>}
          {authStore?.currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN)
          && (
          <Menu.Item key="9" className="w-auto">
            <NavLink to={Routes.approval}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#approval" />
              Approval
            </NavLink>
          </Menu.Item>
          )}
          {menuAccess?.includes(SERVICE_MANAGEMENT)
          && (
          <Menu.Item key="10" className="w-auto">
            <NavLink to={Routes.request}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#requests" />
              Service Requests
            </NavLink>
          </Menu.Item>
          )}
          {menuAccess?.includes(PLAN_MANAGEMENT)
          && (
          <Menu.Item key="11" className="w-auto">
            <NavLink to={Routes.contracts}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#contracts" />
              Plans
            </NavLink>
          </Menu.Item>
          )}
          {menuAccess?.includes(CLIENT_MANAGEMENT)
          && (
          <Menu.Item key="12" className="w-auto">
            <NavLink to={Routes.clients}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#User" />
              Clients
            </NavLink>
          </Menu.Item>
          )}
          {authStore?.currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN)
            && !UIStore.collapsed && <h6 className="text-uppercase mt-4 mb-2 menu_sub_head">settings</h6> }
          {authStore?.currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN)
          && (
          <Menu.Item key="13" className="w-auto">
            <NavLink to={Routes.employees}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#employess" />
              Employees
            </NavLink>
          </Menu.Item>
          )}

          <Menu.Item key="14" className="w-auto">
            <NavLink to={Routes.facilities}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#facilities" />
              Facilities
            </NavLink>
          </Menu.Item>

          <Menu.Item key="15" className="w-auto">
            <NavLink to={Routes.models}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#models" />
              Vehicle Models
            </NavLink>
          </Menu.Item>

          {authStore?.currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN)
          && (
          <Menu.Item key="16" className="w-auto">
            <NavLink to={Routes.settings}>
              <Icon className={classNames('icon-14 mb-1', { 'me-5': UIStore?.collapsed === true }, { 'me-3': UIStore?.collapsed === false })} iconRef="#settings" />
              Portal Settings
            </NavLink>
          </Menu.Item>
          )}
          <Menu.Item key="17" className="d-xl-none border-top py-3 h-auto">
            <Button className="p-0 bg-transparent border-0 shadow-none h-auto" onClick={signOut}>
              <div className="d-flex align-items-center">
                <Icon className="icon-14 red" iconRef="#signout" />
                <Typography.Text className="red fs-16 r-bold lh-sm ms-3">
                  Sign Out
                </Typography.Text>
              </div>
            </Button>
          </Menu.Item>
        </Menu>

      </Layout.Sider>
    </div>
  );
}

export default inject('UIStore', 'userStore', 'tenantStore')(observer(Sidebar));
