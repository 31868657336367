/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { action, makeObservable, observable } from 'mobx';
import Constant from '../Global/Constant';
import {
  serviceRequest, serviceRequestData, serviceRequestParams, serviceRequestProps,
} from '../Global/interface';
import serviceRequestService from '../Services/serviceRequestSevices';

export interface IServiceStore{
  services:Array<serviceRequestProps>,
  getServices: () => any,
  servicesRequests: serviceRequest,
  getRequests: (queryParams: serviceRequestParams) => any,
  serviceRequest: serviceRequestData,
  getServiceReqById: (id: string) => any
}

class ServiceStore {
  services:Array<serviceRequestProps>=[];

  servicesRequests: serviceRequest | null = null;

  serviceRequest: serviceRequestData | null = null;

  constructor() {
    makeObservable(this, {
      services: observable,
      servicesRequests: observable,
      getRequests: action,
      serviceRequest: observable,
      getServices: action,
    });
  }

  getServices = async () => {
    try {
      const response = await serviceRequestService.getServices();
      if (response && response?.data) {
        this.services = response?.data?.services;
      }
      return (null);
    } catch (error:any) {
      return (error?.response?.data?.error?.message);
    }
  }

  getRequests = async (queryParams: serviceRequestParams) => {
    try {
      const response = await serviceRequestService.getRequests(queryParams);
      if (response && response?.data) {
        this.servicesRequests = response?.data;
      }
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }

  getServiceReqById = async (id: string) => {
    try {
      const response = await serviceRequestService.getServiceReqById(id);
      if (response && response?.data) {
        this.serviceRequest = response?.data?.service;
      }
      return Promise.resolve(null);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new ServiceStore();
