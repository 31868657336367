/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Radio,
  Row,
  Skeleton,
  Table,
  Typography,
} from 'antd';
import './ApprovalListing.less';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Link, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import classNames from 'classnames';
import ConfirmModal from '../../Components/ConfirmModal';
import {
  Action,
  Icon, NoData, Pagination, Search, Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import Routes from '../../Global/Routes';
import SortTh from '../../Components/SortTh';
import requestService from '../../Services/requestService';
import RequestModal from './RequestConfirmation';
import { RequestData, RequestedData } from '../../Global/interface';

const { Title, Paragraph } = Typography;
const { APPROVED, REJECTED, HOLD } = Constant.customerContract;
const { CLIENT_DELETE, MODEL_DELETE, FACILITY_DELETE } = Constant.employeeRequest;
const { APPROVE, REJECT } = Constant.requestActions;
const { PLAN_MANAGEMENT } = Constant.menuAccess;

const { Sider } = Layout;

function ApprovalListing(props: any) {
  const { requestStore, tenantStore } = props;

  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const defaultPlanStatus = queryParams?.waiting
    ? Constant.requestStatus.PENDING : Constant.requestStatus.OPEN;
  const [isLoading, setIsLoading] = useState(false);
  const { requests } = requestStore;
  const [empSearchValue, setEmpSearchValue] = useState<string>('');
  const [planSearchValue, setPlanSearchValue] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [selectedId, setSelectedId] = useState<string>('');
  const [isEmployeeReq, setIsEmployeeReq] = useState(false);
  const [planReqStatus, setPlanReqStatus] = useState(defaultPlanStatus);
  const [collapsed, setCollapsed] = useState(false);

  const showPlanRequest = tenantStore?.tenantAccess?.includes(PLAN_MANAGEMENT);

  const employeeReqColumns = [
    {
      title: <SortTh title="Employee" dataIndex="user.first_name" orderBy={requests?.employee?.orderBy} order={requests?.employee?.order} />,
      dataIndex: 'employee',
      ellipsis: true,
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.employee, 'user.first_name', 'employee');
        },
      }),
    },
    {
      title: <SortTh title="Request Date" dataIndex="createdAt" orderBy={requests?.employee?.orderBy} order={requests?.employee?.order} />,
      dataIndex: 'date',
      ellipsis: true,
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.employee, 'createdAt', 'employee');
        },
      }),
    },
    {
      title: 'Request',
      dataIndex: 'request',
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      ellipsis: true,
      render: (text: string, record: any) => (
        <Action onAccept={() => acceptEmpReq(record)} onReject={() => rejectEmpReq(record)} />
      ),
    },
  ];

  const acceptEmpReq = (record: any) => {
    setIsRejected(false);
    setIsEmployeeReq(true);
    showModal(record?.rowKey);
  };

  const rejectEmpReq = (record: any) => {
    setIsRejected(true);
    setIsEmployeeReq(true);
    showModal(record?.rowKey);
  };

  const findRoute = (request: RequestedData) => {
    switch (request?.request) {
      case CLIENT_DELETE:
        return `${Routes.client_detail}/${request?.target_id}`;
      case MODEL_DELETE:
        return `${Routes.model_detail}/${request?.target_id}`;
      case FACILITY_DELETE:
        return `${Routes.facility_detail}/${request?.target_id}`;
      default:
        return '';
    }
  };

  const getTargetName = (request: RequestedData) => {
    switch (request?.request) {
      case CLIENT_DELETE:
        return `${request?.client?.first_name} ${request?.client?.middle_name || ''} ${request?.client?.last_name}`;
      case MODEL_DELETE:
        return `${request?.model?.company} ${request?.model?.model}`;
      case FACILITY_DELETE:
        return `${request?.facility?.name}`;
      default:
        return '';
    }
  };

  const employeeReqData = requests?.employee?.data?.map((request: RequestedData) => ({
    employee:
  <div className="d-flex ml-auto text-capitalize r-bold align-items-center p-0 link_text">
    <span className="text-truncate">
      {request?.user[0]?.first_name} {request?.user[0]?.middle_name || ''} {request?.user[0]?.last_name}
    </span>
    { !request?.user[0]?.deleted && (
    <Link to={`/employees_detail/${request?.user[0]?._id}`} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
      <Icon className="icon-8" iconRef="#arrow_right" />
    </Link>
    )}
  </div>,
    date: Utility.getFormattedDate(request?.createdAt),
    request:
  <div className="d-flex">{Utility.getRequestText(request?.request)} -
    <span title={getTargetName(request)} className="text-truncate text-capitalize ms-1">
      {getTargetName(request)}
    </span>
    <Link to={findRoute(request)} className="d-flex ml-auto r-bold ms-1 align-items-center p-0 link_text">
      <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
        <Icon className="icon-8" iconRef="#arrow_right" />
      </div>
    </Link>
  </div>,
    rowKey: request?._id,
    key: request?._id,
  }));

  const sortTable = (request: RequestData, dataIndex: string, isEmployee?: string) => {
    const nextSort = request?.orderBy === -1 ? 1 : -1;
    if (isEmployee) getEmployeeRequests(request?.page, request?.keyword, dataIndex, nextSort);
    else getPlanRequests(planReqStatus, request?.page, request?.keyword, dataIndex, nextSort);
  };

  const clientReqColumn = [
    {
      title: <SortTh title="Client" dataIndex="client.first_name" orderBy={requests?.client?.orderBy} order={requests?.client?.order} />,
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.client, 'client.first_name');
        },
      }),
      dataIndex: 'client',
      ellipsis: true,
    },
    {
      title: <SortTh title="Request Date" dataIndex="createdAt" orderBy={requests?.client?.orderBy} order={requests?.client?.order} />,
      onHeaderCell: () => ({
        onClick: () => {
          sortTable(requests?.client, 'createdAt');
        },
      }),
      dataIndex: 'date',
      ellipsis: true,
    },
    {
      title: 'Plan Type',
      dataIndex: 'planType',
      ellipsis: true,
    },
    {
      title: 'Plan Term',
      dataIndex: 'planTerm',
      ellipsis: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      ellipsis: true,
      rowClassName: 'd-flex align-items-center',
    },
    // {
    //   title: 'Client Type',
    //   dataIndex: 'clientType',
    //   ellipsis: true,
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      ellipsis: true,
      render: (text: any, record: any) => (
        <Action
          onAccept={() => acceptClientReq(record)}
          onReject={() => rejectClientReq(record)}
          isWaiting={planReqStatus !== Constant.requestStatus.PENDING}
          onWait={() => holdClientReq(record)}
        />
      ),
    },
  ];

  const holdClientReq = (record: any) => {
    ConfirmModal('Put on hold', 'Are you sure you want to put this request on hold?', () => updatePlanRequestStatus(record?.rowKey, HOLD), 'Hold');
  };

  const acceptClientReq = (record: any) => {
    setIsRejected(false);
    showModal(record?.rowKey);
  };

  const rejectClientReq = (record: any) => {
    setIsRejected(true);
    showModal(record?.rowKey);
  };

  const getLocation = (contractType: string, request: RequestedData) => {
    if (contractType === Constant.plan_type.VAULT) {
      return `${request?.facility[0]?.name} (${request?.facility[0]?.used_capacity}/${request?.facility[0]?.total_capacity})`;
    }
    return request?.contract[0]?.address?.state;
  };

  const clientReqData = requests?.client?.data.map((request: RequestedData) => ({
    planType: request?.contractPlan[0]?.plan,
    planTerm: Utility.getMonthFromDays(request?.contract[0]?.duration),
    date: Utility.getFormattedDate(request?.createdAt),
    location:
  <div className="d-flex align-items-center"> <Icon className="icon-13 secondary me-1 min-w-13" iconRef="#location" />
    <span className="d-block text-truncate">{getLocation(request?.contractPlan[0]?.plan_type, request)}</span>
  </div>,
    client:
  <Link to={`${Routes.client_detail}/${request?.client[0]?._id}`} className="d-flex ml-auto text-capitalize r-bold align-items-center p-0 link_text">
    <span className="text-truncate text-capitalize">
      {request?.client[0]?.first_name} {request?.client[0]?.middle_name || ''}  {request?.client[0]?.last_name}
    </span>
    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
      <Icon className="icon-8" iconRef="#arrow_right" />
    </div>
  </Link>,
    rowKey: request?.contract[0]?._id,
    key: request?._id,
  }));

  const getEmployeeRequests = async (
    page: number, keyword?: string, order?: string, orderBy?:number,
  ) => {
    setIsLoading(true);
    try {
      await requestStore.getEmployeeRequest(
        Constant.requestStatus.OPEN, page, Constant.approvalsPerPage, keyword, order, orderBy,
      );
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const getPlanRequests = async (
    reqStatus: string, page: number, keyword?: string, order?: string, orderBy?:number,
  ) => {
    setIsLoading(true);
    try {
      await requestStore.getPlanRequests({
        request_status: reqStatus, page, limit: Constant.approvalsPerPage, keyword, order, orderBy,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };

  const updatePlanRequestStatus = async (id: string, action: string, comment?: string) => {
    requestService.updatePlanRequestStatus(id, { action, comment }).then(() => {
      getPlanRequests(planReqStatus, Constant.defaultPageNumber, requests?.client?.keyword,
        requests?.client?.order, requests?.client?.orderBy);
      setIsLoading(false);
    }).catch((error: any) => {
      setIsLoading(false);
      Toast.error(error);
    });
  };

  const updateEmpRequestStatus = async (id: string, action: string, comment?: string) => {
    requestService.updateEmpRequestStatus(id, { action, comment }).then(() => {
      getEmployeeRequests(Constant.defaultPageNumber, requests?.employee?.keyword,
        requests?.employee?.order, requests?.employee?.orderBy);
      setIsLoading(false);
    }).catch((error: any) => {
      setIsLoading(false);
      Toast.error(error);
    });
  };

  useEffect(() => {
    requestStore.initialize();
    getEmployeeRequests(Constant.defaultPageNumber);
    getPlanRequests(planReqStatus, Constant.defaultPageNumber);
  }, []);

  const empDebouncedSave = useCallback(
    debounce((nextValue: any) => getEmployeeRequests(
      Constant.defaultPageNumber, nextValue, requests?.employee?.order, requests?.employee?.orderBy,
    ), Constant.debaounceTime),
    [],
  );
  const empSearch = (event: any) => {
    const { value: nextValue } = event.target;
    setEmpSearchValue(nextValue);
    empDebouncedSave(nextValue);
  };

  const planDebouncedSave = useCallback(
    debounce((nextValue: string, status: string) => getPlanRequests(
      status,
      Constant.defaultPageNumber, nextValue, requests?.client?.order, requests?.client?.orderBy,
    ), Constant.debaounceTime),
    [],
  );

  const planSearchHandler = (event: any) => {
    const { value: nextValue } = event.target;
    setPlanSearchValue(nextValue);
    planDebouncedSave(nextValue, planReqStatus);
  };

  const showModal = (id: string) => {
    setSelectedId(id);
    setIsModalVisible(true);
  };

  const handleOk = (comment?: string) => {
    setIsLoading(true);
    setIsModalVisible(false);
    setIsEmployeeReq(false);
    if (isEmployeeReq) {
      if (isRejected) updateEmpRequestStatus(selectedId, REJECT, comment);
      else updateEmpRequestStatus(selectedId, APPROVE);
    } else if (isRejected) updatePlanRequestStatus(selectedId, REJECTED, comment);
    else updatePlanRequestStatus(selectedId, APPROVED);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEmployeeReq(false);
  };

  const emplocale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="employee requests" />
    ),
  };

  const planlocale = {
    emptyText: (
      (isLoading) ? <Skeleton loading={isLoading} /> : <NoData subTitle="plan requests" />
    ),
  };

  const getEmployeeReq = (page: number) => getEmployeeRequests(
    page, requests?.employee?.keyword, requests?.employee?.order, requests?.employee?.orderBy,
  );

  const planPageHandler = (page: number) => getPlanRequests(
    planReqStatus, page, requests?.client?.keyword,
    requests?.client?.order, requests?.client?.orderBy,
  );

  const reqStatusHandler = (event: any) => {
    setPlanReqStatus(event?.target?.value);
    getPlanRequests(event?.target?.value, Constant.defaultPageNumber);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Link to={Routes.approval_history} className="d-flex ml-auto r-bold align-items-center p-0 link_text">
          Approval history
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="listing_wrapper">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <div className="d-flex align-items-center justify-content-between border-bottom mb-2 pb-3">
            <Typography.Title className="m-0 header_main d-flex align-items-center fs-xs-24 border-bottom-0">
              Approvals
            </Typography.Title>
            <div className="action_resp d-xl-none">
              <Dropdown overlay={menu} trigger={['click']}>
                <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                  <Icon className="icon-12 primary" iconRef="#action" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="approval_listing">
            {showPlanRequest
            && (
            <Element name="Client Request" className="approval_table_listing">
              <div className="section_off_set border-bottom mb-4 pb-4" id="expired_cnrct">
                <div className="d-md-flex align-items-center justify-content-between mb-3">
                  <div className="d-sm-flex align-items-center mb-3 mb-md-0">
                    <h4 className="mb-0 table-header r-bold">Plan Requests</h4>
                    <Search
                      onChange={planSearchHandler}
                      value={planSearchValue}
                      placeholder="Search"
                      titleToolTip="Search Client Request"
                      size="small"
                    />
                  </div>
                  <Radio.Group
                    onChange={reqStatusHandler}
                    defaultValue={defaultPlanStatus}
                    buttonStyle="solid"
                    className="radio_tab gray_bg w-100 border-radius"
                  >
                    <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16" value={Constant.requestStatus.OPEN}>NEW</Radio.Button>
                    <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16" value={Constant.requestStatus.PENDING}>WAITING</Radio.Button>
                  </Radio.Group>
                </div>
                <Table
                  rowClassName="cursor-pointer"
                  columns={clientReqColumn}
                  dataSource={clientReqData}
                  pagination={false}
                  size="small"
                  scroll={{ x: '1000px' }}
                  className="table_wrapper"
                  locale={planlocale}
                />
                <Pagination
                  total={requests?.client?.total}
                  currentPage={requests?.client?.page}
                  pageSize={Constant.approvalsPerPage}
                  onChange={planPageHandler}
                />
              </div>
            </Element>
            )}
            <Element name="Employees Request" className="employee_table_request">
              <div className="section_off_set" id="active_cnrct">
                <div className="d-sm-flex justify-content-between align-items-center mb-3">
                  <div className="d-sm-flex align-items-center flex-fill">
                    <h4 className="mb-0 table-header r-bold">Employee Requests</h4>
                    <Search
                      onChange={empSearch}
                      value={empSearchValue}
                      placeholder="Search"
                      titleToolTip="Search Employee Request"
                      size="small"
                    />
                  </div>
                </div>
                <Table
                  rowClassName="cursor-pointer"
                  columns={employeeReqColumns}
                  dataSource={employeeReqData}
                  pagination={false}
                  size="small"
                  scroll={{ x: '800px' }}
                  className="table_wrapper"
                  locale={emplocale}
                />
                <Pagination
                  total={requests?.employee?.total}
                  currentPage={requests?.employee?.page}
                  pageSize={Constant.approvalsPerPage}
                  onChange={getEmployeeReq}
                />
              </div>
            </Element>
          </div>
        </Col>
        {/* <div className="d-flex justify-content-end">
            <Button type="link" className="px-2 primary_bg text-white d-none d-xl-flex
             border-0 shadow-none align-items-center">
              <Icon className="icon-18" iconRef="#menu" />
            </Button>
          </div> */}
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See all approval requests from clients and employees</Paragraph>
          </Typography>
          <div>
            <h6 className="text-uppercase r-medium r-side-info mt-5">Sections</h6>
            <div className="v_tab d-flex flex-column">
              {showPlanRequest
              && (
              <ScrollLink
                to="Client Request"
                activeClass="tab_active"
                offset={-110}
                className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
                spy
              >
                Plan Requests
              </ScrollLink>
              )}
              <ScrollLink
                to="Employees Request"
                activeClass="tab_active"
                offset={-110}
                className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
                spy
              >
                Employee Requests
              </ScrollLink>
            </div>
          </div>
          <h6 className="text-uppercase r-medium r-side-info mt-4 mt-xl-5">Actions</h6>
          <Link to={Routes.approval_history} className="d-flex ml-auto mb-3 r-bold align-items-center p-0 link_text">
            Approval history
            <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_right" />
            </div>
          </Link>
        </Sider>
      </Row>
      <RequestModal
        onOk={handleOk}
        onCancel={handleCancel}
        modalVisibility={isModalVisible}
        isReject={isRejected}
        isLoading={isLoading}
      />
    </Layout.Content>
  );
}

export default inject('requestStore', 'tenantStore')(observer(ApprovalListing));
