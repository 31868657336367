/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
import React from 'react';
import { Form, InputNumber } from 'antd';
import './Counter.less';
import { Icon } from '..';

interface CounterProps {
    defaultValue?: number;
    min: number;
    max: number;
    maxLength: number;
    rule: any;
    label: any;
    name: string;
}

function Counter(props: CounterProps) {
  const {
    defaultValue, min, max, maxLength, rule, label, name,
  } = props;
  return (
    <div>
      <Form.Item label={label} className="fac_counter mandatory" name={name} rules={rule}>
        <InputNumber
          defaultValue={defaultValue}
          min={min}
          max={max}
          maxLength={maxLength}
          className="counter"
          placeholder="0"
        />
      </Form.Item>
      <div className="fac_count_plus fac_count_picon">
        <Icon className="icon-8" iconRef="#add" />
      </div>
      <div className="fac_count_minus fac_count_picon">
        <Icon className="icon-8" iconRef="#minus" />
      </div>
    </div>
  );
}

export default Counter;
