/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Spin,
  Typography,
} from 'antd';
import './ClientParkUnpark.less';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import {
  Icon,
  SwapVehicle,
  ParkVehicle,
  Toast,
  SliderItem,
  VehicleMilage,
  OverDue,
  UploadDocument,
  VehicleImageSelector,
} from '../../Components';
import defaultModelCar from '../../Assets/Images/modelCar.png';
import { ParkUnparkStoreProps } from '../../Store/parkUnparkStore';
import Constant from '../../Global/Constant';
import parkUnparkService from '../../Services/parkUnparkService';
import contractService from '../../Services/contractService';
import fileService from '../../Services/fileService';

const { PARK, UNPARK, SWAP } = Constant?.vehicleActions;
const { GATEWAY } = Constant.payment_mode;
const { VAULT } = Constant.plan_type;

const { Sider } = Layout;
const { Title, Paragraph } = Typography;
interface ClientParkUnparkProps {
  parkUnparkStore: ParkUnparkStoreProps
}

function ClientParkUnpark(props: ClientParkUnparkProps) {
  const { parkUnparkStore } = props;
  const {
    vehicle_details, // current vehicle details
    active_contract, // current vehicle's contract
    available_contracts, // active contract list
    vehicle_status, // current vehicle status
    due_days,
    due_amount,
    getVehicleDetails,
  } = parkUnparkStore;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams<any>();
  const history = useHistory();
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const [parkVehicleImages, setParkVehicleImages] = useState<any>([]);
  const [unparkVehicleImages, setUnparkVehicleImages] = useState<any>([]);
  const [isShowParkImgModal, setIsShowParkImgModal] = useState(false);
  const [isShowUnparkImgModal, setIsShowUnparkImgModal] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [activeImageTab, setActiveImageTab] = useState('IN');

  const [fuelValue, setFuelValue] = useState(vehicle_status?.FUEL_CHECK?.value);
  const [mileageValue, setMileageValue] = useState(vehicle_status?.MILEAGE_CHECK?.value);

  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);

  const getVehicleItems = async () => {
    setIsLoading(true);
    try {
      await getVehicleDetails(id);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const occupied_contracts = available_contracts?.filter(
    (item: any) => item?.active_vehicle && item.contract?.plan_type === VAULT,
  );
  const free_contracts = available_contracts?.filter(
    (item: any) => !item?.active_vehicle && item.contract?.plan_type === VAULT,
  );
  const vaultPlan = active_contract?.contract?.plan_type === VAULT;

  const parkUnparkVehicle = async (plan: string) => {
    setIsLoading(true);
    try {
      let vehicleError = false;
      if (!unparkVehicleImages?.length && [UNPARK, SWAP].includes(action)) vehicleError = true;
      if (!parkVehicleImages?.length && [PARK, SWAP].includes(action)) vehicleError = true;
      if (vehicleError) {
        setIsLoading(false);
        return Toast.error(Constant?.vehicleImageRequiredError);
      }
      if (action === UNPARK) {
        if (due_days && (due_days > 0)) {
          setIsModalVisible(true);
        } else {
          await unparkVehicle();
        }
      } else {
        const params: any = {
          vehicle: vehicle_details._id,
          planId: plan,
          clientId: vehicle_details?.client?._id,
          in_vehicle_images: parkVehicleImages,
          action,
        };

        if (action === SWAP) {
          params.out_vehicle_images = unparkVehicleImages;
        }
        // if value for fuel or mileage change then we update the values in vehicle status
        if (fuelValue && fuelValue !== vehicle_status?.FUEL_CHECK?.value) {
          params.fuelValue = fuelValue;
        }
        if (mileageValue && mileageValue !== vehicle_status?.MILEAGE_CHECK?.value) {
          params.mileageValue = mileageValue;
        }
        await parkUnparkService.parkSwapVehicle(params);
        gotoVehicleDetailPage();
      }
      setIsLoading(false);
      return null;
    } catch (error: any) {
      setIsLoading(false);
      return Toast.error(error);
    }
  };

  const unparkVehicle = async (close_plan?: boolean) => {
    await parkUnparkService?.unparkVehicle({
      vehicle: id,
      clientId: vehicle_details?.client?._id,
      out_vehicle_images: unparkVehicleImages,
      close_plan,
    });
    gotoVehicleDetailPage();
  };

  const handleOverDue = async (planId: string, params: any) => {
    try {
      setIsLoading(true);
      let closePlan;
      if (params?.close_plan) {
        closePlan = true;
      }
      await contractService.handleOverDue(planId, params);
      if (params.paymentMode !== GATEWAY) {
        await unparkVehicle(closePlan);
        setIsLoading(false);
        gotoVehicleDetailPage();
      } else {
        getVehicleItems();
        Toast.success('Link shared successfully');
        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error);
    }
  };
  const gotoVehicleDetailPage = () => history.goBack();

  useEffect(() => {
    getVehicleItems();
  }, [id]);

  const removeFileInPark = async (index:number) => {
    const uploadedDoc = parkVehicleImages.filter(
      (value : any, actualIndex:number) => actualIndex !== index,
    );
    setParkVehicleImages(uploadedDoc);
  };

  const removeFileInUnpark = async (index:number) => {
    const uploadedDoc = unparkVehicleImages.filter(
      (value : any, actualIndex:number) => actualIndex !== index,
    );
    setUnparkVehicleImages(uploadedDoc);
  };

  const uploadParkImages = async (selectedDocuments: any) => {
    try {
      setIsImageUploading(true);
      const fileFormData = new FormData();
      selectedDocuments.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
      const response = await fileService.uploadFile(fileFormData);
      setParkVehicleImages([...parkVehicleImages, ...response?.data]);
      setIsShowParkImgModal(false);
      setIsImageUploading(false);
    } catch (error:any) {
      setIsImageUploading(false);
      Toast.error(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  const uploadUnparkImages = async (selectedDocuments: any) => {
    try {
      setIsImageUploading(true);
      const fileFormData = new FormData();
      selectedDocuments.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
      const response = await fileService.uploadFile(fileFormData);
      setUnparkVehicleImages([...unparkVehicleImages, ...response?.data]);
      setIsShowUnparkImgModal(false);
      setIsImageUploading(false);
    } catch (error:any) {
      setIsImageUploading(false);
      Toast.error(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  const cancelUploadModal = () => {
    setIsShowParkImgModal(false);
    setIsShowUnparkImgModal(false);
  };

  const tabChangeHandle = (activeTab: string) => setActiveImageTab(activeTab);
  const showImagePopup = () => {
    // Show image selection model based on action and tab
    let isPark;
    if (action === SWAP) isPark = activeImageTab === 'IN';
    else isPark = action === PARK;

    setIsShowParkImgModal(isPark);
    setIsShowUnparkImgModal(!isPark);
  };

  const clientPlan = (active_contract && Object.keys(active_contract)?.length > 0
  && active_contract?.contract?.plan_type === VAULT)
  || available_contracts?.length > 0;

  return (
    <Layout.Content className="detail_wrapper fac_detail_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames(('mb-3'), { c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div>
              <Button className="d-flex ml-auto mb-3 r-bold align-items-center primary w-max-content p-0 bg-transparent border-0 shadow-none" onClick={gotoVehicleDetailPage}>
                <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                  <Icon className="icon-8" iconRef="#arrow_left" />
                </div>
                Back
              </Button>
              <Typography.Title className="m-0 header_main mb-2 pb-3">
                Park/Unpark
              </Typography.Title>
              <Row gutter={16}>
                <Col md={24} lg={16}>
                  <Card className="mb-3">
                    <div className="mb-3">
                      <Typography.Title level={5} className="r-blod">Vehicle</Typography.Title>
                      <Row gutter={16}>
                        <Col lg={9} md={9} sm={9} xs={24}>
                          <div className="d-flex align-items-center">
                            <div className="veh_park_img w-100 d-flex align-items-center me-2">
                              <img className="w-100" src={vehicle_details?.model?.image?.fileUrl || defaultModelCar} alt="vehicle_image" />
                            </div>
                            <div className="w-50">
                              <Typography.Title
                                level={5}
                                className="d-flex align-items-center primary mb-1 cursor-pointer"
                              >
                                {vehicle_details?.model?.company
                                && (
                                <span className="text-ellipsis" title={`${vehicle_details?.model?.company} ${vehicle_details?.model?.model}`}>
                                  {`${vehicle_details?.model?.company} ${vehicle_details?.model?.model}`}
                                </span>
                                )}
                              </Typography.Title>
                              <Typography.Paragraph className="secondary fs-16 d-flex align-items-center mb-0">
                                {active_contract?.contract_code}
                                <span className="veh_color d-inline-block ms-3 rounded-circle" style={{ backgroundColor: vehicle_details?.model?.color }} />
                              </Typography.Paragraph>
                            </div>
                          </div>
                        </Col>
                        <Col lg={9} md={9} sm={9} xs={24} className="mt-3 mt-sm-0">
                          <Typography.Text className="text-uppercase r-medium secondary">ACTION</Typography.Text><br />
                          { clientPlan && (
                          <Radio.Group className="cust_radio_btn mt-1" onChange={(e: RadioChangeEvent) => { setAction(e?.target?.value); }}>
                            {vaultPlan
                            && <Radio className="radio_inp" value="UNPARK">Unpark</Radio>}
                            {!vaultPlan && free_contracts?.length && <Radio className="radio_inp" value="PARK">Park</Radio>}
                            {!vaultPlan && occupied_contracts?.length && <Radio className="radio_inp" value="SWAP">Swap</Radio>}
                          </Radio.Group>
                          )}
                          { !clientPlan && !isLoading && (<div>{'Client don\'t have any vault plan, no actions available'}</div>)}
                        </Col>
                      </Row>
                    </div>
                    {(!vaultPlan && clientPlan)
                  && (
                  <div>
                    <Typography.Title level={5} className="r-blod">Update Status</Typography.Title>
                    <Row gutter={16}>
                      <Col lg={24} xs={24}>
                        <SliderItem
                          statusName="Fuel"
                          label="Level"
                          checkBoxText="Fuel status"
                          IconRef={Constant.statusImage.get('FUEL_CHECK')}
                          updateServiceRequest={() => {}}
                          sliderStatus={vehicle_status?.FUEL_CHECK}
                          updateVehicleStatus={(value: number) => setFuelValue(value)}
                        />
                      </Col>
                      <div className="w-100 d-sm-flex">
                        <Col lg={9} md={9} sm={9} xs={24} />
                        <Col lg={15} md={15} sm={15} xs={24}>
                          <Typography.Paragraph className="fs-16 mt-2 mb-0 secondary">
                            Choose the fuel level
                          </Typography.Paragraph>
                        </Col>
                      </div>
                      <Divider className="mt-2 mb-4 border-top" />
                      <Col lg={24} xs={24}>
                        <VehicleMilage
                          statusName="Mileage"
                          checkBoxText="Mileage Status checked"
                          IconRef={Constant.statusImage.get('MILEAGE_CHECK')}
                          milageStatus={vehicle_status?.MILEAGE_CHECK}
                          updateServiceRequest={() => {}}
                          updateVehicleStatus={(value: number) => setMileageValue(value)}
                        />
                      </Col>
                      <div className="w-100 d-sm-flex">
                        <Col lg={9} md={9} sm={9} xs={24} />
                        <Col lg={15} md={15} sm={15} xs={24}>
                          <Typography.Paragraph className="fs-16 mt-2 mb-0 secondary">
                            Enter vehicle mileage
                          </Typography.Paragraph>
                        </Col>
                      </div>
                    </Row>
                  </div>
                  )}
                    {action === UNPARK
                  && (
                  <div className="d-flex ps-lg-0 ps-4 mt-2 pt-4 border-top">
                    <Button className="main_btn me-3 fs-16 r-bold" onClick={() => parkUnparkVehicle(active_contract?._id)}>
                      Unpark Vehicle
                    </Button>
                    <Button type="text" className="cancel_btn fs-16 r-bold" onClick={gotoVehicleDetailPage}>
                      Cancel
                    </Button>
                  </div>
                  )}
                  </Card>
                </Col>
                <Col lg={8} xs={24} className="mb-3">
                  {action
                && (
                <VehicleImageSelector
                  action={action}
                  parkVehicleImages={parkVehicleImages}
                  removeFileInPark={removeFileInPark}
                  removeFileInUnpark={removeFileInUnpark}
                  showImagePopup={showImagePopup}
                  tabChangeHandle={tabChangeHandle}
                  unparkVehicleImages={unparkVehicleImages}
                />
                )}
                </Col>
                <Col lg={16} xs={24}>
                  {action === SWAP
                  && (
                    <Card>
                      <SwapVehicle
                        contracts={occupied_contracts}
                        swapVehicle={parkUnparkVehicle}
                        activeContract={active_contract}
                        cancelSwap={gotoVehicleDetailPage}
                      />
                    </Card>
                  )}
                  {action === PARK && (
                  <Card>
                    <ParkVehicle
                      contracts={free_contracts}
                      parkVehicle={parkUnparkVehicle}
                      cancelPark={gotoVehicleDetailPage}
                      isLoading={isLoading}
                    />
                  </Card>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
          <Sider
            className="r_side_wrapper hidden d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>Park/Unpark client vehicle</Paragraph>
            </Typography>
          </Sider>

        </Row>
        <UploadDocument
          fileMaxCount={10}
          isModalVisible={isShowParkImgModal}
          handleModelCancel={cancelUploadModal}
          uploadDocuments={uploadParkImages}
          uploadedDocuments={parkVehicleImages}
          isLoading={isImageUploading}
          acceptedExtension=".jpeg,.png,.jpg"
        />
        <UploadDocument
          fileMaxCount={10}
          isModalVisible={isShowUnparkImgModal}
          handleModelCancel={cancelUploadModal}
          uploadDocuments={uploadUnparkImages}
          uploadedDocuments={unparkVehicleImages}
          isLoading={isImageUploading}
          acceptedExtension=".jpeg,.png,.jpg"
        />
        <Modal
          title="Payment pending"
          centered
          footer={null}
          className="cmn_modal_wrapper"
          destroyOnClose
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          closeIcon={(
            <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={handleCancel}>
              <Icon className="icon-10" iconRef="#close" />
            </Button>
                      )}
        >
          <OverDue
            dueDays={due_days}
            invoiceAmount={due_amount}
            contract={active_contract}
            handleOverDue={handleOverDue}
            loading={isLoading}
            email={vehicle_details?.client?.email}
            phone={vehicle_details?.client?.phone}
          />
        </Modal>
      </Spin>
    </Layout.Content>
  );
}

export default inject('parkUnparkStore')(observer(ClientParkUnpark));
