/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Col,
  Layout,
  Row,
  Spin,
  Table,
  Typography,
} from 'antd';
import './FacilityHistory.less';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import {
  Icon,
  NoData,
  Pagination,
  Search,
  SortTh,
  Toast,
} from '../../Components';
import Constant from '../../Global/Constant';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;

function FacilityHistory(props: any) {
  const { facilityStore } = props;
  const { facilityHistory } = facilityStore;
  const [isLoading, setIsLoading] = useState(false);
  const [facilityKeyword, setFacilityKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [orderBy, setOrderBy] = useState(1);
  const [order, setOrder] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  const columns = [
    {
      title: <SortTh title="Facility ID" dataIndex="facility_code" order={order} orderBy={orderBy} />,
      dataIndex: 'facility_code',
      onHeaderCell: (column: any) => ({
        onClick: () => {
          sortTable(column?.dataIndex);
        },
      }),
      ellipsis: true,
    },
    {
      title: 'Facility Name',
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: 'Facility Location',
      dataIndex: 'address',
      ellipsis: true,
    },
    {
      title: 'Deleted Date',
      dataIndex: 'updatedAt',
      ellipsis: true,
    },
  ];

  const facilityTableData = facilityHistory?.map((facility: any) => ({
    facility_code: facility?.facility_code,
    name: facility?.name,
    address: facility?.address,
    updatedAt: Utility.getFormattedDate(facility?.updatedAt),
  }));

  const sortTable = (dataIndex: string) => {
    const nextSort = orderBy === -1 ? 1 : -1;
    setOrderBy(nextSort);
    setOrder(dataIndex);
    getFacilities(pageNumber, facilityKeyword, dataIndex, nextSort);
  };

  const getFacilities = async (
    page: number, keyword?: string, sort?: string, sortBy?: number,
  ) => {
    setIsLoading(true);
    facilityStore.getFacilityHistory({
      keyword,
      page,
      limit: Constant.facilityPerPage,
      order: sort,
      orderBy: sortBy,
      deleted: true,
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
        Toast.error(error);
      });
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Spin spinning /> : <NoData subTitle="facilities" />
    ),
  };

  const debounceHandler = useCallback(
    debounce((nextValue: string, page: number, sort: string, sortBy: number) => {
      setPageNumber(page);
      getFacilities(
        page, nextValue, sort, sortBy,
      );
    }, Constant.debaounceTime),
    [],
  );

  const searchHandler = (event: any) => {
    const { value: nextValue } = event.target;
    setFacilityKeyword(nextValue);
    debounceHandler(nextValue, Constant.defaultPageNumber, order, orderBy);
  };

  const pageHandler = (page: number) => {
    setPageNumber(page);
    getFacilities(page, facilityKeyword, order, orderBy);
  };

  useEffect(() => {
    getFacilities(Constant.defaultPageNumber);
  }, []);

  return (
    <Layout.Content className="listing_wrapper facility_history">
      <Row gutter={16}>
        <Col
          lg={24}
          md={24}
          sm={24}
          xs={24}
          className={classNames({ c_main_wrap: collapsed === false },
            { c_main_wrap_collapse: collapsed === true })}
        >
          <Link className="d-flex ml-auto mb-3 r-bold align-items-center back_link w-max-content primary" to={Routes.facilities}>
            <div className="me-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
              <Icon className="icon-8" iconRef="#arrow_left" />
            </div>
            Back
          </Link>
          <Typography.Title className="m-0 header_main mb-2 pb-3 d-flex align-items-center fs-xs-24">
            Facility History
          </Typography.Title>
          <div className="approval_listing">
            <div className="section_off_set" id="active_cnrct">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-sm-flex align-items-center flex-fill">
                  <h4 className="mb-0 table-header r-bold">Deleted Facilities</h4>
                  <Search
                    onChange={searchHandler}
                    value={facilityKeyword}
                    placeholder="Search"
                    titleToolTip="Search Facilities"
                    size="small"
                  />
                </div>
              </div>
              <Table
                columns={columns}
                dataSource={facilityTableData}
                pagination={false}
                size="small"
                scroll={{ x: '800px' }}
                className="table_wrapper"
                locale={locale}
              />
              <Pagination
                total={facilityStore?.historyTotal}
                currentPage={pageNumber}
                pageSize={Constant.facilityPerPage}
                onChange={pageHandler}
              />
            </div>
          </div>
        </Col>
        <Sider
          className="r_side_wrapper d-none d-xl-block"
          collapsedWidth="0"
          width={230}
          collapsible
          onCollapse={(value) => setCollapsed(value)}
          trigger={
            <Icon className="icon-16" iconRef="#menu" />
            }
        >
          <Typography>
            <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
            <Paragraph>See the facility history</Paragraph>
          </Typography>
        </Sider>
      </Row>
    </Layout.Content>
  );
}

export default inject('facilityStore')(observer(FacilityHistory));
