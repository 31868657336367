/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import Button from 'antd/lib/button';
import { Spin } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import Link from 'antd/lib/typography/Link';
import fileSize from 'filesize';
import Constant from '../../Global/Constant';
import { Icon, Toast } from '..';
import docicon from '../../Assets/Images/ic_delete_mini.svg';

interface Props {
    isModalVisible: boolean;
    uploadedDocuments: any;
    isLoading: boolean;
    handleModelCancel: () => void;
    uploadDocuments: (doc: any) => void;
    fileMaxCount?: number;
    acceptedExtension?: string;
}

const UploadDocuments = (props: Props) => {
  const {
    isModalVisible,
    handleModelCancel,
    uploadedDocuments,
    uploadDocuments,
    isLoading,
    fileMaxCount,
    acceptedExtension,
  } = props;
  const [selectedDocuments, setSelectedDocuments] = useState<any>(null);

  const beforeUploadHandle = () => false;
  const onFinish = () => uploadDocuments(selectedDocuments);

  const handleMultipleUpload = (fileInfo : any) => {
    const fileExt = fileInfo?.file?.name?.split('.').pop();
    if (!acceptedExtension?.includes(fileExt)) {
      Toast.error(`Only files with following extensions are allowed: ${acceptedExtension}.`);
      return;
    }
    if ((fileInfo?.file?.size / 1024 / 1024 > Constant.maximumFileSize)) {
      Toast.error(`File size exceeded the limit: ${Constant.maximumFileSize} MB.`);
      return;
    }
    const selectedFile = fileInfo?.fileList[fileInfo?.fileList?.length - 1];
    const selectedFileNames = selectedDocuments?.map((file:any) => file?.name);
    const uploadedFileNames = uploadedDocuments?.map((file:any) => file?.fileName);

    if (selectedFileNames?.includes(selectedFile?.name)
    || uploadedFileNames?.includes(selectedFile?.name)) {
      Toast.error(Constant?.fileExistError);
      return;
    }
    setSelectedDocuments(fileInfo?.fileList);
  };

  const removeFileFromDoc = (index:number) => setSelectedDocuments(
    selectedDocuments.filter((value : any, actualIndex:number) => actualIndex !== index),
  );

  useEffect(() => { setSelectedDocuments(null); }, [isModalVisible]);

  const handleCancel = () => {
    setSelectedDocuments(null);
    handleModelCancel();
  };

  const isDraggerDisabled = () => {
    const totalFilelength = (selectedDocuments?.length || 0) + (uploadedDocuments?.length || 0);
    return fileMaxCount ? totalFilelength >= fileMaxCount : false;
  };

  return (
    <Modal
      title="Upload File"
      centered
      footer={null}
      className="doc_modal_wrapper cmn_modal_wrapper"
      visible={isModalVisible}
      maskClosable={!isLoading}
      onCancel={handleCancel}
      destroyOnClose
      width={350}
      closeIcon={(
        <Button disabled={isLoading} className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={handleCancel}>
          <Icon className="icon-10" iconRef="#close" />
        </Button>
                      )}
    >
      <Spin spinning={isLoading}>
        <Dragger
          disabled={isDraggerDisabled()}
          beforeUpload={beforeUploadHandle}
          onChange={handleMultipleUpload}
          showUploadList={false}
          fileList={selectedDocuments}
          accept={acceptedExtension}
          className="mb-2 border-radius border-0"
        >
          <p className="ant-upload-drag-icon justify-content-center d-flex align-items-center mb-0">
            <div className="large_icn_bg p-0 rounded-circle mt-3 mb-3 justify-content-center d-flex align-items-center">
              <Icon className="icon-32" iconRef="#upload" />
            </div>
          </p>
          <p className="ant-upload-text upload_text">Drag and drop here ({acceptedExtension})</p>
          <Link className="d-flex ml-auto mb-3 r-bold align-items-center link_text justify-content-center" disabled={isDraggerDisabled()}>
            Add Files
            <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon" type="link" shape="circle" disabled={isDraggerDisabled()}>
              <Icon className="icon-8" iconRef="#arrow_right" />
            </Button>
          </Link>
        </Dragger>

        {selectedDocuments?.length > 0
        && selectedDocuments?.map((document: any, index: number) => (
          <div className="document_wrapper pe-0">
            <div className="d-flex d-res-block justify-content-between doc_wrap modal_doc_listing pt-2 pb-2 border-top-0 align-items-center">
              <p className="document_list_wrap d-flex align-items-center mb-0 w-75 w-res-100">
                <span className="text-truncate">{index + 1}. {document.name}</span>
                <Button className="doc_icon ms-2 d-flex justify-content-end align-items-center p-0 border-0 shadow-none" onClick={() => removeFileFromDoc(index)}>
                  <img src={docicon} alt="doc_close" />
                </Button>
              </p>
              <p className="mb-0">{document?.size && fileSize(document?.size)}</p>
            </div>
          </div>
        ))}
        <div className="d-flex mt-4 justify-content-between align-items-center">
          {selectedDocuments?.length > 0
        && (
        <Button className="main_btn me-3 r-bold" onClick={onFinish}>
          Upload
        </Button>
        ) }
          <div className="d-flex justify-content-end w-100">
            <Button type="text" className="cancel_btn fs-16 r-bold" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

UploadDocuments.defaultProps = {
  fileMaxCount: 0,
  acceptedExtension: '.pdf,.doc,.jpeg,.png',
};

export default UploadDocuments;
