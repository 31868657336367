/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Badge, Card, List, Spin,
} from 'antd';

import { inject, observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Icon, Toast, NoData } from '..';
import NotificationIcon from './NotificationIcon';
import { getFirebaseToken, onMessageListener } from '../../firebase';
import authService from '../../Services/authService';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import Constant from '../../Global/Constant';
import Utility from '../../Global/Utility';
import './Notifications.less';
import Routes from '../../Global/Routes';

const {
  PLAN_REQUEST, GATE_OPEN_REQUEST, DELETE_CLIENT_REQUEST, DELETE_MODEL_REQUEST,
  DELETE_FACILITY_REQUEST, DELETE_CLIENT_APPROVAL, DELETE_MODEL_APPROVAL,
  DELETE_FACILITY_APPROVAL, DELETE_CLIENT_REJECTION, DELETE_MODEL_REJECTION,
  DELETE_FACILITY_REJECTION, SERVICE_REQUEST, SERVICE_REQUEST_CANCELLED, PLAN_CLOSED, PLAN_CANCEL,
} = Constant.notificationTypes;

interface NotificationProps {
  notificationStore?: any
}

function Notifications(props: NotificationProps) {
  const { notificationStore } = props;
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(Constant.defaultPageNumber);
  const [showNotificationIcon, setShowNotificationIcon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useOnClickOutside(ref, () => {
    setShow(false);
    setPageNumber(Constant.defaultPageNumber);
  });
  const history = useHistory();
  const toggleNotficationHandler = () => {
    if (!show) {
      updateNotificationStatus();
    }
    setShow(((prev: any) => !prev));
  };

  async function tokenFunc() {
    try {
      const data = await getFirebaseToken() as string;
      if (data) {
        await authService.syncNotificationToken(data);
      }
    } catch (error: any) {
      Toast.error(Constant.defaultErrorMessage);
    }
  }

  useEffect(() => {
    tokenFunc();
  }, []);

  const getIcons = (notification: any) => {
    let icon;
    const { notification_type, reference } = notification;
    switch (notification_type) {
      case PLAN_REQUEST:
      case PLAN_CLOSED:
      case PLAN_CANCEL:
        return <NotificationIcon icon="#contracts" />;
      case GATE_OPEN_REQUEST:
        return <NotificationIcon icon="#gate" />;
      case DELETE_CLIENT_REQUEST:
      case DELETE_MODEL_REQUEST:
      case DELETE_FACILITY_REQUEST:
        return <NotificationIcon icon="#approval" />;
      case DELETE_CLIENT_APPROVAL:
      case DELETE_MODEL_APPROVAL:
      case DELETE_FACILITY_APPROVAL:
        return <NotificationIcon icon="#tick" />;
      case DELETE_CLIENT_REJECTION:
      case DELETE_MODEL_REJECTION:
      case DELETE_FACILITY_REJECTION:
        return <NotificationIcon icon="#close" isRedBg />;
      case SERVICE_REQUEST:
      case SERVICE_REQUEST_CANCELLED:
        icon = Constant?.statusImage?.get(reference?.service) as string;
        return <NotificationIcon icon={icon} />;
      default: return '';
    }
  };

  const getNotifications = async () => {
    try {
      setIsLoading(true);
      await notificationStore?.getNotifications(pageNumber, Constant.notificationPerPage);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const isShowMoreButton = notificationStore?.total
  && (pageNumber * Constant.notificationPerPage < notificationStore?.total);

  const loadMoreNotifications = () => {
    updateNotificationStatus(pageNumber + 1);
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    getNotifications();
  }, [pageNumber]);

  useEffect(() => {
    setShowNotificationIcon(notificationStore?.unSeenCount > 0);
  }, [notificationStore?.unSeenCount]);

  const callOnMessageListener = () => {
    // TODO: need to check the logic on calling this function
    onMessageListener().then((res: any) => {
      const data = JSON.parse(res?.data?.data);
      const duplicateData = notificationStore?.notifications?.find(
        (notification: any) => notification?._id === data?._id,
      );
      if (!duplicateData) {
        setShowNotificationIcon(true);
        notificationStore?.pushNotification(data);
        Toast.info(res?.data?.body);
        if (show) {
          updateNotificationStatus(Constant.defaultPageNumber);
        }
      }
      callOnMessageListener();
    }).catch(() => {
      Toast.error(Constant?.defaultErrorMessage);
    });
  };
  callOnMessageListener();

  const updateNotificationStatus = async (page?: number) => {
    try {
      if (notificationStore?.unSeenCount) {
        await notificationStore?.updateNotificationStatus(
          page || pageNumber, Constant.notificationPerPage,
        );
      }
    } catch (error: any) {
      Toast.error(error);
    }
  };

  const locale = {
    emptyText: (
      (isLoading) ? <Spin spinning /> : <NoData size={100} subTitle="notifications" />
    ),
  };

  const closeNotif = () => setShow(false);

  const gotoPages = (item: any) => {
    let route = '';
    const { notification_type, reference } = item;
    switch (notification_type) {
      case PLAN_REQUEST:
      case PLAN_CLOSED:
      case PLAN_CANCEL:
      case DELETE_CLIENT_REQUEST:
      case DELETE_MODEL_REQUEST:
      case DELETE_FACILITY_REQUEST:
        route = `${Routes.approval}`;
        break;
      case SERVICE_REQUEST:
      case SERVICE_REQUEST_CANCELLED:
        route = `${Routes.service_request_detail}/${reference?.request_code}?vId=${reference?.vehicle}&status=true`;
        break;
      default: route = '';
    }
    setShow(false);
    if (route) history.push(route);
  };

  return (
    <div className="position-relative" ref={ref}>
      <Button type="link" className={show ? 'icn_bg_44 rounded-circle me-3 px-0 not-active-bg notif-res-none' : 'me-3 px-0 icn_bg_44 bg-transparent notif-res-none'} onClick={toggleNotficationHandler}>
        {showNotificationIcon ? (
          <Badge dot={showNotificationIcon} offset={['-3px', '8px']} color="#ff4141">
            <Icon className="icon-14" iconRef="#Notification" />
          </Badge>
        ) : <Icon className="icon-14" iconRef="#Notification" /> }
      </Button>
      {show
            && (
            <div className="resp_notif">
              <div className="d-flex d-sm-none justify-content-end notif_close">
                <Button className="d-flex justify-content-center align-items-center text-center p-0 modal_close mt-0" type="link" shape="circle" onClick={closeNotif}>
                  <Icon className="icon-10" iconRef="#close" />
                </Button>
              </div>
              <Card
                className="notif-card position-absolute border end-0 w-100"
                size="small"
                title="Notifications"
              >
                <List
                  itemLayout="horizontal"
                  dataSource={notificationStore?.notifications}
                  locale={locale}
                  className="w-100 notif-scroll overflow-auto"
                  renderItem={(item : any) => (
                    <List.Item className="refund_wrap cursor-pointer position-relative border-bottom-0" onClick={() => gotoPages(item)}>
                      <List.Item.Meta
                        avatar={(getIcons(item))}
                        title={item?.notification_body}
                        description={`${Utility.getRelativeDate(item?.createdAt)}`}
                      />
                    </List.Item>
                  )}
                />
                {isShowMoreButton > 0
              && (
              <div className="d-flex justify-content-center align-items-center p-12 not-border pt-2">
                <Button onClick={loadMoreNotifications} type="text" className="pe-0 ps-0 r-medium load-more secondary bg-transparent">Load more</Button>
              </div>
              )}
              </Card>
            </div>
            )}
    </div>
  );
}

export default inject('notificationStore')(observer(Notifications));
