/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { observable, action, makeObservable } from 'mobx';
import vehicleService from '../Services/vehicleService';
import Constant from '../Global/Constant';
import { Category, Vehicle } from '../Global/interface';

export interface VehicleStoreProps {
  vehicle: Vehicle;
  vehicleCategories: Array<Category>;
  getVehicleCategory: () => any
  getVehicleById: (id: string, query?: any) => any,
  vehicleStatus: any,
  getVehicleStatus: (id: string) => any
}

class VehicleStore {
  vehicleCategories: Array<Category> | null = null;

  vehicle: Vehicle | null = null;

  vehicleStatus: any = null

  constructor() {
    makeObservable(this, {
      vehicleCategories: observable,
      vehicle: observable,
      vehicleStatus: observable,
      getVehicleStatus: action,
      getVehicleCategory: action,
    });
  }

  getVehicleCategory = async () => new Promise((resolve, reject) => {
    vehicleService.getVehiclesCategory()
      .then((response: any) => {
        if (response && response.data) {
          this.vehicleCategories = response?.data?.category;
          resolve(response?.data?.category);
        }
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getVehicleById = async (id: string, query?: any) => new Promise((resolve, reject) => {
    vehicleService.getVehicleById(id, query)
      .then((response: any) => {
        if (response?.data) {
          this.vehicle = response?.data?.vehicle;
        }
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getVehicleStatus = async (id: string) => new Promise((resolve, reject) => {
    vehicleService.getVehicleStatus(id)
      .then((response: any) => {
        if (response?.data) {
          this.vehicleStatus = response?.data?.vehicle_status;
        }
        resolve(null);
      })
      .catch((error : any) => {
        reject(error?.response?.data?.message?.message || Constant.defaultErrorMessage);
      });
  })
}

export default new VehicleStore();
