/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import {
  Button,
  Col, Divider, Form, Row, Select, Typography,
} from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Icon } from '..';
import defaultModelCar from '../../Assets/Images/modelCar.png';
import { Contract } from '../../Global/interface';

const { Option } = Select;

interface SwapVehicleProps {
  contracts: Array<Contract>,
  activeContract: any,
  swapVehicle: (planId: string) => void,
  cancelSwap: () => void,
}

function SwapVehicle(props: SwapVehicleProps) {
  const {
    contracts, activeContract, swapVehicle, cancelSwap,
  } = props;

  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState<any>(null);
  const options = contracts?.map((item: any) => (
    <Option value={item?._id}>
      <Typography.Title level={5} className="d-flex align-items-center r-medium primary mb-0 mt-2 cursor-pointer">
        <span
          className="text-ellipsis"
        >{`${item?.active_vehicle?.model?.company} ${item?.active_vehicle?.model?.model}` }
        </span>
      </Typography.Title>
      <Typography.Paragraph className="secondary fs-16 d-flex align-items-center mb-0">
        {item?.contract_code}
        <span className="veh_color d-inline-block ms-3 rounded-circle" style={{ backgroundColor: item?.active_vehicle?.model?.color }} />
      </Typography.Paragraph>
    </Option>
  ));

  const selectPlan = (value: any) => {
    setSelectedPlan(value);
    const selectedPlanObject = contracts?.find((item: any) => item?._id === value);
    setSelectedPlanDetails(selectedPlanObject);
  };
  const swap = () => swapVehicle(selectedPlan);

  const vehicleFilterOption = (input: any, option: any) => (
    option?.children[0]?.props?.children?.props?.children?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0
      || option?.children[1]?.props?.children[0]?.toLowerCase()
        .indexOf(input?.toLowerCase()) >= 0);

  return (
    <div>
      <Form layout="vertical" onFinish={swap} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
        <div className="mb-3">
          <Typography.Title level={5} className="r-blod">Swap using</Typography.Title>
          <Row gutter={16} className="mb-3">
            <Col lg={11} sm={11} xs={24}>
              <Form.Item
                label={<div><Typography.Text className="text-uppercase r-medium secondary">VEHICLE</Typography.Text><br /></div>}
                className="mandatory"
                name="country"
                rules={[{
                  required: true,
                  message: 'Vehicle is required.',
                }]}
              >
                <Select
                  showSearch
                  value={selectedPlan}
                  onSelect={selectPlan}
                  placeholder="Select a vehicle"
                  className="slct_style w-75 w-xs-100 mt-2"
                  filterOption={vehicleFilterOption}
                >
                  {options}
                </Select>
              </Form.Item>
            </Col>
            {selectedPlan
          && (
          <Col lg={13} sm={13} xs={24}>
            <div className="d-flex align-items-center">
              <div className="veh_park_img w-100 d-flex align-items-center me-2">
                <img className="w-100" src={selectedPlanDetails?.active_vehicle?.model?.image?.fileUrl || defaultModelCar} alt="vehicle_image" />
              </div>
              <div className="w-75">
                <Typography.Title
                  level={5}
                  className="d-flex align-items-center primary mb-1"
                ><span className="text-ellipsis">{`${selectedPlanDetails?.active_vehicle?.model?.company} ${selectedPlanDetails?.active_vehicle?.model?.model}`}</span>
                </Typography.Title>
                <Typography.Paragraph className="secondary fs-16 d-flex align-items-center mb-0">
                  {selectedPlanDetails?.contract_code}
                  <span className="veh_color d-inline-block ms-3 rounded-circle" style={{ backgroundColor: selectedPlanDetails?.model?.color }} />
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
          )}
          </Row>
          <Divider className="mt-2 mb-4 border-top" />
          {selectedPlan && Object.keys(activeContract).length !== 0
        && (
        <Row gutter={16}>
          <Col lg={11} sm={11} xs={24}>
            <Typography.Text className="text-uppercase r-medium secondary">Moving this vehicle to</Typography.Text><br />
          </Col>
          <Col lg={13} sm={13} xs={24} className="d-flex justify-content-end flex-column">
            <div className="d-flex align-items-center">
              <div className={classNames('position-relative plan_info_img plan_box exo_fnt border-radius d-flex align-items-center justify-content-center me-3', { 'fs-60': (activeContract?.duration / 30 >= 12) })}>
                {activeContract?.duration / 30}<sub className="r-medium secondary fs-16 roboto position-absolute">Month</sub>
              </div>
              <div className="w-75">
                <Typography.Title
                  level={5}
                  className="d-flex align-items-center primary mb-1"
                ><span className="text-ellipsis">{activeContract?.contract?.plan} Plan</span>
                </Typography.Title>

                <Typography.Paragraph className="secondary fs-16 d-flex align-items-center mb-0">
                  {activeContract?.contract_code}
                </Typography.Paragraph>

                <Typography.Paragraph className="secondary fs-16 d-flex align-items-center mb-0">
                  <Icon className="icon-12 me-1" iconRef="#location" />
                  {activeContract?.address?.city}
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
        </Row>
        )}
        </div>
        <div className="d-flex mt-4">
          <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold">
            Swap
          </Button>
          <Button type="text" className="cancel_btn fs-16 r-bold" onClick={cancelSwap}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default SwapVehicle;
