/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import Constant from '../Global/Constant';
import {
  ClientContractParams, clientQuery, ClientVehicleParams, memberParams, sharedAccessParams,
} from '../Global/interface';
import api from './api';

class ClientService {
  getClients = (queryParams: clientQuery) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/clients/list?${query}`;
    return api.get(url);
  }

  createClient = async (client : any) => {
    try {
      const response = await api.post('/clients', client);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateClient = async (id: string, client : any) => {
    try {
      const response = await api.put(`/clients/${id}`, client);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getClientById = async (clientId: String) => {
    try {
      const response = await api.get(`/clients/${clientId}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getClientVehicle = (queryParams: ClientVehicleParams) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/vehicles?${query}`;
    return api.get(url);
  }

  getClientContracts = (queryParams: ClientContractParams) => {
    const query = queryString.stringify(queryParams, { skipNull: true });
    const url = `/customer-contracts?${query}`;
    return api.get(url);
  }

  deleteClient = async (id: string) => {
    try {
      const response = await api.delete(`/clients/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getClientCount = async () => {
    try {
      const response = await api.get('/clients/count');
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  getMembers = async (queryParams: memberParams) => {
    try {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/clients/members?${query}`;
      return api.get(url);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  getSharedAccess = async (queryParams: sharedAccessParams) => {
    try {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/clients/access?${query}`;
      return api.get(url);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  getClientTransactions = async (queryParams: any) => {
    try {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/clients/transactions?${query}`;
      return api.get(url);
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  activateAccount = async (id: string, email?: string) => {
    try {
      const response = await api.put(`/clients/activate-account/${id}`, email);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message);
    }
  }

  createContract = async (client : String, data: any) => {
    try {
      const response = await api.post(`/clients/create-contract/${client}`, data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}
export default new ClientService();
