/* eslint-disable no-underscore-dangle */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Radio,
  Row,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import queryString from 'query-string';
import { useHistory, Link as Route, useLocation } from 'react-router-dom';
import { Link, Element } from 'react-scroll';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import Toast from '../../Components/Toast';
import Constant from '../../Global/Constant';
import {
  Pagination, Icon, NoData, Search, ModelCard,
} from '../../Components';
import Routes from '../../Global/Routes';
import {
  Category, Model, ModelArray, ModelQueryParams,
} from '../../Global/interface';

import './Models.less';

const { Title, Paragraph } = Typography;
const { Sider } = Layout;
const { NEW, PUBLISHED } = Constant.modelPublishStatus;

function Models(props: any) {
  const { authStore, modelStore, vehicleStore } = props;
  const history = useHistory();
  const { currentUser } = authStore;
  const isSuperAdmin = currentUser?.access.includes(Constant.userAccess.SUPER_ADMIN);
  const [isLoading, setIsLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const queryParams: any = queryString.parse(location?.search);
  const defaultModelTab = queryParams?.tab && queryParams?.tab !== 'undefined'
    ? queryParams?.tab : PUBLISHED;
  const [modelTab, setModelTab] = useState(defaultModelTab);
  const [isPublished, setIsPublished] = useState(defaultModelTab === PUBLISHED);

  const fetchModels = async (query: ModelQueryParams) => {
    setIsLoading(true);
    try {
      await modelStore.getModelList(query);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const fetchModelData = async (published: Boolean) => {
    setIsLoading(true);
    try {
      const categories: any = await vehicleStore.getVehicleCategory();
      const list = categories.map((item: Category) => item?.category);
      await modelStore.getData(list, published);
    } catch (error: any) {
      Toast.error(error);
    }
    setIsLoading(false);
  };

  const debounceHandler = useCallback(
    debounce((nextValue: string, category: string, published: boolean) => {
      fetchModels({
        page: Constant.defaultPageNumber,
        limit: Constant.modelPerPage,
        category,
        keyword: nextValue,
        published,
      });
    }, Constant.debaounceTime),
    [],
  );

  const handleSearchTextChange = (event: any, category: string) => {
    const { value } = event.target;
    debounceHandler(value, category, isPublished);
  };

  const getModelData = (page: number, model: ModelArray) => fetchModels({
    page,
    limit: Constant.modelPerPage,
    category: model?.category,
    keyword: model?.keyword,
    published: isPublished,
  });

  const goToModelDetails = (id: string) => history.push(`${Routes.model_detail}/${id}?tab=${modelTab}`);
  const goToModelCreate = () => history.push(Routes.create_model);

  useEffect(() => { fetchModelData(isPublished); }, []);

  const filterHandler = (event: any) => {
    const published = event?.target?.value === Constant.modelPublishStatus.PUBLISHED;
    setModelTab(event?.target?.value);
    setIsPublished(published);
    fetchModelData(published);
    history.push(`${Routes.models}?tab=${event?.target?.value}`);
  };

  const menu = (
    <Menu className="box_shadow border-radius">
      <Menu.Item key="0">
        <Route to={Routes.model_history} className="d-flex ml-auto r-bold align-items-center existing_clnt_link p-0 primary">
          Model History
          <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
            <Icon className="icon-8" iconRef="#arrow_right" />
          </div>
        </Route>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout.Content className="listing_wrapper">
      <Spin spinning={isLoading}>
        <Row gutter={16}>
          <Col
            md={24}
            lg={24}
            sm={24}
            xs={24}
            className={classNames({ c_main_wrap: collapsed === false },
              { c_main_wrap_collapse: collapsed === true })}
          >
            <div className="d-sm-flex justify-content-between align-items-center mb-2 pb-3 border-bottom">
              <div className="d-flex align-items-center justify-content-between">
                <Typography.Title className="m-0 header_main d-flex align-items-center border-0 fs-xs-24">
                  Models
                  { isSuperAdmin && (
                  <Tooltip title="Create Model">
                    <Button
                      className="d-flex justify-content-center
              align-items-center text-center p-0
              icon_circle ms-3"
                      type="link"
                      shape="circle"
                      onClick={goToModelCreate}
                    >
                      <Icon className="icon-13" iconRef="#add" />
                    </Button>
                  </Tooltip>
                  )}
                </Typography.Title>
              </div>
              <div className="d-flex flex-fill justify-content-between justify-content-sm-end align-items-center">
                <Radio.Group
                  onChange={filterHandler}
                  defaultValue={modelTab}
                  buttonStyle="solid"
                  className="radio_tab gray_bg w-100 border-radius mt-2 mt-sm-0"
                >
                  <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14" value={PUBLISHED}>PUBLISHED</Radio.Button>
                  <Radio.Button className="radio_inp bg-transparent border-0 w-50 text-center fs-16 fs-xs-14" value={NEW}>NEW</Radio.Button>
                </Radio.Group>
                <div className="action_resp d-xl-none act_position ms-sm-3">
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button className="ant-dropdown-link action_btn rounded-circle p-0 gray_bg d-flex align-items-center justify-content-center shadow-none border-0">
                      <Icon className="icon-12 primary" iconRef="#action" />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="vehicle_wrapper">
              {modelStore?.models?.length !== 0 && (modelStore?.models).map((model: any) => (
                model?.category && (
                <Element name={model?.category} className="border-bottom mb-4 pb-4 model_brd_btm" key={model?.category}>
                  <div id={model?.category}>
                    <div className="d-sm-flex align-items-center mb-3">
                      <h4 className="mb-0 table-header r-bold text-capitalize">{model?.category}</h4>
                      <Search
                        placeholder="Search"
                        titleToolTip={`Search ${model?.category}`}
                        size="small"
                        onChange={(event) => handleSearchTextChange(event, model?.category)}
                      />
                    </div>
                    <div className="d-flex flex-wrap">
                      { model?.data?.length !== 0 ? model?.data?.map((item: Model) => (
                        <ModelCard
                          category={model?.category}
                          color={item?.color}
                          company={item?.company}
                          image={item?.image}
                          model={item?.model}
                          updatedAt={item?.updatedAt}
                          onClick={() => goToModelDetails(item?._id)}
                          dateText="Last update -"
                          key={model?._id}
                        />
                      )) : (
                        <Card>
                          <NoData subTitle={model?.category} />
                        </Card>
                      )}
                    </div>
                    <Pagination
                      total={model?.total}
                      pageSize={Constant.modelPerPage}
                      currentPage={model?.pageNumber}
                      onChange={(page: number) => getModelData(page, model)}
                    />
                  </div>
                </Element>
                )
              ))}
              {modelStore?.models?.length === 0 && !isLoading && (<NoData subTitle="models" />)}
            </div>
          </Col>
          <Sider
            className="r_side_wrapper d-none d-xl-block"
            collapsedWidth="0"
            width={230}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <Icon className="icon-16" iconRef="#menu" />
            }
          >
            <Typography>
              <Title className="text-uppercase r-medium r-side-info" level={5}>Info</Title>
              <Paragraph>See and manage vehicle models</Paragraph>
            </Typography>
            { modelStore?.models?.length !== 0
            && (
              <div className="d-none d-xl-block">
                <h6 className="text-uppercase r-medium r-side-info mt-5">Sections</h6>
                <div className="v_tab d-flex flex-column">
                  { modelStore?.models?.map((model: any) => (
                    <Link
                      to={model?.category}
                      activeClass="tab_active"
                      offset={-100}
                      className="tab_section inactive ps-3 pt-1 pb-1 border-left text-capitalize"
                      spy
                      key={model?.category}
                    >
                      {model?.category}
                    </Link>
                  ))}
                </div>
              </div>
            )}
            <div><h6 className="text-uppercase r-medium r-side-info mt-xl-5 mt-4">Action</h6>
              <Tooltip title="Model History">
                <Route to={Routes.model_history} className="d-flex ml-auto mb-3 r-bold align-items-center existing_clnt_link p-0 primary">
                  Model History
                  <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </Route>
              </Tooltip>
            </div>
          </Sider>
        </Row>
      </Spin>
    </Layout.Content>
  );
}

export default inject('authStore', 'modelStore', 'vehicleStore')(observer(Models));
