/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input,
  Row, Select, Typography, Modal, Spin,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment, { Moment } from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import {
  Icon, NoData, Toast, UploadDocument, ListDocument,
} from '../../Components';
import './CreateClient.less';
import modelRules from '../../Pages/CreateModel/CreateModelform.validation';
import vehicleService from '../../Services/vehicleService';
import fileService from '../../Services/fileService';
import Constant from '../../Global/Constant';
import contractService from '../../Services/contractService';
import contractRules from './create-contract-form.validation';
import CreateModel from './CreateModel';
import Routes from '../../Global/Routes';
import Utility from '../../Global/Utility';
import { Contract } from '../../Global/interface';

const { REGISTRATION, INSURANCE } = Constant?.vehicleDocTypes;

function CreateContract(props: any) {
  const {
    contractStore, clientStore, masterStore, isUpdate,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm();
  const [contractModalVisible, setContractModalVisible] = useState<boolean>(false);
  const [isDocUploading, setIsDocUploading] = useState<boolean>(false);
  const [regDocuments, setRegDocuments] = useState<any>([]);
  const [InsDocuments, setInsDocuments] = useState<any>([]);
  const [vehicleDocType, setVehicleDocType] = useState<string>('');
  const [isUploadVisible, setIsUploadVisible] = useState<boolean>(false);
  const [isCreateModelVisible, setIsCreateModelVisible] = useState<boolean>(false);
  const [isContractUpdate, setIsContractUpdate] = useState<boolean>(false);
  const [vaultPlanId, setVaultPlanId] = useState<string>('');
  const [isVehicleParked, setisVehicleParked] = useState<boolean>(false);
  const disabledDateForDob = (current: Moment): boolean => current && current > moment();
  const { id } = useParams<any>();
  const history = useHistory();
  const { facilities, vehicleCategories } = masterStore;
  const { contracts } = clientStore;
  const { Option } = Select;

  const createVehicle = async (value: any) => {
    const {
      chassis_no, vehicle_no, insurance_exp_date, registration_exp_date, model,
    } = value;

    const vehicleForm = {
      chassis_no,
      vehicle_no,
      model,
      insurance_exp_date: insurance_exp_date ? moment(insurance_exp_date).format('YYYY-MM-DD') : undefined,
      registration_exp_date: registration_exp_date ? moment(registration_exp_date).format('YYYY-MM-DD') : undefined,
      registration_doc: regDocuments,
      insurance_doc: InsDocuments,
      clientId: id,
    };
    const response = await vehicleService.createVehicle(vehicleForm);
    return response?.data?.id;
  };
  const createContract = async (values: any) => {
    try {
      const {
        facility, start_date, plan_duration, parked_date,
      } = values;
      const contractForm: any = {
        clientId: id,
        plan_duration,
        contract_plan: vaultPlanId,
        facility,
        start_date: new Date(start_date).getTime(),
      };

      if (parked_date) {
        const dueDate = moment(start_date).add(JSON.parse(plan_duration), 'days');
        if (parked_date > dueDate || parked_date < start_date) {
          setIsLoading(false);
          return Toast.error(Constant.parkedDateError);
        }
      }

      if (isVehicleParked) {
        const vehicleId = await createVehicle(values);
        contractForm.vehicle = vehicleId;
        contractForm.parked_date = new Date(parked_date).getTime();
      }
      await contractService.createContract(contractForm);
      setIsLoading(false);
      clientStore.getClientContracts({
        client: id,
        plan_type: Constant.plan_type.VAULT,
        contract_status: [Constant.contractTypes.ACTIVE, Constant.contractTypes.EXPIRED],
      });
      closeContractModel();
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const updateVehicle = async (values: any) => {
    const vehicleId = contractStore?.contract?.active_vehicle?._id;
    const {
      chassis_no, vehicle_no, insurance_exp_date, registration_exp_date, model,
    } = values;

    const vehicleForm = {
      chassis_no,
      vehicle_no,
      model,
      insurance_exp_date: insurance_exp_date ? moment(insurance_exp_date).format('YYYY-MM-DD') : undefined,
      registration_exp_date: registration_exp_date ? moment(registration_exp_date).format('YYYY-MM-DD') : undefined,
      registration_doc: regDocuments,
      insurance_doc: InsDocuments,
      clientId: id,
    };
    const response = await vehicleService.updateVehicle(vehicleId, vehicleForm);
    return response?.data?.id;
  };

  const updateContract = async (values: any) => {
    try {
      const {
        facility, plan_duration, start_date, parked_date,
      } = values;
      const { contract } = contractStore;

      if (parked_date) {
        const dueDate = moment(start_date).add(JSON.parse(plan_duration), 'days');
        if (parked_date > dueDate || parked_date < start_date) {
          setIsLoading(false);
          return Toast.error(Constant.parkedDateError);
        }
      }

      const contractForm: any = {
        clientId: id,
        plan_duration,
        contract_plan: vaultPlanId,
        facility,
        start_date: new Date(start_date).getTime(),
      };
      if (!isVehicleParked && contract?.active_vehicle) {
        contractForm.is_vehicle_parked = false;
      } else if (isVehicleParked) {
        const vehicleId = contract?.active_vehicle ? await updateVehicle(values)
          : await createVehicle(values);
        contractForm.vehicle = vehicleId;
        contractForm.parked_date = new Date(parked_date).getTime();
      }
      await contractService.updateContract(contract?._id, contractForm);
      setIsLoading(false);
      clientStore.getClientContracts({
        client: id,
        plan_type: Constant.plan_type.VAULT,
        contract_status: [Constant.contractTypes.ACTIVE, Constant.contractTypes.EXPIRED],
      });
      closeContractModel();
    } catch (error: any) {
      setIsLoading(false);
      Toast.error(error?.response?.data?.error?.message);
    }
  };

  const onFinishContract = async (values: any) => {
    setIsLoading(true);
    if (isContractUpdate) updateContract(values);
    else createContract(values);
  };

  const closeContractModel = () => {
    setContractModalVisible(false);
    setisVehicleParked(false);
    masterStore.models = [];
    setRegDocuments([]);
    setInsDocuments([]);
    form.resetFields();
  };

  const showContractModel = async (contractId?: string) => {
    setContractModalVisible(true);
    if (contractId) {
      setIsContractUpdate(true);
      contractStore.getContractById(contractId)
        .then(() => {
          const { contract } = contractStore;
          getModels(contract?.active_vehicle?.model?.category || '');
          form.setFieldsValue({
            plan_duration: contract?.duration.toString(),
            start_date: moment(contract?.start_date),
            category: contract?.active_vehicle?.model?.category,
            model: contract?.active_vehicle?.model?._id,
            registration_exp_date: moment(contract?.active_vehicle?.registration_exp_date),
            insurance_exp_date: moment(contract?.active_vehicle?.insurance_exp_date),
            chassis_no: contract?.active_vehicle?.chassis_no,
            vehicle_no: contract?.active_vehicle?.vehicle_no,
            facility: contract?.facility?._id,
            parked_date: contract?.active_vehicle ? moment(contract?.parked_date) : '',
          });
          setisVehicleParked(!!contract?.active_vehicle);
          setRegDocuments(contract?.active_vehicle?.registration_doc || []);
          setInsDocuments(contract?.active_vehicle?.insurance_doc || []);
        })
        .catch((error: AxiosError) => {
          Toast.error(error?.response?.data?.error?.message);
        });
    } else {
      setIsContractUpdate(false);
      setRegDocuments([]);
      setInsDocuments([]);
    }
  };

  const getModels = async (
    category: string, user_created: Boolean = true, published: Boolean = true,
  ) => {
    setIsLoading(true);
    await masterStore.getModels({ category, user_created, published });
    setIsLoading(false);
  };

  const vehicleCategoryChange = (value:string) => {
    form.resetFields(['model']);
    getModels(value);
  };

  const setUploadedDocuments = (documents:any) => {
    if (vehicleDocType === REGISTRATION) {
      setRegDocuments([...regDocuments, ...documents]);
    } else {
      setInsDocuments([...InsDocuments, ...documents]);
    }
  };

  const getContractStatus = (
    contract:Contract,
  ) => {
    const { due_date, payment_status, contract_status } = contract;
    if (payment_status && due_date) {
      return due_date > Date.now()
        ? Constant?.customerContract?.ACTIVE : Constant?.customerContract?.EXPIRED;
    }
    return contract_status;
  };

  const uploadDocuments = async (documents: any) => {
    setIsDocUploading(true);
    const fileFormData = new FormData();
    documents.forEach((document: any) => fileFormData.append('document', document?.originFileObj));
    const response = await fileService.uploadFile(fileFormData);
    setIsUploadVisible(false);
    setIsDocUploading(false);
    setUploadedDocuments(response?.data);
  };

  const removeFileFromUploaded = (index:number, docType: string) => {
    const vehicleDoc = docType === REGISTRATION
      ? regDocuments.filter((value : any, actualIndex:number) => actualIndex !== index)
      : InsDocuments.filter((value : any, actualIndex:number) => actualIndex !== index);

    if (docType === REGISTRATION) setRegDocuments(vehicleDoc);
    else setInsDocuments(vehicleDoc);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!masterStore.vaultContractPlans) {
      masterStore.getContractPlans()
        .then((res: any) => {
          const vaultPlan = res?.find((plan: any) => plan?.plan_type === Constant.plan_type.VAULT);
          setVaultPlanId(vaultPlan?._id);
        })
        .catch((error: string) => Toast.error(error));
    } else {
      const vaultPlan = masterStore.vaultContractPlans?.find(
        (plan: any) => plan?.plan_type === Constant.plan_type.VAULT,
      );
      setVaultPlanId(vaultPlan?._id);
    }
    if (!masterStore?.vehicleCategories) {
      masterStore.getVehicleCategories()
        .catch((error: string) => Toast.error(error));
    }
    masterStore.getFacilities()
      .catch((error: string) => Toast.error(error));
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      clientStore.getClientContracts({
        client: id,
        plan_type: Constant.plan_type.VAULT,
        contract_status: [Constant.contractTypes.ACTIVE, Constant.contractTypes.EXPIRED],
      })
        .catch((error: string) => Toast.error(error));
    }
    setIsLoading(false);
  }, [id]);

  const closeUploadModal = () => setIsUploadVisible(false);

  const showUploadModal = (docType: string) => {
    setVehicleDocType(docType);
    setIsUploadVisible(true);
  };

  const showModelCreate = () => {
    setContractModalVisible(false);
    setIsCreateModelVisible(true);
  };

  const closeModelCreate = () => {
    setContractModalVisible(true);
    setIsCreateModelVisible(false);
  };

  const onFinishModelCreate = async (model: string, category: string) => {
    closeModelCreate();
    await getModels(category);
    form.setFieldsValue({ category, model });
  };

  const gotoListingPage = () => {
    history.push(Routes.clients);
  };

  const modelFilterOption = (input: any, option: any) => (
    option?.children?.props?.children[0]?.props?.children[0]?.toLowerCase()
      .indexOf(input?.toLowerCase()) >= 0
  );

  const checkboxHanlder = () => setisVehicleParked(!isVehicleParked);
  const goToCreateClient = () => {
    history.push(`${Routes.create_client}?create=true`);
    window.location.reload();
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={16}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Card className="create_card">
            <Row
              gutter={{
                xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
              }}
              className="m-0"
            >
              <Col sm={24}>
                <Typography.Title level={3} className="mb-4">Add Vault Plans</Typography.Title>
              </Col>
              <Col lg={4} md={24} sm={24} xs={24}>
                <Typography.Title className="m-0" level={5}>
                  Plans
                </Typography.Title>
              </Col>
              <Col lg={20} md={24} sm={24} xs={24}>
                <div className="d-flex justify-content-between align-items-center">
                  <Typography.Text className="m-0 create_para">
                    Add clients vault plans
                  </Typography.Text>
                  {isUpdate && (
                  <Button className="d-flex ml-auto r-bold align-items-center back_link p-0" type="link" onClick={() => showContractModel()}>
                    Add
                    <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                      <Icon className="icon-8" iconRef="#arrow_right" />
                    </div>
                  </Button>
                  )}

                </div>
                { contracts?.data?.length! !== 0 && (
                  <Row>
                    { contracts?.data?.map((contract: any, index: number) => (
                      <Col lg={20} md={20} sm={20} xs={24}>
                        <div className="exist_cont_listing">
                          <div className="d-sm-flex justify-content-between pt-2 pb-2 fs-16 border-bottom">
                            <div className="d-sm-flex align-items-center">
                              <p className="mb-0 me-3 primary mb-2 mb-sm-0">{index + 1}. {contract?.contract_code}</p>
                              <div className={`${Constant.contractStatusColor.get(`${getContractStatus(contract)}`)} rounded-pill ps-2 pe-2 me-3 d-inline-block ellipse text-center text-capitalize`}>{getContractStatus(contract)}</div>
                              <div className="label_inactive rounded-pill ps-2 pe-2 d-inline-block ellipse text-center ">{Utility.getMonthFromDays(contract?.duration)}</div>
                            </div>
                            {contract?.is_admin_created
                            && (
                            <Button className="d-flex ml-auto r-bold align-items-center back_link p-0" type="link" onClick={() => showContractModel(contract?._id)}>
                              Edit
                              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                                <Icon className="icon-8" iconRef="#arrow_right" />
                              </div>
                            </Button>
                            ) }
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
                { contracts?.data?.length === 0 && !isLoading && (<NoData subTitle="plans" />)}
                <div className="d-flex mt-4">
                  <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading} onClick={gotoListingPage}>
                    Done
                  </Button>
                  <Button
                    className="border-0 primary shadow-none h-40 fs-16 r-bold"
                    disabled={isLoading}
                    onClick={goToCreateClient}
                  >
                    Add another client
                  </Button>
                </div>
              </Col>
            </Row>
            <div>

              <Modal
                title={isContractUpdate ? 'Edit Vault Plan' : 'Add Vault Plan'}
                visible={contractModalVisible}
                onCancel={closeContractModel}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
                className="cmn_modal_wrapper"
                width={1000}
                destroyOnClose
                closeIcon={(
                  <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle" onClick={closeContractModel}>
                    <Icon className="icon-10" iconRef="#close" />
                  </Button>
                )}
              >
                <div>
                  <Spin spinning={isLoading}>
                    <Form layout="vertical" onFinish={onFinishContract} form={form} validateTrigger={['onBlur', 'onSubmit', 'onChange']} scrollToFirstError>
                      <Row
                        gutter={{
                          xs: 16, sm: 16, md: 24, lg: 32, xl: 32, xxl: 64,
                        }}
                        className="mt-3"
                      >
                        <Col lg={4} md={24} sm={24} xs={24}>
                          <Typography.Title className="m-0" level={5}>
                            Period
                          </Typography.Title>
                        </Col>
                        <Col lg={20} md={24} sm={24} xs={24}>
                          <div className="d-flex justify-content-between">
                            <Typography.Text className="m-0 create_para">
                              Add plan time period
                            </Typography.Text>
                          </div>
                          <div className="add_cnt_wrapper">
                            <div className="border-bottom">
                              <Row gutter={16} className="mt-3">
                                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                                  <Form.Item
                                    label="Plan period"
                                    className="mandatory"
                                    name="plan_duration"
                                    rules={contractRules.contract}
                                  >
                                    <Select
                                      suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                                      placeholder="Choose plan period"
                                      className="slct_style"
                                      dropdownClassName="slct_drp_dwn border-radius"
                                    >
                                      {Object.entries(Constant.duration).map(([key, value]) => (
                                        <Option value={key}>{value}</Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                                  <Form.Item
                                    label="start date"
                                    className="mandatory"
                                    name="start_date"
                                    rules={contractRules.start_date}
                                  >
                                    <DatePicker
                                      className="inp_style date_picker primary"
                                      dropdownClassName="date_picker_drp_dwn"
                                      placeholder="Choose start date"
                                      showToday={false}
                                      disabledDate={disabledDateForDob}
                                      format="DD-MM-YYYY"
                                      suffixIcon={(
                                        <Icon className="icon-16 primary" iconRef="#calendar" />
                                      )}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                                  <Form.Item
                                    label="facility"
                                    className="mandatory"
                                    name="facility"
                                    rules={contractRules.facility}
                                  >
                                    <Select
                                      suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                                      placeholder="Choose"
                                      className="slct_style"
                                      dropdownClassName="slct_drp_dwn border-radius"
                                    >
                                      {facilities?.map((facility: any) => (
                                        <Option
                                          value={facility._id}
                                          disabled={
                                          facility?.used_capacity >= facility?.total_capacity
                                        }
                                        >
                                          {facility?.name}<br />
                                          <span className={classNames('text-uppercase secondary r-regular d-flex align-items-center', { disabled: facility?.used_capacity >= facility?.total_capacity })}>{facility?.address}
                                            <Icon className="icon-12 ms-2" iconRef="#location" />
                                          </span>
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                                  <Checkbox
                                    checked={isVehicleParked}
                                    className="veh_park_chkbox_pad chkbox_style primary r-regular"
                                    onChange={checkboxHanlder}
                                  >
                                    Vehicle is inside the facility now
                                  </Checkbox>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <Divider className="m-0 border-top mb-3" />
                        </Col>
                        { isVehicleParked && (
                        <Col lg={4} md={24} sm={24} xs={24}>
                          <Typography.Title className="m-0" level={5}>
                            Vehicle
                          </Typography.Title>
                        </Col>
                        )}
                        { isVehicleParked && (
                        <Col lg={20} md={24} sm={24} xs={24}>
                          <Typography.Paragraph className="create_para">
                            Choose or enter client vehicle details
                          </Typography.Paragraph>
                          <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                              <Form.Item label="vehicle category" className="mandatory" name="category" rules={modelRules?.category}>
                                <Select
                                  suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                                  placeholder="Choose"
                                  className="slct_style"
                                  dropdownClassName="slct_drp_dwn border-radius"
                                  onChange={vehicleCategoryChange}
                                >
                                  {vehicleCategories?.map((vehicleCategory: any) => (
                                    <Option value={vehicleCategory.category}>
                                      {vehicleCategory.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                              <Form.Item label="Choose Model" className="mandatory" name="model" rules={modelRules?.model}>
                                <Select
                                  showSearch
                                  suffixIcon={<Icon className="icon-12" iconRef="#arrow_down" />}
                                  placeholder="Choose"
                                  className="slct_style w-100"
                                  dropdownClassName="slct_drp_dwn border-radius"
                                  filterOption={modelFilterOption}
                                >
                                  {masterStore?.models?.map((model: any) => (
                                    <Option value={model?._id}>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          {`${model?.company} ${model?.model}`}<br />
                                          <span className="text-uppercase secondary r-regular d-flex align-items-center">{model?.manufacture_year}</span>
                                        </div>
                                        <span className="veh_color d-inline-block ms-3 rounded-circle v_color_display" style={{ backgroundColor: model?.color }} />
                                      </div>
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={showModelCreate}>
                              Add new model
                              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                                <Icon className="icon-8" iconRef="#arrow_right" />
                              </div>
                            </Button>
                          </Row>
                          <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                              <Form.Item
                                label="parked date"
                                className="mandatory"
                                name="parked_date"
                                rules={contractRules?.parked_date}
                              >
                                <DatePicker
                                  className="inp_style date_picker primary"
                                  dropdownClassName="date_picker_drp_dwn"
                                  placeholder="Choose date"
                                  showToday={false}
                                  format="DD-MM-YYYY"
                                  disabledDate={disabledDateForDob}
                                  suffixIcon={(
                                    <Icon className="icon-16 primary" iconRef="#calendar" />
                                    )}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Divider className="m-0 border-top mb-3" />
                          <div className="d-flex justify-content-between">
                            <Typography.Text className="m-0 create_para">
                              Add registration documents
                            </Typography.Text>
                            <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 primary" type="link" onClick={() => showUploadModal(REGISTRATION)}>
                              Add
                              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                                <Icon className="icon-8" iconRef="#arrow_right" />
                              </div>
                            </Button>
                          </div>
                          {regDocuments
                            && (
                              <ListDocument
                                documents={regDocuments}
                                removeFile={
                                  (index: number) => removeFileFromUploaded(index, REGISTRATION)
                                }
                              />
                            )}

                          {regDocuments.length > 0 && (
                          <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                              <Form.Item
                                label="expiry date"
                                name="registration_exp_date"
                              >
                                <DatePicker
                                  className="inp_style date_picker primary"
                                  dropdownClassName="date_picker_drp_dwn"
                                  placeholder="Choose expiry date"
                                  showToday={false}
                                  format="DD-MM-YYYY"
                                  suffixIcon={(
                                    <Icon className="icon-16 primary" iconRef="#calendar" />
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xxl={10} />
                          </Row>
                          ) }
                          <Divider className="m-0 border-top mb-3" />
                          <div className="d-flex justify-content-between">
                            <Typography.Text className="m-0 create_para">
                              Add insurance documents
                            </Typography.Text>
                            <Button className="d-flex ml-auto r-bold align-items-center back_link p-0 mb-3 primary" type="link" onClick={() => showUploadModal(INSURANCE)}>
                              Add
                              <div className="ms-2 d-flex justify-content-center align-items-center text-center p-0 small_icon rounded-circle">
                                <Icon className="icon-8" iconRef="#arrow_right" />
                              </div>
                            </Button>
                          </div>
                          {InsDocuments
                            && (
                              <ListDocument
                                documents={InsDocuments}
                                removeFile={
                                  (index: number) => removeFileFromUploaded(index, INSURANCE)
                                }
                              />
                            )}
                          {InsDocuments.length > 0
                            && (
                            <Row gutter={16}>
                              <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                                <Form.Item
                                  label="expiry date"
                                  name="insurance_exp_date"
                                >
                                  <DatePicker
                                    className="inp_style date_picker primary"
                                    dropdownClassName="date_picker_drp_dwn"
                                    placeholder="Choose expiry date"
                                    showToday={false}
                                    format="DD-MM-YYYY"
                                    suffixIcon={(
                                      <Icon className="icon-16 primary" iconRef="#calendar" />
                                  )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} sm={12} md={12} lg={12} xxl={10} />
                            </Row>
                            )}
                          <Divider className="m-0 border-top mb-3" />
                          <Typography.Paragraph className="mt-3 create_para">
                            Enter other vehicle details
                          </Typography.Paragraph>
                          <Row gutter={16}>
                            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                              <Form.Item
                                label="vehicle number"
                                name="vehicle_no"
                                required={false}
                              >
                                <Input placeholder="Enter vehicle number" className="inp_style" maxLength={10} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xxl={10}>
                              <Form.Item
                                label="chassis number"
                                name="chassis_no"
                                required={false}
                              >
                                <Input placeholder="Enter chassis number" className="inp_style" maxLength={17} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Divider className="m-0 border-top mb-3" />
                        </Col>
                        )}
                        <Col lg={4} md={24} sm={24} xs={24} />
                        <Col lg={20} md={24} sm={24} xs={24}>
                          <div className="d-flex mt-4 ps-lg-0 ps-4">
                            <Button htmlType="submit" className="main_btn me-3 fs-16 r-bold" disabled={isLoading}>
                              {isContractUpdate ? 'Update' : 'Finish'}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </div>
                <UploadDocument
                  isModalVisible={isUploadVisible}
                  handleModelCancel={closeUploadModal}
                  uploadDocuments={uploadDocuments}
                  uploadedDocuments={vehicleDocType === REGISTRATION ? regDocuments : InsDocuments}
                  isLoading={isDocUploading}
                />
              </Modal>
              <CreateModel
                handleModelCancel={closeModelCreate}
                isModalVisible={isCreateModelVisible}
                handleOnFinish={onFinishModelCreate}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
}

export default inject('contractStore', 'masterStore', 'clientStore')(observer(CreateContract));
